import React, {useState, useRef, useEffect, useCallback} from 'react'
import { RiArrowDropDownLine } from "react-icons/ri";
import Fuse from 'fuse.js';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

import ListItem from '../MultiSelect/ListItem';
import ToolTip from '../../ToolTip';

export default function SingleSelect({
    label, 
    inputType, 
    ListItemType,
    placeholder, 
    formik,
    value,
    name, 
    errors, 
    touched, 
    hasErrorOutside = null, 
    noLabel = false, 
    theme, 
    data, 
    id, 
    hasToolTip = false,
    toolTipContent = null,
    toolTipPlacement = null,
    isToolTipInfo = false
}) {

    const [openedDropDown, setOpenedDropDown] = useState(false);
    const [searchResults, setSearchResults] = useState(data);
    const [selected, setSelected] = useState(value ? value : '');

    const { setFieldValue } = formik;
    const [t] = useTranslation();

    const openDropDown = () => {
        setOpenedDropDown(true)
    }
    
    const ref = useRef();
    const refInput = useRef();
    useOnClickOutside(ref, () => setOpenedDropDown(false));


    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target) || refInput.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }


    const options = {
        shouldSort: true,
        minMatchCharLength: 3,
        keys: ['title','en','alt'],
        threshold:0.2,
        // threshold:1.0,
        includeMatches: true,
        // findAllMatches: true,
        // useExtendedSearch: true,
        ignoreLocation: true
    }
      
    const fuse = new Fuse(data, options)
      

    const liveSearch = (e) =>{
        const length = e.target.value.length;
        if(length >= 3){
            const searchedObjects = []
            const result = fuse.search(e.target.value);
            result.forEach((item, index) => {
                searchedObjects.push(item.item)
                return;
            });
            setSearchResults(searchedObjects);
        }else{
            setSearchResults(data);
        }
    }






    const renderItem = useCallback(
        (item) => (
            selected === item.id ? (
                <ListItem ListItemType={ListItemType} key={item.id} item={item} theme={theme} onClick={()=>{
                    setSelected();
                    refInput.current.value = '';
                    setSearchResults(data);
                    setOpenedDropDown(false)
                }} checked />
            ):(
                <ListItem ListItemType={ListItemType} key={item.id} item={item} theme={theme} onClick={()=>{
                    setSelected(item.id);
                    refInput.current.value = '';
                    setSearchResults(data);
                    setOpenedDropDown(false)
                }} />
            )
        ),
        [selected, theme, data, ListItemType]
    );




    const variants = {
        SelectedActive: { translateY: 36, transition: { duration: 0.125 } },
        SelectedInActive: { translateY: 0, transition: { duration: 0.125 } },
      };


      useEffect(() => {
        setFieldValue(name, selected ? selected : '')
      }, [selected, setFieldValue, name])
      


  return (
    <>
        {noLabel ? (
            null
        ):(
            <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
        )}
        <div id={id} className={
            ((errors && touched)) ? (
                theme === 'light' ? (
                    `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                ):(
                    `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                )     
            ): (
                theme === 'light' ? (
                    styles.InputOuterLight
                ):(
                    styles.InputOuterDark
                )
            )}>
                {selected && !openedDropDown ? (
                    <div
                        className={theme === 'light' ? styles.InputSelectedLight : styles.InputSelectedDark}
                        ref={refInput}
                        onClick={()=>openDropDown()}
                    >{data.find(x => x.id === selected).title}</div>
                ):(
                    <input 
                        className={theme === 'light' ? styles.InputLight : styles.InputDark}
                        type={inputType} 
                        autoFocus={openedDropDown}
                        onFocus={()=>openDropDown()}
                        onChange={liveSearch}
                        ref={refInput}
                        placeholder={placeholder}
                    />
                )}
            
            <div className={theme === 'light' ? styles.DropDownChevronLight : styles.DropDownChevronDark}>
                <RiArrowDropDownLine size={26} />
            </div>
        </div>

        {
            hasToolTip ? (
                <ToolTip
                    id={id}
                    theme={theme}
                    toolTipContent={toolTipContent}
                    toolTipPlacement={toolTipPlacement}
                    isToolTipInfo={isToolTipInfo}
                />
            ):null
        }
        

        <div style={{position:'relative'}}>
 
            {
                openedDropDown ? (
                    <motion.div 
                        variants={variants}
                        animate={selected?.length > 0 ? 'SelectedActive' : 'SelectedInActive' }
                        style={{position:'absolute', top:0, width:'100%', zIndex:2}}
                    >   
                        <motion.div 
                            className={theme === 'light' ? `${styles.DropDownContainerLight}` : `${styles.DropDownContainerDark}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}
                            ref={ref}
                        >   
                            
                            {
                                searchResults?.length >= 1 ? searchResults.map(renderItem) : (
                                    <div className={theme === 'light' ? styles.NoResultsLight : styles.NoResultsDark}>{t('thereAreNoResults')}</div>
                                )
                            }
                        </motion.div>
                    </motion.div>
                ):null
            }
        </div>
        
        {(errors && touched) &&
            <div style={{ marginTop:4}}>
            <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
            </div>
        }
    </>
  )
}