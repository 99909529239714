import React from 'react'

import BreedItem from '../ListItemTypes/BreedItem';
import ColorItem from '../ListItemTypes/ColorItem';
import BloodTypeItem from '../ListItemTypes/BloodTypeItem';
import AllergiesItem from '../ListItemTypes/AllergiesItem';

export default function ListItem({ListItemType, theme, item, onClick, checked}) {

    if(ListItemType === 'breeds'){
        return <BreedItem theme={theme} item={item} onClick={onClick} checked={checked} />
    }

    if(ListItemType === 'colors'){
        return <ColorItem theme={theme} item={item} onClick={onClick} checked={checked} />
    }

    if(ListItemType === 'bloodType'){
        return <BloodTypeItem theme={theme} item={item} onClick={onClick} checked={checked} />
    }

    if(ListItemType === 'allergies'){
        return <AllergiesItem theme={theme} item={item} onClick={onClick} checked={checked} />
    }

}
