const Sterilized = [
    {
        id:1,
        title:"Δεν γνωρίζω",
        en:"I don't know",
        // img:require('../assets/thinking_emoji.png')
    }, 
    {
        id:2,
        title:"Έχει στειρωθεί",
        en:"Sterilized",
        // img:require('../assets/thumbs-up.png')
    }, {
        id:3,
        title:"Δεν εχει στειρωθεί",
        en:"Not sterilized",
        // img:require('../assets/thumbs-down.png')
    }
];

export default Sterilized
