import React, {useContext, useState, useEffect} from 'react'
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../../../../context/AppearanceContext';
import stateImg from '../../../../../assets/getStarted/AddPet/belt.png'

import dog from '../../../../../assets/getStarted/AddPet/dog.png'
import cat from '../../../../../assets/getStarted/AddPet/cat.png'

// import { useAuth } from '../../../../../hooks/useAuth';

import LTitle from '../../../../../components/Titles/LTitle';
import PrimaryBtnLarge from '../../../../../components/Buttons/PrimaryBtnLarge';


import TIME from '../../../../../constants/TIME';
const MoveValue = 10




export default function SelectSpecies() {
    

    // const { user } = useAuth();
    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()

    const [isSelected, setIsSelected] = useState(location.state ? location.state[0].speciesSelected : null);
    const [setProgressBarVisible, setNextProgress, setPrevProgress] = useOutletContext();

    useEffect(() => {
        setProgressBarVisible(false)
        setPrevProgress(8)
        setNextProgress(0)
    })
    


    const handleSelect = (x) => {
        setIsSelected(x);
    }


    const CompleteStep = () => {
        navigate('/getstarted/pet-basics', { state: [{speciesSelected: isSelected}] })
    }
   
    
    // if (user.m === null) {
    //     return <Navigate to="/mobile-phone" />;
    // }


    return (
        <>

        <motion.div
            className={styles.TopRightImg}
            initial={{ opacity: 0, translateY: MoveValue }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: MoveValue }}
            transition={{ duration: 0.25, delay: 10*TIME }}
        >
            <img src={stateImg} alt='Pet belt hero' />
        </motion.div>

        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('selectPetSpecies')} 
                backButtonPath='/getstarted'
                hasBackButton
            />
        </motion.div>



        <div className={styles.PetSpeciesContainer}>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 3*TIME }} 
                className={styles.PetSpeciesInner} 
                onClick={()=>handleSelect(0)}
            >
                <div className={theme === 'light' ? (
                        isSelected === 0 ? styles.PetSpeciesSelectedLight : styles.PetSpeciesLight
                    ) : (
                        isSelected === 0 ? styles.PetSpeciesSelectedDark : styles.PetSpeciesDark
                    )}>
                    <img src={dog} alt='dog' />
                </div>
                <div className={theme === 'light' ? styles.PetSpeciesTextLight : styles.PetSpeciesTextDark}>{t('dog')}</div>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 4*TIME }} 
                className={styles.PetSpeciesInner} 
                onClick={()=>handleSelect(1)}
            >
                <div className={theme === 'light' ? (
                        isSelected === 1 ? styles.PetSpeciesSelectedLight : styles.PetSpeciesLight
                    ) : (
                        isSelected === 1 ? styles.PetSpeciesSelectedDark : styles.PetSpeciesDark
                    )}>
                    <img src={cat} alt='cat' />
                </div>
                <div className={theme === 'light' ? styles.PetSpeciesTextLight : styles.PetSpeciesTextDark}>{t('cat')}</div>
            </motion.div>

        </div>

        {isSelected !== null &&
            <motion.div
                style={{maxWidth:280, marginTop:150}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25 }}
            >
                <PrimaryBtnLarge fullWidth title={t('next')} onClick={CompleteStep} />
            </motion.div>
        }


      


           
        </>
    )
}
