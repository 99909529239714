import React from 'react'
import styles from './index.module.css'

const CustomInput = (props,ref) => {
  return (
    props.additional.country === 'GR' ? (
        <input 
            {...props}
            ref={ref}
            value={props.value}
            onChange={props.onChange}
            maxLength="16"
            autoFocus
            className={props.additional.theme === 'light' ? styles.PhoneInputInnerLight : styles.PhoneInputInnerDark}
        />
    ):(
        <input 
            {...props}
            ref={ref}
            value={props.value}
            onChange={props.onChange}
            autoFocus
            className={props.additional.theme === 'light' ? styles.PhoneInputInnerLight : styles.PhoneInputInnerDark}
        />
    )
    
  )
}

export default React.forwardRef(CustomInput)
