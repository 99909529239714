import React, {useContext} from 'react'
import { Outlet, Link } from 'react-router-dom'
// import styles from './index.module.css'
import MultiSelect from '../../components/Select/MultiSelect';
import { AppearanceContext } from '../../context/AppearanceContext';

// import { AuthContext } from '../../context/AuthContext';
import { useAuth } from '../../hooks/useAuth'

export default function Home() {

    // const { setAuthFunc } = useContext(AuthContext);
    const { logout } = useAuth();
    const { theme } = useContext(AppearanceContext)


    return (
        <div style={{maxWidth:700}}>
            <div>Home</div>
            <Link to='/feed'>Go to Feed</Link>
            <button onClick={()=>logout()}>Signout</button>
            <MultiSelect theme={theme} />
            <Outlet />
        </div>
    )
}
