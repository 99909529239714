import React, {useContext} from 'react'
import styles from './index.module.css'
import { Link } from 'react-router-dom'
import VerifiedUser from '../../../assets/UserBadges/VerifiedUser'

import { AppearanceContext } from '../../../context/AppearanceContext'
import { useAuth } from '../../../hooks/useAuth'

export default function UserBox({hasLink = true}) {

    const {theme} = useContext(AppearanceContext)
    const {user} = useAuth()

    return (
        <>
            <img src={user.img} className={styles.UserAvatar} alt='user avatar' />
            <div className={ theme === 'light' ? styles.UserNameLight : styles.UserNameDark}>{user.n}</div>
            {hasLink ? (
                <Link to='/giorgosgeorgiou' className={ theme === 'light' ? styles.UserSlugLight : styles.UserSlugDark}>@{user.s}</Link>
            ):(
                <div className={ theme === 'light' ? styles.UserSlugLight : styles.UserSlugDark}>@{user.s}</div>
            )}
            <div className={styles.UserBadgesContainer}>
                <VerifiedUser />
            </div>
        </>
    )
}
