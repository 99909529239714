import React from 'react'

export default function Terms() {
  return (
    <div>
        <h3>Sample Terms of Use</h3>
        
    </div>
  )
}
