import React from 'react'
import { RiErrorWarningLine } from "react-icons/ri";
import { Tooltip as ReactToolTip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import styles from './index.module.css'

export default function ToolTip({
    id,
    theme,
    toolTipContent = null,
    toolTipPlacement = null,
    isToolTipInfo = false,
    isOpen = false
}) {
  return (
    isOpen === true ? (
      <ReactToolTip
        isOpen={isOpen}
        className={theme === 'light' ? styles.ToolTipLight : styles.ToolTipDark}
        anchorId={id}
        place={toolTipPlacement}
        content={()=> (
            <div className={theme === 'light' ? styles.ToolTipContentLight : styles.ToolTipContentDark}>
                {isToolTipInfo ? <RiErrorWarningLine className={theme === 'light' ? styles.ToolTipInfoLight : styles.ToolTipInfoDark} /> : null}
                {toolTipContent}
            </div> 
        )}
        clickable
      />
    ):(
      <ReactToolTip
        className={theme === 'light' ? styles.ToolTipLight : styles.ToolTipDark}
        anchorId={id}
        place={toolTipPlacement}
        content={()=> (
            <div className={theme === 'light' ? styles.ToolTipContentLight : styles.ToolTipContentDark}>
                {isToolTipInfo ? <RiErrorWarningLine className={theme === 'light' ? styles.ToolTipInfoLight : styles.ToolTipInfoDark} /> : null}
                {toolTipContent}
            </div> 
        )}
        clickable
      />
    )
    
  )
}
