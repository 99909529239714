import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.css'

export default function SmallLink({destination, leftIcon, text, onClick, theme}) {
  return (
    destination ? (
        leftIcon ? (
            <Link className={theme === 'light' ? styles.SmallLinkWithLeftIconLight : styles.SmallLinkWithLeftIconDark} to={destination}>
                {leftIcon}
                {text}
            </Link> 
        ):(
            <Link className={theme === 'light' ? styles.SmallLinkLight : styles.SmallLinkDark} to={destination}>{text}</Link>
        )
    ):(
        leftIcon ? (
            <div className={theme === 'light' ? styles.SmallLinkWithLeftIconLight : styles.SmallLinkWithLeftIconDark} onClick={onClick}>
                {leftIcon}
                {text}
            </div>
        ):(
            <div className={theme === 'light' ? styles.SmallLinkLight : styles.SmallLinkDark} onClick={onClick}>{text}</div>
        )
        
    )
    
  )
}
