const CatBreeds = [
    {title:'Ημίαιμη', id:1, en:'Half-blood', alt:'ημιαιμη'},
    {title:'Αβάνας', id:2, en:'Havana Brown', alt:'αβανας'},
    {title:'Αβησσυνίας', id:3, en:'Abyssinian', alt:'αβησσυνιας'},
    {title:'Αιγυπτιακή Μάου', id:4, en:'Egyptian Mau', alt:'αιγυπτιακη μαου'},
    {title:'Αμερικάνικη Κερλ', id:5, en:'American Curl', alt:'αμερικανικη κερλ'},
    {title:'Αμερικάνικη Κοντότριχη', id:6, en:'American Shorthaired', alt:'αμερικανικη κοντοτριχη'},
    {title:'Αμερικάνικη Μπομπτέιλ', id:7, en:'American Bobtail', alt:'αμερικανικη μπομπτειλ'},
    {title:'Αμερικάνικη Σκληρότριχη', id:8, en:'American Wirehaired', alt:'αμερικανικη σκληροτριχη'},
    {title:'Ασιατική', id:9, en:'Asian', alt:'ασιατικη'},
    {title:'Αυστραλιανή Μιστ', id:10, en:'Australian Mist', alt:'αυστραλιανη μιστ'},
    {title:'Βιρμανίας', id:11, en:'Burmese', alt:'βιρμανιας'},
    {title:'Βομβάης', id:12, en:'Bombay cat', alt:'βομβαης'},
    {title:'Βρετανική Κοντότριχη', id:13, en:'British Shorthaired', alt:'βρετανικη κοντοτριχη'},
    {title:'Γερμανική Ρεξ', id:14, en:'German Rex', alt:'γερμανικη ρεξ'},
    {title:'Κάο Μάνι', id:15, en:'Khao Manee', alt:'καο μανι'},
    {title:'Κόρατ', id:16, en:'Korat', alt:'κορατ'},
    {title:'Μανξ', id:17, en:'Manx', alt:'μανξ'},
    {title:'Μάντσκιν', id:18, en:'Munchkin', alt:'μαντσκιν'},
    {title:'Μπομπτέιλ Κουρίλων', id:19, en:'Kurilian Bobtail', alt:'μπομπτειλ κουριλων'},
    {title:'Μπουρμίλα', id:20, en:'Burmilla', alt:'μπουρμιλα'},
    {title:'Ντέβον Ρεξ', id:21, en:'Devon Rex', alt:'ντεβον ρεξ'},
    {title:'Ντον Σφίγγα', id:22, en:'Don Sphynx', alt:'ντον σφιγγα'},
    {title:'Όκικατ', id:23, en:'Ocicat', alt:'οκικατ'},
    {title:'Οριεντάλ', id:24, en:'Oriental', alt:'οριενταλ'},
    {title:'Πίξι Μπομπ', id:25, en:'Pixie-bob', alt:'πιξι μπομπ'},
    {title:'Πίτερμπολντ', id:26, en:'Peterbald', alt:'πιτερμπολντ'},
    {title:'Ρεξ Κορνουάλης', id:27, en:'Cornish Rex', alt:'ρεξ κορνουαλης'},
    {title:'Ρωσική Μπλε', id:28, en:'Russian Blue', alt:'ρωσικη μπλε'},
    {title:'Σεϋχελλών', id:29, en:'Seychellois', alt:'σευχελλων'},
    {title:'Σιάμ', id:30, en:'Siamese', alt:'σιαμ'},
    {title:'Σιγκαπούρης', id:31, en:'Singapura', alt:'σιγκαπουρης'},
    {title:'Σνόουσου', id:32, en:'Snowshoe', alt:'σνοουσου'},
    {title:'Σοκόκε', id:33, en:'Sokoke', alt:'σοκοκε'},
    {title:'Τονκινέζα', id:34, en:'Tonkinese', alt:'τονκινεζα'},

    {title:'Νορβηγικού Δάσους', id:35, en:'Norwegian Forest cat', alt:'νορβηγικου δασους'},
    {title:'Ιαπωνική Μπομπτέιλ', id:36, en:'Japanese Bobtail', alt:'ιαπωνικη μπομπτειλ'},
    {title:'Ιερή Βιρμανίας', id:37, en:'Sacred Cat of Burma', alt:'ιερη βιρμανιας'},
    {title:'Λαπέρμ', id:38, en:'LaPerm', alt:'λαπερμ'},
    {title:'Λευκή Τουρκική Βαν', id:39, en:'White Turkish Van', alt:'λευκη τουρκικη βαν'},
    {title:'Μέιν Κουν', id:40, en:'Maine Coon', alt:'μειν κουν'},
    {title:'Νέβα Μάσκερεϊντ', id:41, en:'Neva Masquerade', alt:'νεβα μασκερειντ'},
    {title:'Ραγκαμάφιν', id:42, en:'Ragamuffin', alt:'ραγκαμαφιν'},
    {title:'Ράγκντολ', id:43, en:'Ragdoll', alt:'ραγκντολ'},
    {title:'Σαρτρέ', id:44, en:'Chartreux', alt:'σαρτρε'},
    {title:'Σέλκιρκ Ρεξ', id:45, en:'Selkirk Rex', alt:'σελκιρκ ρεξ'},
    {title:'Σιβηρίας', id:46, en:'Siberian cat', alt:'σιβηριας'},
    {title:'Σίμρικ', id:47, en:'Cymric cat', alt:'σιμρικ'},
    {title:'Σκωτσέζικη Στρέιτ', id:48, en:'Scottish Straight', alt:'σκωτσεζικη στρειτ'},
    {title:'Σκωτσέζικη Φολντ', id:49, en:'Scottish Fold', alt:'σκωτσεζικη φολντ'},
    {title:'Σομαλίας', id:50, en:'Somali cat', alt:'σομαλιας'},
    {title:'Τουρκική Αγκύρας', id:51, en:'Turkish Angora', alt:'τουρκικη αγκυρας'},
    {title:'Τουρκική Βαν', id:52, en:'Turkish Van', alt:'τουρκικη βαν'},

    {title:'Βρετανική Μακρύτριχη', id:53, en:'British Longhair', alt:'βρετανικη μακρυτριχη'},
    {title:'Μπαλινέζ', id:54, en:'Balinese', alt:'μπαλινεζ'},
    {title:'Περσίας', id:55, en:'Persian cat', alt:'περσιας'}
];

export default CatBreeds;

// export default [
//     'Ημίαιμη',
//     'Αβάνας',
//     'Αβησσυνίας',
//     'Αιγυπτιακή Μάου',
//     'Αμερικάνικη Κερλ',
//     'Αμερικάνικη Κοντότριχη',
//     'Αμερικάνικη Μπομπτέιλ',
//     'Αμερικάνικη Σκληρότριχη',
//     'Ασιατική',
//     'Αυστραλιανή Μιστ',
//     'Βιρμανική',
//     'Βομβάης',
//     'Βρετανική Κοντότριχη',
//     'Γερμανική Ρεξ',
//     'Κάο Μάνι',
//     'Κόρατ',
//     'Μανξ',
//     'Μάντσκιν',
//     'Μπομπτέιλ Κουρίλων',
//     'Μπουρμίλα',
//     'Ντέβον Ρεξ',
//     'Ντον Σφίγγα',
//     'Όκικατ',
//     'Οριεντάλ',
//     'Πίξι Μπομπ',
//     'Πίτερμπολντ',
//     'Ρεξ Κορνουάλης',
//     'Ρώσικη',
//     'Σεϋχελλών',
//     'Σιάμ',
//     'Σιγκαπούρης',
//     'Σνόουσουζ',
//     'Σοκόκε',
//     'Τονκινέζα',

//     'Γάτα Νορβηγικού Δάσους',
//     'Ιαπωνική Μπομπτέιλ',
//     'Ιερή Βιρμανίας',
//     'Λαπέρμ',
//     'Λευκή Τουρκική Βαν',
//     'Μέιν Κουν',
//     'Νέβα Μάσκερεϊντ',
//     'Ραγκαμάφιν',
//     'Ράγκντολ',
//     'Σαρτρέ',
//     'Σέλκιρκ Ρεξ',
//     'Σιβηρίας',
//     'Σίμρικ',
//     'Σκωτσέζικη Στρέιτ',
//     'Σκωτσέζικη Φολντ',
//     'Σομαλίας',
//     'Τουρκική Αγκύρας',
//     'Τουρκική Βαν',

//     'Βρετανική Μακρύτριχη',
//     'Μπαλινέζ',
//     'Περσίας'
// ];