import React, {useEffect, useState, useContext } from 'react';
import styles from './index.module.css'
// import { useLocation, useNavigate } from 'react-router-dom'
import { Link, useLocation } from 'react-router-dom';
import { motion } from "framer-motion"
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../../context/AppearanceContext';

import GetGeolocation from '../../../components/GetGeolocation';
import Toggle from '../../../components/Toggles/Toggle_1'

import LTitle from '../../../components/Titles/LTitle'
import SubTitleDual from '../../../components/Titles/SubTitleDual';
import Input from '../../../components/Inputs/Input';
import PrimaryBtnLarge from '../../../components/Buttons/PrimaryBtnLarge';

import thinking_emoji from '../../../assets/Register/thinking_emoji.png'


import TIME from '../../../constants/TIME';

const MoveValue = 10


export default function RegisterStep3() {

    const location = useLocation();
    // const navigate = useNavigate()

    const { theme } = useContext(AppearanceContext);
    const [t] = useTranslation();

    
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [address, setAddress] = useState(null)
    const [tk, setTk] = useState(null)
    const [toggleChecked, setToggleChecked] = useState(false)


    const handleChangeToggle = (e) => {
        // setToggleChecked(e.target.checked);
        setToggleChecked(e);
        // if(e.target.checked){
            
        // }
    }
    


    const registerValidationSchema = yup.object().shape({
        tk: yup
            .string()
            .matches(/^[0-9]+$/, "Ο ταχυδρομικός κώδικας αποτελείται μόνο από αριθμούς")
            .min(5, ({ min }) => 'Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία')
            .max(5, ({ max }) => 'Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία')
            .required('Ο ταχυδρομικός κώδικας είναι υποχρεωτικός'),
        })
    const formik = useFormik({
        validationSchema: registerValidationSchema,
        initialValues: {
          tk: ''
        },
        onSubmit: values => {
            // registerUser(name, email, pass, lat, lng, confirmedShownImage, address, values.tk)
            console.log(lat, lng)
        },
    });

    
    
    const getAddressFromLatLng = (myLat ,myLng) => {
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + myLat + ',' + myLng + '&language=el&key=AIzaSyAk0UBca0lwoeowNItVUdBkISaFLAtfw6E')
        .then((response) => response.json())
        .then((responseJson) => {  
            // console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify((responseJson.results[0])))
            const locality = responseJson.results[0].address_components.find(local => local.types[0] === "locality").long_name;
            const zip_code = responseJson.results[0].address_components.find(addr => addr.types[0] === "postal_code").short_name;
            setAddress(locality);
            setTk(zip_code.replace(/\s/g, ""))
        })
    }
    
    
    useEffect(() => {
        // console.log(userLocation)
        if(userLocation){
            getAddressFromLatLng(userLocation.lat, userLocation.lng)
            setLat(userLocation.lat);
            setLng(userLocation.lng)
        }
      }, [userLocation]);





    const CompleteRegister = () => {
        // registerUser(name, email, pass, lat, lng, confirmedShownImage, address, tk);
        // console.log('register completed')
    }








    return (
        <>
            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: TIME }}
            >
                <LTitle title={t('location')} theme={theme} hasBackButton backButtonPath='/register/profile-image' backButtonParams={location.state} />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 2*TIME }}
            >
                <SubTitleDual text1={t('helpusprovidenotifications1')} text2={t('foleskakopoiisieksafanisi')} theme={theme} />
            </motion.div>
            <div className={styles.SeperatorS}></div>
            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 3*TIME }}
            >
                <div className={theme === 'light' ? styles.UpperLabelLight : styles.UpperLabelDark}>{t('enableLocationServices')}</div>
                <Toggle
                    checked={toggleChecked}
                    text={t('locationservices')}
                    onChange={handleChangeToggle}
                    theme={theme}
                />
            </motion.div>
            { 
                    toggleChecked ? (
                        <>
                            <div className={styles.SeperatorM}></div>
                            <motion.div
                                initial={{ opacity: 0, translateX: MoveValue }}
                                animate={{ opacity: 1, translateX: 0 }}
                                exit={{ opacity: 0, translateX: MoveValue }}
                                transition={{ duration: 0.25, delay: TIME }}
                            >
                                <GetGeolocation setUserLocation={setUserLocation} />
                                
                            </motion.div>
                            {tk ? (
                                <>
                                    <motion.div
                                        initial={{ opacity: 0, translateX: MoveValue }}
                                        animate={{ opacity: 1, translateX: 0 }}
                                        exit={{ opacity: 0, translateX: MoveValue }}
                                        transition={{ duration: 0.25, delay: 2*TIME }}
                                    >
                                        <div className={styles.IsLocationCorrectContainer} style={{marginTop:20}}>
                                            <img className={styles.ThinkingEmoji} src={thinking_emoji} alt='Thinking emoji' />
                                            <div className={theme === 'light' ? styles.IsLocationCorrectLight : styles.IsLocationCorrectDark}>
                                                {address && tk ? (<>{t('youlivein')} <strong>{address}</strong> {t('andyourzipcodeis')} <strong>{tk}</strong>? </>):null}
                                                <br></br><u>{t('ifyourlocationiswrongmovethepin')}</u>
                                            </div>
                                        </div>
                                    </motion.div>
                                    <div className={styles.SeperatorXL}></div>
                                    
                                    <motion.div
                                        initial={{ opacity: 0, translateX: MoveValue }}
                                        animate={{ opacity: 1, translateX: 0 }}
                                        exit={{ opacity: 0, translateX: MoveValue }}
                                        transition={{ duration: 0.25, delay: 3*TIME }}
                                    >
                                        <span className={theme === 'light' ? styles.RegisterAcceptedRulesLight : styles.RegisterAcceptedRulesDark}>{t('registerAcceptedRules1')}<Link to=''>{t('registerAcceptedRulesTerms')}</Link>{t('registerAcceptedRulesAnd')}<Link to=''>{t('registerAcceptedRulesPrivacy')}</Link></span>
                                    </motion.div>

                                    <div className={styles.SeperatorL}></div>

                                    <motion.div
                                        initial={{ opacity: 0, translateX: MoveValue }}
                                        animate={{ opacity: 1, translateX: 0 }}
                                        exit={{ opacity: 0, translateX: MoveValue }}
                                        transition={{ duration: 0.25, delay: 4*TIME }}
                                    >
                                        <PrimaryBtnLarge type='button' title={t('completeregistration')} fullWidth onClick={()=>CompleteRegister()} />
                                    </motion.div>
                                </>
                                ):(<></>)
                            }
                           
                        </>
                    ):(
                        <>
                        <motion.div
                            initial={{ opacity: 0, translateX: MoveValue }}
                            animate={{ opacity: 1, translateX: 0 }}
                            exit={{ opacity: 0, translateX: MoveValue }}
                            transition={{ duration: 0.25, delay: 4*TIME }}
                        >
                            <div className={styles.SeperatorL}></div>
                            <div className={theme === 'light' ? styles.UpperLabelLight : styles.UpperLabelDark}>{t('enterzipcodeofwhereyoulive')}</div>
                            
                            
                            <form onSubmit={formik.handleSubmit}>
                                <Input 
                                    formik={formik} 
                                    placeholder="12345" 
                                    type="text" 
                                    name="tk" 
                                    value={formik.values.tk} 
                                    errors={formik.errors.tk}
                                    touched={formik.touched.tk}
                                    theme={theme}
                                    noLabel
                                />
                
                                
                            </form>
                        </motion.div>
                        <div className={styles.SeperatorXL}></div>
                        <motion.div
                            initial={{ opacity: 0, translateX: MoveValue }}
                            animate={{ opacity: 1, translateX: 0 }}
                            exit={{ opacity: 0, translateX: MoveValue }}
                            transition={{ duration: 0.25, delay: 5*TIME }}
                        > 
                            <PrimaryBtnLarge type='submit' title={t('completeregistration')} onClick={formik.handleSubmit} fullWidth />
                        </motion.div>
                        </>
                    )
                }
        </>
    )
}
