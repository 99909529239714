import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';

export default function Password({label, placeholder, formik, name, value, errors, touched, hasErrorOutside = null, forgotPass = false, theme}) {

    const [showPass, setShowPass] = useState(false)
    const [t] = useTranslation();
    
    return (
        <>
            {forgotPass ? (
                <div className={styles.LabelAndForgotPassContainer}>
                    <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
                    <Link className={theme === 'light' ? styles.ForgotPassLight : styles.ForgotPassDark} to='/forgotpassword'>{t('forgotpassword')}</Link>
                </div>
            ):(
                <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
            )}
            <div className={
                ((errors && touched) || hasErrorOutside) ? (
                    theme === 'light' ? (
                        `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                    ):(
                        `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                    )     
                ): (
                    theme === 'light' ? (
                        styles.InputOuterLight
                    ):(
                        styles.InputOuterDark
                    )
                )}>
                <input 
                    className={theme === 'light' ? styles.InputLight : styles.InputDark} 
                    type={showPass ? ('text'):('password')} 
                    placeholder={placeholder}
                    name={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={value}
                />
                <div 
                    className={theme === 'light' ? styles.ShowHidePassLight : styles.ShowHidePassDark}
                    onClick={()=>setShowPass(!showPass)}
                >
                    {
                    showPass ? ( <RiEyeLine />):(<RiEyeOffLine />)
                    }
                </div>
            </div>
            {(errors && touched) &&
                <div style={{ marginTop:4}}>
                <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
                </div>
            }
        </>
    )
}