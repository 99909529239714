import React, {useContext} from 'react'
import styles from './index.module.css';

import { AppearanceContext } from '../../../context/AppearanceContext'


export default function PrimaryBtnLarge({title, onClick, type = 'button', fullWidth = false, loading = false}) {

  const { theme } = useContext(AppearanceContext);

  return (
    <button
        type={type}
        onClick={onClick}
        className={theme === 'light' ? styles.PrimaryBtnLargeLight : styles.PrimaryBtnLargeDark}
        style={{width: fullWidth ? '100%' : 'auto'}}
    >{title}</button>
  )
}
