import React, { useContext }  from 'react'
// import { Outlet, Link, Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';

// import Explosion from '../../../../components/Explosion';
import { AppearanceContext } from '../../../../context/AppearanceContext';

// import { useAuth } from '../../../../hooks/useAuth';

import LTitle from '../../../../components/Titles/LTitle';
import PrimaryBtnLarge from '../../../../components/Buttons/PrimaryBtnLarge';
// import stateImage from '../../../../assets/AddMobilePhone/verified_3d.png'

import UserBox from '../../../../components/User/UserBox';




import TIME from '../../../../constants/TIME';
const MoveValue = 10

export default function SuccessfullVerification() {
    const { theme } = useContext(AppearanceContext);
    const [t] = useTranslation();
    const navigate = useNavigate()


    
    const handleSubmit = (e) => {
            navigate("/enter-otp")
    }

  return (
    <>
        {/* <motion.div
            style={{position:'absolute',top:100, right:10}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 7*TIME }}
        >
            <img src={stateImage} alt='Verification hero' />
        </motion.div> */}
        {/* <Explosion /> */}
        <motion.div
            style={{maxWidth:490}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('accountSuccessfulVerified')} 
                color
            />
        </motion.div>


        <motion.div
            style={{maxWidth:400, minHeight:332, marginTop:50, position:'relative', zIndex:2}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }} 
            transition={{ duration: 0.25, delay: 4*TIME }}
        >
            <UserBox hasLink={false} />
        </motion.div>


        <motion.div
            style={{maxWidth:420}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 5*TIME }}
        >
            <PrimaryBtnLarge fullWidth title={t('continue')} onClick={handleSubmit} />
        </motion.div>

        <div className={styles.SeperatorXL}></div>



    </>
  )
}
