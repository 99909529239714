import React, {useContext, useEffect} from 'react'
import { useOutletContext, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AppearanceContext } from '../../../../../context/AppearanceContext';
import stateImg from '../../../../../assets/getStarted/AddPet/SlidersBg.png'
import LTitle from '../../../../../components/Titles/LTitle';
import SubTitle from '../../../../../components/Titles/SubTitle';



import TIME from '../../../../../constants/TIME';
import RangeSlider from '../../../../../components/Sliders/RangeSlider';
import PrimaryBtnLarge from '../../../../../components/Buttons/PrimaryBtnLarge';

 

const MoveValue = 10

export default function AddPetPersonality() {

    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()

    const [setProgressBarVisible, setNextProgress, setPrevProgress] = useOutletContext();


    useEffect(() => {
        setProgressBarVisible(true)
        setPrevProgress(54)
        setNextProgress(89)
    },[location, setProgressBarVisible, setPrevProgress, setNextProgress])


    const registerPetValidationSchema_1 = yup.object().shape({
        autopepithisi: yup
            .number(),
        aneksartisia: yup
            .number(),
        ntropalotita: yup
            .number(),
        deiliafovos: yup
            .number(),
        epithetikotita: yup
            .number(),
        kiriarxia: yup
            .number(),
        koinwnikotita: yup
            .number(),
        prosarmostikotita: yup
            .number(),
        xalarotita: yup
            .number(),
        athlitikotita: yup
            .number(),
        
    });


    const formik = useFormik({
        validationSchema: registerPetValidationSchema_1,
        initialValues: {
            autopepithisi: 0,
            aneksartisia: 0,
            ntropalotita: 0,
            deiliafovos: 0,
            epithetikotita: 0,
            kiriarxia: 0,
            koinwnikotita: 0,
            prosarmostikotita: 0,
            xalarotita: 0,
            athlitikotita: 0
        },
        onSubmit: values => {
            console.log(
                values.autopepithisi,
                values.aneksartisia,
                values.ntropalotita,
                values.deiliafovos,
                values.epithetikotita,
                values.kiriarxia,
                values.koinwnikotita,
                values.prosarmostikotita,
                values.xalarotita,
                values.athlitikotita
            )
        //     if(location.state.length > 3){
        //         location.state.pop();
        //     }
        //   navigate('/getstarted/pet-personality', { state: [...location.state, ...[{
        //     autopepithisi: values.autopepithisi,
        //     aneksartisia: values.aneksartisia,
        //     ntropalotita: values.ntropalotita,
        //     deiliafovos: values.deiliafovos,
        //     epithetikotita: values.epithetikotita,
        //     kiriarxia: values.kiriarxia,
        //     koinwnikotita: values.koinwnikotita,
        //     prosarmostikotita: values.prosarmostikotita,
        //     xalarotita: values.xalarotita,
        //     athlitikotita: values.athlitikotita
        //     }]] })
        },
    });


    if (location.state === null) {
        return <Navigate to="/getstarted" />;
    }else if(location.state[2] === null){
        return <Navigate to="/getstarted" />;
    }


    return (
        <>

            <motion.div
                className={styles.TopRightImg}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 10*TIME }}
            >
                <img src={stateImg} alt='Pet sliders hero' />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 2*TIME }}
            >
                <LTitle 
                    theme={theme} 
                    title={t('personalityBehavior')} 
                    backButtonPath='/getstarted/pet-health'
                    hasBackButton
                    backButtonParams={location.state}
                />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 3*TIME }}
            >
                <SubTitle 
                    theme={theme} 
                    text={t('chooseQuantityThatIsMoreCorrect')} 
                />
            </motion.div>

            <form onSubmit={formik.handleSubmit}>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 4*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='autopepithisi' 
                        formik={formik}
                        label={t('autopepithisi')} 
                        name='autopepithisi'
                        value={formik.values.autopepithisi}
                        errors={formik.errors.autopepithisi}
                        touched={formik.touched.autopepithisi}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 5*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='aneksartisia' 
                        formik={formik}
                        label={t('aneksartisia')} 
                        name='aneksartisia'
                        value={formik.values.aneksartisia}
                        errors={formik.errors.aneksartisia}
                        touched={formik.touched.aneksartisia}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 6*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='ntropalotita' 
                        formik={formik}
                        label={t('ntropalotita')} 
                        name='ntropalotita'
                        value={formik.values.ntropalotita}
                        errors={formik.errors.ntropalotita}
                        touched={formik.touched.ntropalotita}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 7*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='deiliafovos' 
                        formik={formik}
                        label={t('deiliafovos')} 
                        name='deiliafovos'
                        value={formik.values.deiliafovos}
                        errors={formik.errors.deiliafovos}
                        touched={formik.touched.deiliafovos}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 8*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='epithetikotita' 
                        formik={formik}
                        label={t('epithetikotita')} 
                        name='epithetikotita'
                        value={formik.values.epithetikotita}
                        errors={formik.errors.epithetikotita}
                        touched={formik.touched.epithetikotita}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 9*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='kiriarxia' 
                        formik={formik}
                        label={t('kiriarxia')} 
                        name='kiriarxia'
                        value={formik.values.kiriarxia}
                        errors={formik.errors.kiriarxia}
                        touched={formik.touched.kiriarxia}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 10*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='koinwnikotita' 
                        formik={formik}
                        label={t('koinwnikotita')} 
                        name='koinwnikotita'
                        value={formik.values.koinwnikotita}
                        errors={formik.errors.koinwnikotita}
                        touched={formik.touched.koinwnikotita}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 11*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='prosarmostikotita' 
                        formik={formik}
                        label={t('prosarmostikotita')} 
                        name='prosarmostikotita'
                        value={formik.values.prosarmostikotita}
                        errors={formik.errors.prosarmostikotita}
                        touched={formik.touched.prosarmostikotita}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 12*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='xalarotita' 
                        formik={formik}
                        label={t('xalarotita')} 
                        name='xalarotita'
                        value={formik.values.xalarotita}
                        errors={formik.errors.xalarotita}
                        touched={formik.touched.xalarotita}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 13*TIME }}
                    style={{maxWidth:500, position:'relative'}}
                >
                    <RangeSlider 
                        theme={theme} 
                        type='athlitikotita' 
                        formik={formik}
                        label={t('athlitikotita')} 
                        name='athlitikotita'
                        value={formik.values.athlitikotita}
                        errors={formik.errors.athlitikotita}
                        touched={formik.touched.athlitikotita}
                    />
                </motion.div>

                <div className={styles.SeperatorS}></div>
            

                <motion.div
                    style={{maxWidth:280, marginTop:80, marginBottom:150}}
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 14*TIME }}
                >
                    <PrimaryBtnLarge type='submit' fullWidth title={t('completeProfile')} />
                </motion.div>
            
            </form>

            

        </>
    )
}
