import React from 'react'
import styles from './index.module.css'

export default function index({size = 50}) {
  return (
    <svg className={styles.Loader} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 100 100">
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#a05afb" />
            <stop offset="25%" stopColor="#59e6fc" />
            <stop offset="50%" stopColor="#eee51b" />
            <stop offset="75%" stopColor="#ff6085" />
            <stop offset="100%" stopColor="#5eaefd" />
            </linearGradient>
        </defs>
        <circle cx="50" cy="50" r="47" stroke="url(#gradient)" strokeWidth="6" fill="none" transform='rotate(90 50 50)'/>
    </svg>
  )
}
