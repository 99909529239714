import React, {useState, useRef, useEffect, useCallback} from 'react'
import { RiArrowDropDownLine } from "react-icons/ri";
import Fuse from 'fuse.js'
import { motion } from "framer-motion"
import { useDraggable } from "react-use-draggable-scroll";
import { useTranslation } from 'react-i18next';
import styles from './index.module.css'

import ListItem from './ListItem'
import ChipItem from './ChipItem'
import ToolTip from '../../ToolTip';

export default function MultiSelect({
    label, 
    inputType, 
    ListItemType,
    placeholder, 
    formik,
    value,
    name, 
    errors, 
    touched, 
    hasErrorOutside = null, 
    noLabel = false, 
    theme, 
    data, 
    id, 
    hasToolTip = false,
    toolTipContent = null,
    toolTipPlacement = null,
    isToolTipInfo = false
}) {

    const [openedDropDown, setOpenedDropDown] = useState(false);
    const [searchResults, setSearchResults] = useState(data);
    const [selected, setSelected] = useState(value ? value : []);

    const { setFieldValue } = formik;
    const [t] = useTranslation();

    const openDropDown = () => {
        setOpenedDropDown(true)
    }
    
    const ref = useRef();
    const refInput = useRef();
    const refSelected = useRef();
    const { events } = useDraggable(refSelected);
    useOnClickOutside(ref, () => setOpenedDropDown(false));


    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target) || refInput.current.contains(event.target) || refSelected.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }


    const options = {
        shouldSort: true,
        minMatchCharLength: 3,
        keys: ['title','en','alt'],
        threshold:0.2,
        // threshold:1.0,
        includeMatches: true,
        // findAllMatches: true,
        // useExtendedSearch: true,
        ignoreLocation: true
    }
      
    const fuse = new Fuse(data, options)
      

    const liveSearch = (e) =>{
        const length = e.target.value.length;
        if(length >= 3){
            const searchedObjects = []
            const result = fuse.search(e.target.value);
            result.forEach((item, index) => {
                searchedObjects.push(item.item)
                return;
            });
            setSearchResults(searchedObjects);
        }else{
            setSearchResults(data);
        }
    }


    // const ListItemAppender = (id) => {
    //     setSelected([...selected, id]);
    //     refInput.current.value = '';
    //     setSearchResults(data);
    // }
    // const ListItemRemover = (id) => {
    //     setSelected(selected.filter((i)=>(i !== id)))
    //     refInput.current.value = '';
    //     setSearchResults(data);
    // }




    const renderItem = useCallback(
        (item) => (
            selected.includes(item.id) ? (
                <ListItem ListItemType={ListItemType} key={item.id} item={item} theme={theme} onClick={()=>{
                    // ListItemRemover(item.id)
                    setSelected(selected.filter((i)=>(i !== item.id)));
                    refInput.current.value = '';
                    setSearchResults(data)
                }} checked />
            ):(
                <ListItem ListItemType={ListItemType} key={item.id} item={item} theme={theme} onClick={()=>{
                    // ListItemAppender(item.id)
                    setSelected([...selected, item.id]);
                    refInput.current.value = '';
                    setSearchResults(data)
                }} />
            )
        ),
        [selected, theme, data, ListItemType]
    );


    const renderSelectedItem = useCallback(
        (item) => (
            <ChipItem 
                key={data.find(x => x.id === item).id}
                id={data.find(x => x.id === item).id}
                title={data.find(x => x.id === item).title}
                theme={theme}
                onClick={()=>{
                    // ListItemRemover(data.find(x => x.id === item).id)
                    setSelected(selected.filter((i)=>(i !== data.find(x => x.id === item).id)))
                    refInput.current.value = '';
                    setSearchResults(data);
                }}
            />
        ),
        [selected, theme, data]
    );


    const variants = {
        SelectedActive: { translateY: 36, transition: { duration: 0.125 } },
        SelectedInActive: { translateY: 0, transition: { duration: 0.125 } },
      };



      useEffect(() => {
        setFieldValue(name, selected)
      }, [selected, setFieldValue, name])
      


  return (
    <>
        {noLabel ? (
            null
        ):(
            <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
        )}
        <div id={id} className={
            ((errors && touched) || hasErrorOutside) ? (
                theme === 'light' ? (
                    `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                ):(
                    `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                )     
            ): (
                theme === 'light' ? (
                    styles.InputOuterLight
                ):(
                    styles.InputOuterDark
                )
            )}>
            <input 
                className={theme === 'light' ? styles.InputLight : styles.InputDark}
                // name={name}
                type={inputType} 
                onFocus={()=>openDropDown()}
                onClick={()=>openDropDown()}
                onChange={liveSearch}
                ref={refInput}
                placeholder={placeholder}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={value}
            />
            <div className={theme === 'light' ? styles.DropDownChevronLight : styles.DropDownChevronDark}>
                <RiArrowDropDownLine size={26} />
            </div>
        </div>

        {
            hasToolTip ? (
                <ToolTip
                    id={id}
                    theme={theme}
                    toolTipContent={toolTipContent}
                    toolTipPlacement={toolTipPlacement}
                    isToolTipInfo={isToolTipInfo}
                />
            ):null
        }
        

        <div style={{position:'relative'}}>
            <div ref={refSelected} {...events} className={styles.SelectedItemsContainer} style={{paddingTop: selected?.length > 0 ? 4 : 0,paddingBottom: selected.length > 0 ? 4 : 0}}>
            {
                selected?.length > 0 ? (
                    selected.map(renderSelectedItem)
                ):null
            }
            </div>
            {
                openedDropDown ? (
                    <motion.div 
                        onMouseLeave={() => setOpenedDropDown(false)}
                        variants={variants}
                        animate={selected?.length > 0 ? 'SelectedActive' : 'SelectedInActive' }
                        style={{position:'absolute', top:0, width:'100%', zIndex:2}}
                    >   
                        <motion.div 
                            className={theme === 'light' ? `${styles.DropDownContainerLight}` : `${styles.DropDownContainerDark}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}
                            ref={ref}
                        >   
                            
                            {
                                searchResults?.length >= 1 ? searchResults.map(renderItem) : (
                                    <div className={theme === 'light' ? styles.NoResultsLight : styles.NoResultsDark}>{t('thereAreNoResults')}</div>
                                )
                            }
                        </motion.div>
                    </motion.div>
                ):null
            }
        </div>
        
        {(errors && touched) &&
            <div style={{ marginTop:4}}>
            <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
            </div>
        }
    </>
  )
}