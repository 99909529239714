import React from 'react'
import { RiCloseLine } from "react-icons/ri";
import { motion } from "framer-motion"
import styles from './index.module.css'

export default function ChipItem({id, title, onClick, theme}) {
  return (
    <motion.div
        className={theme === 'light' ? `${styles.ChipLight}` : `${styles.ChipDark}`}
        initial={{ opacity: 0, translateY:10 }}
        animate={{ opacity: 1, translateY:0 }}
        exit={{ opacity: 0, translateY:10 }}
        transition={{ duration: 0.25 }}
        key={id} >
            {title}
            <RiCloseLine
                onClick={onClick}
                className={theme === 'light' ? styles.DeleteChipLight : styles.DeleteChipDark}
            />
    </motion.div>
  )
}
