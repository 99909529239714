const DogBreeds = [
    {title:'Ημίαιμο', id:1, en:'Half-blood', alt:'ημιαιμο'},
    {title:'Αφενπίνσερ', id:2, en:'Affenpinscher', alt:'αφενπινσερ'},
    {title:'Αφγανικό κυνηγόσκυλο', id:3, en:'Afghan Hound', alt:'αφγανικο κυνηγοσκυλο'},
    {title:'Έρντεϊλ Τεριέ', id:4, en:'Airedale Terrier', alt:'ερντειλ τεριε'},
    {title:'Μαλαμούτ Αλάσκας', id:5, en:'Alaskan Malamute', alt:'μαλαμουτ αλασκας'},
    {title:'Ποιμενικός Ανατολίας', id:6, en:'Anatolian Shepherd', alt:'ποιμενικος ανατολιας'},
    {title:'Αυστραλιανός Σκύλος Βοοειδών', id:7, en:'Australian Cattle Dog', alt:'αυστραλιανος σκυλος βοοειδων'},
    {title:'Ποιμενικός Αυστραλίας', id:8, en:'Australian Shepherd Dog', alt:'ποιμενικος αυστραλιας'},
    {title:'Αυστραλιανό Σίλκι Τεριέ', id:9, en:'Australian Silky Terrier', alt:'αυστραλιανο σιλκι τεριε'},
    {title:'Αυστραλιανό Τεριέ', id:10, en:'Australian Terrier', alt:'αυστραλιανο τεριε'},
    {title:'Μπασέντζι', id:11, en:'Basenji', alt:'μπασεντζι'},
    {title:'Μπασέ Μπλε της Γασκώνης', id:12, en:'Basset Bleu De Gascogne', alt:'μπασε μπλε της γασκωνης'},
    {title:'Μπασέ Φοβ της Βρετάνης', id:13, en:'Basset Fauve De Bretagne', alt:'μπασε φοβ της βρετανης'},
    {title:'Μπασέ Γκριφόν Βανδέας (Μεγάλο)', id:14, en:'Basset Griffon Vendeen (Grand)', alt:'μπασε γκριφον βανδεας (μεγαλο)'},
    {title:'Μπασέ Γκριφόν Βανδέας (Μικρό)', id:15, en:'Basset Griffon Vendeen (Petit)', alt:'μπασε γκριφον βανδεας (μικρο)'},
    {title:'Κυνηγόσκυλο Μπασέ', id:16, en:'Basset Hound', alt:'κυνηγοσκυλο μπασε'},
    {title:'Βαυαρικό ορεινό κυνηγόσκυλο', id:17, en:'Bavarian Mountain Hound', alt:'βαυαρικο ορεινο κυνηγοσκυλο'},
    {title:'Μπιγκλ', id:18, en:'Beagle', alt:'μπιγκλ'},
    {title:'Γενειοφόρος Κόλεϊ', id:19, en:'Bearded Collie', alt:'γενειοφορος κολει'},
    {title:'Μποσερόν', id:20, en:'Beauceron', alt:'μποσερον'},
    {title:'Μπέντλινγκτον Τεριέ', id:21, en:'Bedlington Terrier', alt:'μπεντλινγκτον τεριε'},
    {title:'Βέλγικος Ποιμενικός Γκρένενταλ', id:22, en:'Belgian Shepherd Dog Groenendael', alt:'βελγικος ποιμενικος γκρενενταλ'},
    {title:'Βέλγικος Ποιμενικός Λακενουά', id:23, en:'Belgian Shepherd Dog Laekenois', alt:'βελγικος ποιμενικος λακενουα'},
    {title:'Βέλγικος Ποιμενικός Μαλινουά', id:24, en:'Belgian Shepherd Dog Malinois', alt:'βελγικος ποιμενικος μαλινουα'},
    {title:'Βέλγικος Ποιμενικός Τερβύρεν', id:25, en:'Belgian Shepherd Dog Tervueren', alt:'βελγικος ποιμενικος τερβυρεν'},
    {title:'Μπεργκαμάσκο', id:26, en:'Bergamasco', alt:'μπεργκαμασκο'},
    {title:'Ορεινός Σκύλος Βέρνης', id:27, en:'Bernese Mountain Dog', alt:'ορεινος σκυλος βερνης'},
    {title:'Μπισόν Φριζέ', id:28, en:'Bichon Frise', alt:'μπισον φριζε'},
    {title:'Μπλάντ Χάουντ', id:29, en:'Bloodhound', alt:'μπλαντ χαουντ'},
    {title:'Μπολονέζ', id:30, en:'Bolognese', alt:'μπολονεζ'},
    {title:'Μπόρντερ Κόλεϊ', id:31, en:'Border Collie', alt:'μποντερ κολει'},
    {title:'Μπόρντερ Τεριέ', id:32, en:'Border Terrier', alt:'μποντερ τεριε'},
    {title:'Μπορζόϊ', id:33, en:'Borzoi', alt:'μπορζοι'},
    {title:'Μπόστον Τεριέ', id:34, en:'Boston Terrier', alt:'μποστον τεριε'},
    {title:'Μπουβιέ της Φλάντρας', id:35, en:'Bouvier Des Flandres', alt:'μπουβιε της φλαντρας'},
    {title:'Μπόξερ', id:36, en:'Boxer', alt:'μποξερ'},
    {title:'Μπράκο Ιταλιάνο', id:37, en:'Bracco Italiano', alt:'μπρακο ιταλιανο'},
    {title:'Μπριάρ', id:38, en:'Briard', alt:'μπριαρ'},
    {title:'Μπρετόν', id:39, en:'Brittany', alt:'μπρετον'},
    {title:'Μπουλ Τεριέ', id:40, en:'Bull Terrier', alt:'μπουλ τεριε'},
    {title:'Μπουλ Τεριέ (Μινιατούρα)', id:41, en:'Bull Terrier Miniature', alt:'μπουλ τεριε (μινιατουρα)'},
    {title:'Μπουλ Ντογκ', id:42, en:'Bulldog', alt:'μπουλ ντογκ'},
    {title:'Μπουλμαστίφ', id:43, en:'Bullmastiff', alt:'μπουλμαστιφ'},
    {title:'Κερν Τεριέ', id:44, en:'Cairn Terrier', alt:'κερν τεριε'},
    {title:'Σκύλος της Χαναάν', id:45, en:'Canaan Dog', alt:'σκυλος της χανααν'},
    {title:'Καναδέζικο Έσκιμο', id:46, en:'Canadian Eskimo Dog', alt:'καναδεζικο εσκιμο'},
    {title:'Κάβαλιερ Κινγκ Τσαρλς Σπάνιελ', id:47, en:'Cavalier King Charles Spaniel', alt:'καβαλιερ κινγκ τσαρλς σπανιελ'},
    {title:'Τσέσκυ Τεριέ', id:48, en:'Cesky Terrier', alt:'τσεσκυ τεριε'},
    {title:'Τσιουάουα (Μακρύτριχο)', id:49, en:'Chihuahua (Long Coat)', alt:'τσιουαουα (μακρυτριχο)'},
    {title:'Τσιουάουα (Κοντότριχο)', id:50, en:'Chihuahua (Smooth Coat)', alt:'τσιουαουα (κοντοτριχο)'},
    {title:'Κινέζικο Κρέστιντ', id:51, en:'Chinese Crested', alt:'κινεζικο κρεστιντ'},
    {title:'Τσόου-Τσόου', id:52, en:'Chow Chow', alt:'τσοου-τσοου'},
    {title:'Κόλεϊ (Σκληρό τρίχωμα)', id:53, en:'Collie (Rough)', alt:'κολει (σκληρο τριχωμα)'},
    {title:'Κόλεϊ (Απαλό τρίχωμα)', id:54, en:'Collie (Smooth)', alt:'κολει (απαλο τριχωμα)'},
    {title:'Κοτόν ντε Τουλεάρ', id:55, en:'Coton De Tulear', alt:'κοτον ντε τουλεαρ'},
    {title:'Ντάτσχουντ (Μακρύτριχο)', id:56, en:'Dachshund (Long-Haired)', alt:'ντατσχουντ (μακρυτριχο), λουκανικο'},
    {title:'Ντάτσχουντ (Μινιατούρα Μακρύτριχο)', id:57, en:'Dachshund (Miniature Long-Haired)', alt:'ντατσχουντ (μινιατουρα μακρυτριχο), λουκανικο'},
    {title:'Ντάτσχουντ (Μινιατούρα Απαλό τρίχωμα)', id:58, en:'Dachshund (Miniature Smooth-Haired)', alt:'ντατσχουντ (μινιατουρα απαλο τριχωμα), λουκανικο'},
    {title:'Ντάτσχουντ (Μινιατούρα Σκληρό τρίχωμα)', id:59, en:'Dachshund (Miniature Wire-Haired)', alt:'ντατσχουντ (μινιατουρα σκληρό τριχωμα), λουκανικο'},
    {title:'Ντάτσχουντ (Απαλό τρίχωμα)', id:60, en:'Dachshund (Smooth-Haired)', alt:'ντατσχουντ (απαλο τριχωμα), λουκανικο'},
    {title:'Ντάτσχουντ (Σκληρό τρίχωμα)', id:61, en:'Dachshund (Wire-Haired)', alt:'ντατσχουντ (σκληρό τριχωμα), λουκανικο'},
    {title:'Δαλματίας', id:62, en:'Dalmatian', alt:'δαλματιας'},
    {title:'Ντάντι Ντινμοντ Τεριέ', id:63, en:'Dandie Dinmont Terrier', alt:'νταντι ντινμοντ τεριε'},
    {title:'Ντίρχαουντ', id:64, en:'Deerhound', alt:'ντιρχαουντ'},
    {title:'Ντόμπερμαν', id:65, en:'Dobermann', alt:'ντομπερμαν'},
    {title:'Ντογκ ντε Μπορντώ', id:66, en:'Dogue de Bordeaux', alt:'ντογκ ντε μπορντω'},
    {title:'Αγγλικό Σέττερ', id:67, en:'English Setter', alt:'αγγλικο σεττερ'},
    {title:'Αγγλικό Τόι Τεριέ', id:68, en:'English Toy Terrier (Black & Tan)', alt:'αγγλικο τοι τεριε'},
    {title:'Ορεινό Εστρέλλας', id:69, en:'Estrela Mountain Dog', alt:'ορεινο εστρελλας'},
    {title:'Φινλανδικό Λάπχουντ', id:70, en:'Finnish Lapphund', alt:'φινλανδικο λαπχουντ'},
    {title:'Φινλανδικό Σπιτζ', id:71, en:'Finnish Spitz', alt:'φινλανδικο σπιτζ'},
    {title:'Φοξ Τεριέ (Ίσιο τρίχωμα)', id:72, en:'Fox Terrier (Smooth Coat)', alt:'φοξ τεριε (ισιο τριχωμα)'},
    {title:'Φοξ Τεριέ (Σκληρό τρίχωμα)', id:73, en:'Fox Terrier (Wire Coat)', alt:'φοξ τεριε (σκληρο τριχωμα)'},
    {title:'Φοξχάουντ', id:74, en:'Foxhound', alt:'φοξχαουντ'},
    {title:'Γαλλικό Μπουλ Ντογκ', id:75, en:'French Bulldog', alt:'γαλλικο μπουλ ντογκ'},
    {title:'Γερμανικό Πίνσερ', id:76, en:'German Pinscher', alt:'γερμανικο πινσερ'},
    {title:'Γερμανικός Ποιμενικός', id:77, en:'German Shepherd Dog', alt:'γερμανικος ποιμενικος'},
    {title:'Γερμανικό Πόιντερ (Κοντότριχο)', id:78, en:'German Shorthaired Pointer', alt:'γερμανικο ποιντερ (κοντοτριχο)'},
    {title:'Γερμανικό Σπιτζ (Klein)', id:79, en:'German Spitz (Klein)', alt:'γερμανικο σπιτζ (klein)'},
    {title:'Γερμανικό Σπιτζ (Mittel)', id:80, en:'German Spitz (Mittel)', alt:'γερμανικο σπιτζ (mittel)'},
    {title:'Γερμανικό Πόιντερ (Σκληρό τρίχωμα)', id:81, en:'German Wirehaired Pointer', alt:'γερμανικο ποιντερ (σκληρο τριχωμα)'},
    {title:'Σνάουτσερ Γίγας', id:82, en:'Giant Schnauzer', alt:'σναουτσερ γιγας'},
    {title:'Γκόρντον Σέττερ', id:83, en:'Gordon Setter', alt:'γκορντον σεττερ'},
    {title:'Μεγάλος Μπλε της Γασκώνης', id:84, en:'Grand Bleu De Gascogne', alt:'μεγαλος μπλε της γασκωνης'},
    {title:'Γερμανικός Μολοσσός', id:85, en:'Great Dane', alt:'γερμανικος μολοσσος'},
    {title:'Γκρεϊχάουντ', id:86, en:'Greyhound', alt:'γκρειχαουντ'},
    {title:'Γκριφόν Βρυξελλών', id:87, en:'Griffon Bruxellois', alt:'γκριφον βρυξελλων'},
    {title:'Χάμιλτον Στοβάρε', id:88, en:'Hamiltonstovare', alt:'χαμιλτον στοβαρε'},
    {title:'Μπισόν Χαβανέζ', id:89, en:'Havanese', alt:'μπισον χαβανεζ'},
    {title:'Χόβαβαρτ', id:90, en:'Hovawart', alt:'χοβαβαρτ'},
    {title:'Ουγγρικό Καβάζ', id:91, en:'Hungarian Kuvasz', alt:'ουγγρικο καβαζ'},
    {title:'Ουγγρικό Πούλι', id:92, en:'Hungarian Puli', alt:'ουγγρικο πουλι'},
    {title:'Ουγγρικό Βίζλα', id:93, en:'Hungarian Vizsla', alt:'ουγγρικο βιζλα'},
    {title:'Ουγγρικό Βίζλα (Σκληρό τρίχωμα)', id:94, en:'Hungarian Wire Haired Vizsla', alt:'ουγγρικο βιζλα (σκληρο τριχωμα)'},
    {title:'Κυνηγόσκυλο της Ίμπιζα', id:95, en:'Ibizan Hound', alt:'κυνηγοσκυλο της ιμπιζα'},
    {title:'Ιρλανδικό Ερυθρόλευκο Σέττερ', id:96, en:'Irish Red & White Setter', alt:'ιρλανδικο ερυθρολευκο σεττερ'},
    {title:'Ιρλανδικό Σέττερ', id:97, en:'Irish Setter', alt:'ιρλανδικο σεττερ'},
    {title:'Ιρλανδικό Τεριέ', id:98, en:'Irish Terrier', alt:'ιρλανδικο τεριε'},
    {title:'Ιρλανδικό Λυκόσκυλο', id:99, en:'Irish Wolfhound', alt:'ιρλανδικο λυκοσκυλο'},
    {title:'Ιταλικό Γκρεϊχάουντ', id:100, en:'Italian Greyhound', alt:'ιταλικο γκρειχαουντ'},
    {title:'Ιταλικό Σπινόνε', id:101, en:'Italian Spinone', alt:'ιταλικο σπινονε'},
    {title:'Ιαπωνέζικο Ακίτα', id:102, en:'Japanese Akita', alt:'ιαπωνεζικο ακιτα'},
    {title:'Ιαπωνέζικο Τσιν', id:103, en:'Japanese Chin', alt:'ιαπωνεζικο τσιν'},
    {title:'Ιαπωνέζικο Σίμπα Ινού', id:104, en:'Japanese Shiba Inu', alt:'ιαπωνεζικο σιμπα ινου'},
    {title:'Ιαπωνέζικο Σπιτζ', id:105, en:'Japanese Spitz', alt:'ιαπωνεζικο σπιτζ'},
    {title:'Κίσχοντ', id:106, en:'Keeshond', alt:'κισχοντ'},
    {title:'Κέρι Μπλου Τεριέ', id:107, en:'Kerry Blue Terrier', alt:'κερι μπλου τεριε'},
    {title:'Κινγκ Τσαρλς Σπάνιελ', id:108, en:'King Charles Spaniel', alt:'κινγκ τσαρλς σπανιελ'},
    {title:'Κομοντόρ', id:109, en:'Komondor', alt:'κομοντορ'},
    {title:'Κοϊκερόντζ', id:110, en:'Kooikerhondje', alt:'κοικεροντζ'},
    {title:'Λέικλαντ Τεριέ', id:111, en:'Lakeland Terrier', alt:'λεικλαντ τεριε'},
    {title:'Λάνκασαϊρ Χίλερ', id:112, en:'Lancashire Heeler', alt:'λανκασαιρ χιλερ'},
    {title:'Λεονμπέργκερ', id:113, en:'Leonberger', alt:'λεονμπεργκερ'},
    {title:'Λάσα Άπσο', id:114, en:'Lhasa Apso', alt:'λασα απσο'},
    {title:'Λόουτσεν (Μικρό λιοντάρι)', id:115, en:'Lowchen (Little Lion Dog)', alt:'λοουτσεν (μικρο λιονταρι)'},
    {title:'Μαλτέζ', id:116, en:'Maltese', alt:'μαλτεζ'},
    {title:'Μάντσεστερ Τεριέ', id:117, en:'Manchester Terrier', alt:'μαντσεστερ τεριε'},
    {title:'Ποιμενικός Μαρεμάνο', id:118, en:'Maremma Sheepdog', alt:'ποιμενικος μαρεμανο'},
    {title:'Μαστίφ', id:119, en:'Mastiff', alt:'μαστιφ'},
    {title:'Πίνσερ μινιατούρα', id:120, en:'Miniature Pinscher', alt:'πινσερ μινιατουρα'},
    {title:'Σνάουτσερ μινιατούρα', id:121, en:'Miniature Schnauzer', alt:'σναουτσερ μινιατουρα'},
    {title:'Μυνστερλάντερ', id:122, en:'Munsterlander', alt:'μυνστερλαντερ'},
    {title:'Μαστίφ της Νάπολης', id:123, en:'Neapolitan Mastiff', alt:'μαστιφ της ναπολης'},
    {title:'Νιού Φάουντλαντ', id:124, en:'Newfoundland', alt:'νιου φαουντλαντ'},
    {title:'Νόρφολκ Τεριέ', id:125, en:'Norfolk Terrier', alt:'νορφολκ τεριε'},
    {title:'Νορβηγικό Μπουχούντ', id:126, en:'Norwegian Buhund', alt:'νορβηγικο μπουχουντ'},
    {title:'Ελκχάουντ Νορβηγίας', id:127, en:'Norwegian Elkhound', alt:'ελκχαουντ νορβηγιας'},
    {title:'Νόργουιτς Τεριέ', id:128, en:'Norwich Terrier', alt:'νοργουιτς τεριε'},
    {title:'Παλαιός Αγγλικός Ποιμενικός (Μπομπτέιλ)', id:129, en:'Old English Sheepdog (Bobtail)', alt:'παλαιος αγγλικος ποιμενικος (μπομπτειλ)'},
    {title:'Οτερχάουντ', id:130, en:'Otterhound', alt:'οτερχαουντ'},
    {title:'Παπιγιόν', id:131, en:'Papillon', alt:'παπιγιον'},
    {title:'Πάρσον Ράσελ Τεριέ', id:132, en:'Parson Russell Terrier', alt:'παρσον ρασελ τεριε'},
    {title:'Κυνηγόσκυλο του Φαραώ', id:133, en:'Pharaoh Hound', alt:'κυνηγοσκυλο του φαραω'},
    {title:'Πόιντερ', id:134, en:'Pointer', alt:'ποιντερ'},
    {title:'Πολωνικός Ποιμενικός Νιζινί', id:135, en:'Polish Lowland Sheepdog', alt:'πολωνικος ποιμενικος νιζινι'},
    {title:'Πομεράνιαν', id:136, en:'Pomeranian', alt:'πομερανιαν'},
    {title:'Πουντλ / Κανίς (Μινιατούρα)', id:137, en:'Poodle (Miniature)', alt:'πουντλ (μινιατουρα), κανις'},
    {title:'Πουντλ / Κανίς (Κανονικό)', id:138, en:'Poodle (Standard)', alt:'πουντλ (κανονικο), κανις'},
    {title:'Πουντλ / Κανίς (Τόι)', id:139, en:'Poodle (Toy)', alt:'πουντλ (τοι), κανις'},
    {title:'Πορτογαλικό Σκυλί του Νερού', id:140, en:'Portuguese Water Dog', alt:'πορτογαλικο σκυλι του νερου'},
    {title:'Παγκ', id:141, en:'Pug', alt:'παγκ'},
    {title:'Πυρηναίος Ορεινός', id:142, en:'Pyrenean Mountain Dog', alt:'πυρηναιος ορεινος'},
    {title:'Πυρηναίο Τσοπανόσκυλο (Μακρύτριχο)', id:143, en:'Pyrenean Sheepdog (Long Haired)', alt:'πυρηναιο τΤσοπανοσκυλο (μακρυτριχο)'},
    {title:'Ριτρίβερ (Κόλπος Τσέζαπικ)', id:144, en:'Retriever (Chesapeake Bay)', alt:'ριτριβερ (κολπος τσεζαπικ)'},
    {title:'Ριτρίβερ (Σγουρό τρίχωμα)', id:145, en:'Retriever (Curly Coated)', alt:'ριτριβερ (σγουρο τριχωμα)'},
    {title:'Ριτρίβερ (Επίπεδο τρίχωμα)', id:146, en:'Retriever (Flat Coated)', alt:'ριτριβερ (επίπεδο τριχωμα)'},
    {title:'Ριτρίβερ (Γκόλντεν)', id:147, en:'Retriever (Golden)', alt:'ριτριβερ (γκολντεν)'},
    {title:'Ριτρίβερ (Λαμπραντόρ)', id:148, en:'Retriever (Labrador)', alt:'ριτριβερ (λαμπραντορ)'},
    {title:'Ριτρίβερ (Νέας Σκωτίας)', id:149, en:'Retriever (Nova Scotia Duck Tolling)', alt:'ριτριβερ (νεας σκωτιας)'},
    {title:'Ροδεσιανό Ρίτζμπακ', id:150, en:'Rhodesian Ridgeback', alt:'ροδεσιανο ριτζμπακ'},
    {title:'Ροτβάιλερ', id:151, en:'Rottweiler', alt:'ροτβαιλερ'},
    {title:'Σαλούκι', id:152, en:'Saluki', alt:'σαλουκι'},
    {title:'Σαμογιέντ', id:153, en:'Samoyed', alt:'σαμογιεντ'},
    {title:'Σκίπερκι', id:154, en:'Schipperke', alt:'σκιπερκι'},
    {title:'Σνάουτσερ (Κανονικό)', id:155, en:'Schnauzer Standard', alt:'σναουτσερ (κανονικο)'},
    {title:'Σκοτσέζικο Τεριέ', id:156, en:'Scottish Terrier', alt:'σκοτσεζικο τεριε'},
    {title:'Σίλιχαμ Τεριέ', id:157, en:'Sealyham Terrier', alt:'σιλιχαμ τεριε'},
    {title:'Ιταλικός Ιχνηλάτης (Segugio)', id:158, en:'Segugio Italiano', alt:'ιταλικος ιχνηλατης (segugio)'},
    {title:'Σαρ Πέι', id:159, en:'Shar Pei', alt:'σαρ πει'},
    {title:'Ποιμενικός Σέτλαντ', id:160, en:'Shetland Sheepdog', alt:'ποιμενικος σετλαντ'},
    {title:'Σιχ Τσου', id:161, en:'Shih Tzu', alt:'σιχ τσου'},
    {title:'Σιβηρικό Χάσκυ', id:162, en:'Siberian Husky', alt:'σιβηρικο χασκυ'},
    {title:'Σκάι Τεριέ', id:163, en:'Skye Terrier', alt:'σκαι τεριε'},
    {title:'Σλούγκι', id:164, en:'Sloughi', alt:'σλουγκι'},
    {title:'Λειότριχο Λευκόμαλο Τεριέ', id:165, en:'Soft-Coated Wheaten Terrier', alt:'λειοτριχο λευκομαλο τεριε'},
    {title:'Σπάνιελ (Αμερικάνικο Κόκερ)', id:166, en:'Spaniel (American Cocker)', alt:'σπανιελ (αμερικανικο κοκερ)'},
    {title:'Σπάνιελ (Κλάμπερ)', id:167, en:'Spaniel (Clumber)', alt:'σπανιελ (κλαμπερ)'},
    {title:'Σπάνιελ (Κόκερ)', id:168, en:'Spaniel (Cocker)', alt:'σπανιελ (κοκερ)'},
    {title:'Σπάνιελ (Αγγλικό Σπρίνγκερ)', id:169, en:'Spaniel (English Springer)', alt:'σπανιελ (αγγλικο σπρινγκερ)'},
    {title:'Σπάνιελ (Φιλντ)', id:170, en:'Spaniel (Field)', alt:'σπανιελ (φιλντ)'},
    {title:'Σπάνιελ (Άιρις Γουότερ)', id:171, en:'Spaniel (Irish Water)', alt:'σπανιελ (αιρις γουοτερ)'},
    {title:'Σπάνιελ (Σάσεξ)', id:172, en:'Spaniel (Sussex)', alt:'σπανιελ (σασεξ)'},
    {title:'Σπάνιελ (Ουαλικό Σπρίνγκερ)', id:173, en:'Spaniel (Welsh Springer)', alt:'σπανιελ (ουαλικο σπρινγκερ)'},
    {title:'Ισπανικός Σκύλος του Νερού', id:174, en:'Spanish Water Dog', alt:'ισπανικος σκυλος του νερου'},
    {title:'Αγίου Βερνάρδου', id:175, en:'St. Bernard', alt:'αγιου βερναρδου'},
    {title:'Στάφορντσαϊρ Μπουλ Τεριέ', id:176, en:'Staffordshire Bull Terrier', alt:'σταφορντσαιρ μπουλ τεριε'},
    {title:'Σουηδικό Λάπχουντ', id:177, en:'Swedish Lapphund', alt:'σουηδικο λαπχουντ'},
    {title:'Σουηδικό Βάλχουντ', id:178, en:'Swedish Vallhund', alt:'σουηδικο βαλχουντ'},
    {title:'Θιβετιανό Μαστίφ', id:179, en:'Tibetan Mastiff', alt:'θιβετιανο μαστιφ'},
    {title:'Θιβετιανό Σπάνιελ', id:180, en:'Tibetan Spaniel', alt:'θιβετιανο σπανιελ'},
    {title:'Θιβετιανό Τεριέ', id:181, en:'Tibetan Terrier', alt:'θιβετιανο τεριε'},
    {title:'Βαϊμαράνερ', id:182, en:'Weimaraner', alt:'βαιμαρανερ'},
    {title:'Ουαλικό Κόργκι (Κάρντιγκαν)', id:183, en:'Welsh Corgi (Cardigan)', alt:'ουαλικο κοργκι (καρντιγκαν)'},
    {title:'Ουαλικό Κόργκι (Πέμπροκ)', id:184, en:'Welsh Corgi (Pembroke)', alt:'ουαλικο κοργκι (πεμπροκ)'},
    {title:'Ουαλικό Τεριέ', id:185, en:'Welsh Terrier', alt:'ουαλικο τεριε'},
    {title:'Γουέστ Χάιλαντ Άσπρο Τεριέ', id:186, en:'West Highland White Terrier', alt:'γουεστ χαιλαντ ασπρο τεριε'},
    {title:'Γουίπετ', id:187, en:'Whippet', alt:'γουιπετ'},
    {title:'Γιόρκσαϊρ Τεριέ', id:188, en:'Yorkshire Terrier', alt:'γιορκσαιρ τεριε'},
    {title:'Μεξικάνικος Άτριχος', id:189, en:'Mexican Hairless Dog', alt:'μεξικανικος ατριχος'},
    {title:'Τζακ Ράσελ Τεριέ', id:190, en:'Jack Russell Terrier', alt:'τζακ ρασελ τεριε'},
    {title:'Ποιμενικός της Σπάρτης', id:191, en:'Spartan Sheepdog', alt:'ποιμενικος της σπαρτης'},
    {title:'Ελληνικός Ποιμενικός', id:192, en:'Greek Sheepdog', alt:'ελληνικος ποιμενικος'},
    {title:'Ελληνικός Ιχνηλάτης', id:193, en:'Greek Pointer', alt:'ελληνικος ιχνηλατης'},
    {title:'Κρητικός Λαγωνικός/Ιχνηλάτης', id:194, en:'Cretan Greyhound/Pointer', alt:'κρητικος λαγωνικος/ιχνηλατης'},
    {title:'Κοκόνι', id:195, en:'Kokoni', alt:'κοκονι'},
    {title:'Λευκό Ελληνικό Τσοπανόσκυλο', id:196, en:'White Greek Hound', alt:'λευκο ελληνικο τσοπανοσκυλο'},
    {title:'Μολοσσός της Ηπείρου', id:197, en:'Molossus of Epirus', alt:'μολοσσος της ηπειρου'},
    {title:'Αλωπεκίς', id:198, en:'Alopekis', alt:'αλωπεκις'},
    {title:'Πίτμπουλ', id:199, en:'Pitbull', alt:'πιτμπουλ'}
];


export default DogBreeds



// export default [
//     'Half-blood',
//     'Affenpinscher',
//     'Afghan Hound',
//     'Airedale Terrier',
//     'Alaskan Malamute',
//     'Anatolian Shepherd Dog',
//     'Australian Cattle Dog',
//     'Australian Shepherd Dog',
//     'Australian Silky Terrier',
//     'Australian Terrier',
//     'Basenji',
//     'Basset Bleu De Gascogne',
//     'Basset Fauve De Bretagne',
//     'Basset Griffon Vendeen (Grand)',
//     'Basset Griffon Vendeen (Petit)',
//     'Basset Hound',
//     'Bavarian Mountain Hound',
//     'Beagle',
//     'Bearded Collie',
//     'Beauceron',
//     'Bedlington Terrier',
//     'Belgian Shepherd Dog Groenendael',
//     'Belgian Shepherd Dog Laekenois',
//     'Belgian Shepherd Dog Malinois',
//     'Belgian Shepherd Dog Tervueren',
//     'Bergamasco',
//     'Bernese Mountain Dog',
//     'Bichon Frise',
//     'Bloodhound',
//     'Bolognese',
//     'Border Collie',
//     'Border Terrier',
//     'Borzoi',
//     'Boston Terrier',
//     'Bouvier Des Flandres',
//     'Boxer',
//     'Bracco Italiano',
//     'Briard',
//     'Brittany',
//     'Bull Terrier',
//     'Bull Terrier Miniature',
//     'Bulldog',
//     'Bullmastiff',
//     'Cairn Terrier',
//     'Canaan Dog',
//     'Canadian Eskimo Dog',
//     'Cavalier King Charles Spaniel',
//     'Cesky Terrier',
//     'Chihuahua (Long Coat)',
//     'Chihuahua (Smooth Coat)',
//     'Chinese Crested',
//     'Chow Chow (Rough)',
//     'Collie (Rough)',
//     'Collie (Smooth)',
//     'Coton De Tulear',
//     'Dachshund (Long-Haired)',
//     'Dachshund (Miniature Long-Haired)',
//     'Dachshund (Miniature Smooth-Haired)',
//     'Dachshund (Miniature Wire-Haired)',
//     'Dachshund (Smooth-Haired)',
//     'Dachshund (Wire-Haired)',
//     'Dalmatian',
//     'Dandie Dinmont Terrier',
//     'Deerhound',
//     'Dobermann',
//     'Dogue de Bordeaux',
//     'English Setter',
//     'English Toy Terrier (Black & Tan)',
//     'Estrela Mountain Dog',
//     'Finnish Lapphund',
//     'Finnish Spitz',
//     'Fox Terrier Smooth Coat',
//     'Fox Terrier Wire Coat',
//     'Foxhound',
//     'French Bulldog',
//     'German Pinscher',
//     'German Shepherd Dog',
//     'German Shorthaired Pointer',
//     'German Spitz (Klein)',
//     'German Spitz (Mittel)',
//     'German Wirehaired Pointer',
//     'Giant Schnauzer',
//     'Gordon Setter',
//     'Grand Bleu De Gascogne',
//     'Great Dane',
//     'Greyhound',
//     'Griffon Bruxellois',
//     'Hamiltonstovare',
//     'Havanese',
//     'Hovawart',
//     'Hungarian Kuvasz',
//     'Hungarian Puli',
//     'Hungarian Vizsla',
//     'Hungarian Wire Haired Vizsla',
//     'Ibizan Hound',
//     'Irish Red & White Setter',
//     'Irish Setter',
//     'Irish Terrier',
//     'Irish Wolfhound',
//     'Italian Greyhound',
//     'Italian Spinone',
//     'Japanese Akita',
//     'Japanese Chin',
//     'Japanese Shiba Inu',
//     'Japanese Spitz',
//     'Keeshond',
//     'Kerry Blue Terrier',
//     'King Charles Spaniel',
//     'Komondor',
//     'Kooikerhondje',
//     'Lakeland Terrier',
//     'Lancashire Heeler',
//     'Leonberger',
//     'Lhasa Apso',
//     'Lowchen (Little Lion Dog)',
//     'Maltese',
//     'Manchester Terrier',
//     'Maremma Sheepdog',
//     'Mastiff',
//     'Miniature Pinscher',
//     'Miniature Schnauzer',
//     'Munsterlander',
//     'Neapolitan Mastiff',
//     'Newfoundland',
//     'Norfolk Terrier',
//     'Norwegian Buhund',
//     'Norwegian Elkhound',
//     'Norwich Terrier',
//     'Old English Sheepdog (Bobtail)',
//     'Otterhound',
//     'Papillon',
//     'Parson Russell Terrier',
//     'Pharaoh Hound',
//     'Pointer',
//     'Polish Lowland Sheepdog',
//     'Pomeranian',
//     'Poodle (Miniature)',
//     'Poodle (Standard)',
//     'Poodle (Toy)',
//     'Portuguese Water Dog',
//     'Pug',
//     'Pyrenean Mountain Dog',
//     'Pyrenean Sheepdog (Long Haired)',
//     'Retriever (Chesapeake Bay)',
//     'Retriever (Curly Coated)',
//     'Retriever (Flat Coated)',
//     'Retriever (Golden)',
//     'Retriever (Labrador)',
//     'Retriever (Nova Scotia Duck Tolling)',
//     'Rhodesian Ridgeback',
//     'Rottweiler',
//     'Saluki',
//     'Samoyed',
//     'Schipperke',
//     'Schnauzer Standard',
//     'Scottish Terrier',
//     'Sealyham Terrier',
//     'Segugio Italiano',
//     'Shar Pei',
//     'Shetland Sheepdog',
//     'Shih Tzu',
//     'Siberian Husky',
//     'Skye Terrier',
//     'Sloughi',
//     'Soft-Coated Wheaten Terrier',
//     'Spaniel (American Cocker)',
//     'Spaniel (Clumber)',
//     'Spaniel (Cocker)',
//     'Spaniel (English Springer)',
//     'Spaniel (Field)',
//     'Spaniel (Irish Water)',
//     'Spaniel (Sussex)',
//     'Spaniel (Welsh Springer)',
//     'Spanish Water Dog',
//     'St. Bernard',
//     'Staffordshire Bull Terrier',
//     'Swedish Lapphund',
//     'Swedish Vallhund',
//     'Tibetan Mastiff',
//     'Tibetan Spaniel',
//     'Tibetan Terrier',
//     'Weimaraner',
//     'Welsh Corgi (Cardigan)',
//     'Welsh Corgi (Pembroke)',
//     'Welsh Terrier',
//     'West Highland White Terrier',
//     'Whippet',
//     'Yorkshire Terrier',
//     'Mexican Hairless Dog'
//     'Jack Russek Terrier',
//     'Spartan Sheepdog',
//     'Greek Sheepdog',
//     'Greek Pointer',
//     'Cretan Greyhound/Pointer',
//     'Kokoni',
//     'White Greek Hound',
//     'Molossus of Epirus',
//     'Alopekis',
//     'Pitbull'
// ];










// export default [
//     'Ημίαιμος',
//     'Αφενπίνσερ',
//     'Αφγανικό κυνηγόσκυλο',
//     'Έρντεϊλ Τεριέ',
//     'Μαλαμούτ Αλάσκας',
//     'Ποιμενικός Ανατολίας',
//     'Αυστραλιανός Σκύλος Βοοειδών',
//     'Ποιμενικός Αυστραλίας',
//     'Αυστραλιανό Σίλκι Τεριέ',
//     'Αυστραλιανό Τεριέ',
//     'Μπασέντζι',
//     'Μπασέ Μπλε της Γασκώνης',
//     'Μπασέ Φοβ της Βρετάνης',
//     'Μπασέ Γκριφόν Βανδέας (Μεγάλο)',
//     'Μπασέ Γκριφόν Βανδέας (Μικρό)',
//     'Κυνηγόσκυλο Μπασέ',
//     'Βαυαρικό ορεινό κυνηγόσκυλο',
//     'Μπιγκλ',
//     'Γενειοφόρος Κόλεϊ',
//     'Μποσερόν',
//     'Μπέντλινγκτον Τεριέ',
//     'Βέλγικος Ποιμενικός Γκρένενταλ',
//     'Βέλγικος Ποιμενικός Λακενουά',
//     'Βέλγικος Ποιμενικός Μαλινουά',
//     'Βέλγικος Ποιμενικός Τερβύρεν',
//     'Μπεργκαμάσκο',
//     'Ορεινός Σκύλος Βέρνης',
//     'Μπισόν Φριζέ',
//     'Μπλάντ Χάουντ',
//     'Μπολονέζ',
//     'Μπόρντερ Κόλεϊ',
//     'Μπόρντερ Τεριέ',
//     'Μπορζόϊ',
//     'Μπόστον Τεριέ',
//     'Μπουβιέ της Φλάντρας',
//     'Μπόξερ',
//     'Μπράκο Ιταλιάνο',
//     'Μπριάρ',
//     'Μπρετόν',
//     'Μπουλ Τεριέ',
//     'Μπουλ Τεριέ (Μινιατούρα)',
//     'Μπουλ Ντογκ',
//     'Μπουλμαστίφ',
//     'Κερν Τεριέ',
//     'Σκύλος της Χαναάν',
//     'Καναδέζικο Έσκιμο',
//     'Κάβαλιερ Κινγκ Τσαρλς Σπάνιελ',
//     'Τσέσκυ Τερριέ',
//     'Τσιουάουα (Μακρύτριχο)',
//     'Τσιουάουα (Κοντότριχο)',
//     'Κινέζικο Κρέστιντ',
//     'Τσόου-Τσόου',
//     'Κόλεϊ',
//     'Κόλεϊ (Λείο)',
//     'Κοτόν ντε Τουλεάρ',
//     'Ντάτσχουντ (Μακρύτριχο)',
//     'Ντάτσχουντ (Μινιατούρα Μακρύτριχο)',
//     'Ντάτσχουντ (Μινιατούρα Απαλό τρίχωμα)',
//     'Ντάτσχουντ (Μινιατούρα Σκληρό τρίχωμα)',
//     'Ντάτσχουντ (Απαλό τρίχωμα)',
//     'Ντάτσχουντ (Σκληρό τρίχωμα)',
//     'Δαλματίας',
//     'Ντάντι Ντινμοντ Τεριέ',
//     'Ντίρχαουντ',
//     'Ντόμπερμαν',
//     'Ντογκ ντε Μπορντώ',
//     'Αγγλικό Σέττερ',
//     'Αγγλικό Τόι Τεριέ',
//     'Ορεινό Εστρέλλας',
//     'Φινλανδικό Λάπχουντ',
//     'Φινλανδικό Σπιτζ',
//     'Φοξ Τεριέ (Ίσιο τρίχωμα)',
//     'Φοξ Τεριέ (Σκληρό τρίχωμα)',
//     'Φοξχάουντ',
//     'Γαλλικό Μπουλ Ντογκ',
//     'Γερμανικό Πίνσερ',
//     'Γερμανικόσ Ποιμενικός',
//     'Γερμανικό Πόιντερ (Κοντότριχο)',
//     'Γερμανικό Σπιτζ (Klein)',
//     'Γερμανικό Σπιτζ (Mittel)',
//     'Γερμανικό Πόιντερ (Σκληρό τρίχωμα)',
//     'Σνάουτσερ Γίγας',
//     'Γκόρντον Σέττερ',
//     'Μεγάλος Μπλε της Γασκώνης',
//     'Γερμανικός Μολοσσός',
//     'Γκρεϊχάουντ',
//     'Γκριφόν Βρυξελλών',
//     'Χάμιλτον Στοβάρε',
//     'Μπισόν Χαβανέζ',
//     'Χόβαβαρτ',
//     'Ουγγρικό Καβάζ',
//     'Ουγγρικό Πούλι',
//     'Ουγγρικό Βίζλα',
//     'Ουγγρικό Βίζλα (Σκληρό τρίχωμα)',
//     'Κυνηγόσκυλο της Ίμπιζα',
//     'Ιρλανδικό Ερυθρόλευκο Σέττερ',
//     'Ιρλανδικό Σέττερ',
//     'Ιρλανδικό Τεριέ',
//     'Ιρλανδικό Λυκόσκυλο',
//     'Ιταλικό Γκρεϊχάουντ',
//     'Ιταλικό Σπινόνε',
//     'Ιαπωνέζικο Ακίτα',
//     'Ιαπωνέζικο Τσιν',
//     'Ιαπωνέζικο Σίμπα Ινού',
//     'Ιαπωνέζικο Σπιτζ',
//     'Κίσχοντ',
//     'Κέρι Μπλου Τεριέ',
//     'Κινγκ Τσαρλς Σπάνιελ',
//     'Κομοντόρ',
//     'Κοϊκερόντζ',
//     'Λέικλαντ Τεριέ',
//     'Λάνκασαιρ Χίλερ',
//     'Λεονμπέργκερ',
//     'Λάσα Άπσο',
//     'Λόουτσεν (Μικρό λιοντάρι)',
//     'Μαλτέζ',
//     'Μάντσεστερ Τεριέ',
//     'Ποιμενικός Μαρεμάνο',
//     'Μαστίφ',
//     'Πίνσερ μινιατούρα',
//     'Σνάουτσερ μινιατούρα',
//     'Μυνστερλάντερ',
//     'Μαστίφ της Νάπολης',
//     'Νιού Φάουντλαντ',
//     'Νόρφολκ Τεριέ',
//     'Νορβηγικό Μπουχούντ',
//     'Ελκχάουντ Νορβηγίας',
//     'Νόργουιτς Τεριέ',
//     'Παλαιός Αγγλικός Ποιμενικός (Μπομπτέιλ)',
//     'Οτερχάουντ',
//     'Παπιγιόν',
//     'Πάρσον Ράσελ Τεριέ',
//     'Κυνηγόσκυλο του Φαραώ',
//     'Πόιντερ',
//     'Πολωνικός Ποιμενικός Νιζινί',
//     'Πομεράνιαν',
//     'Πουντλ (Μινιατούρα)',
//     'Πουντλ (Κανονικό)',
//     'Πουντλ (Τόι)',
//     'Πορτογαλικό Σκυλί του Νερού',
//     'Παγκ',
//     'Πυρηναίος Ορεινός',
//     'Πυρηναίο Τσοπανόσκυλο (Μακρύτριχο)',
//     'Ριτρίβερ (Κόλπος Τσέζαπικ)',
//     'Ριτρίβερ (Σγουρό τρίχωμα)',
//     'Ριτρίβερ (Επίπεδο τρίχωμα)',
//     'Ριτρίβερ (Γκόλντεν)',
//     'Ριτρίβερ (Λαμπραντόρ)',
//     'Ριτρίβερ (Νέας Σκωτίας)',
//     'Ροδεσιανό Ρίτζμπακ',
//     'Ροτβάιλερ',
//     'Σαλούκι',
//     'Σαμογιέντ',
//     'Σκίπερκι',
//     'Σνάουτσερ (Κανονικό)',
//     'Σκοτσέζικο Τεριέ Terrier',
//     'Σίλιχαμ Τεριέ',
//     'Ιταλικός Ιχνηλάτης (Segugio)',
//     'Σαρ Πέι',
//     'Ποιμενικός Σέτλαντ',
//     'Σιχ Τσου',
//     'Σιβηρικό Χάσκυ',
//     'Σκάι Τεριέ',
//     'Σλούγκι',
//     'Λειότριχο Λευκόμαλο Τεριέ',
//     'Σπάνιελ (Αμερικάνικο Κόκερ)',
//     'Σπάνιελ (Κλάμπερ)',
//     'Σπάνιελ (Κόκερ)',
//     'Σπάνιελ (Αγγλικό Σπρίνγκερ)',
//     'Σπάνιελ (Φιλντ)',
//     'Σπάνιελ (Άιρις Γουότερ)',
//     'Σπάνιελ (Σάσεξ)',
//     'Σπάνιελ (Ουαλικό Σπρίνγκερ)',
//     'Ισπανικός Σκύλος του Νερού',
//     'Αγίου Βερνάρδου',
//     'Στάφορντσαϊρ Μπουλ Τεριέ',
//     'Σουηδικό Λάπχουντ',
//     'Σουηδικό Βάλχουντ',
//     'Θιβετιανό Μαστίφ',
//     'Θιβετιανό Σπάνιελ',
//     'Θιβετιανό Τεριέ',
//     'Βαϊμαράνερ',
//     'Ουαλικό Κόργκι (Κάρντιγκαν)',
//     'Ουαλικό Κόργκι (Πέμπροκ)',
//     'Ουαλικό Τεριέ',
//     'Γουέστ Χάιλαντ Άσπρο Τεριέ',
//     'Γουίπετ',
//     'Γιόρκσαϊρ Τεριέ',
//     'Μεξικάνικος Άτριχος',
//     'Τζακ Ράσελ Τεριέ',
//     'Ποιμενικός της Σπάρτης',
//     'Ελληνικός Ποιμενικός',
//     'Ελληνικός Ιχνηλάτης',
//     'Κρητικός Λαγωνικός/Ιχνηλάτης',
//     'Κοκόνι',
//     'Λευκό Ελληνικό Τσοπανόσκυλο',
//     'Μολοσσός της Ηπείρου',
//     'Αλωπεκίς',
//     'Πίτμπουλ'
// ];