// import React, {useContext} from 'react'
// import { Outlet } from 'react-router-dom'
// import styles from './index.module.css'
// // import { useLocation } from "react-router-dom";
// // import { AnimatePresence } from "framer-motion";

// import { AppearanceContext } from '../../context/AppearanceContext'
// import { AuthContext } from '../../context/AuthContext'

// export default function PublicRoutes() {

//   const { theme } = useContext(AppearanceContext);
//   const { auth } = useContext(AuthContext);
//   // const location = useLocation();
//   const [publicRoutes, setPublicRoutes] = React.useState(false)

//   React.useEffect(()=>{
//     if(auth){
//       setPublicRoutes(false)
//     }else{
//       setPublicRoutes(true)
//     }
//   },[auth])
//   return (
//     publicRoutes ? (
//       <div className={theme === 'light' ? styles.MainContainerLight : styles.MainContainerDark}>
//         <Outlet/>
//       </div>
//     ):null
//   )
// }









import React, {useLayoutEffect} from 'react'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { AppearanceContext } from "../../context/AppearanceContext";
import styles from './index.module.css'

export default function PublicRoutes() {
  const { user } = useAuth();
  const location = useLocation();
  const {theme} = React.useContext(AppearanceContext)


  // SCROLL TO TOP ON EVERY URL CHANGE
  useLayoutEffect(() => {
    // document.documentElement.scrollTo(0, 10000);
    document.getElementById("MainAppIntro").scrollIntoView();
  }, [location.pathname]);
  // SCROLL TO TOP ON EVERY URL CHANGE


  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={theme === 'light' ? styles.MainContainerLight : styles.MainContainerDark}>
        <Outlet/>
    </div>
  );
};
