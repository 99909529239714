import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-date-picker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import { RiCalendar2Line } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import styles from './index.module.css'

export default function DatePick({
    label, 
    value, 
    name, 
    formik, 
    theme, 
    errors, 
    touched, 
    minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 25)), 
    maxDate = null}) {

    const {t} = useTranslation();

    const FormatDate = (date) => {
        return {
            year: date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate(),
        }
    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }


    const [val, onChange] = useState(isValidDate(value) ? FormatDate(value) : '');

    const { setFieldValue } = formik;


    useEffect(() => {
        const date = new Date(new Date().setFullYear(val.year, val.month-1, val.day));
        setFieldValue(name, date);
    }, [val, setFieldValue, name])




    const Loc = {
        // months list by order
        months: [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
        ],
    
        // week days by order
        weekDays: [
        {
            name: t('sunday'), // used for accessibility 
            short: t('sunday_short'), // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: t('monday'),
            short: t('monday_short'),
        },
        {
            name: t('tuesday'),
            short: t('tuesday_short'),
        },
        {
            name: t('wednesday'),
            short: t('wednesday_short'),
        },
        {
            name: t('thursday'),
            short: t('thursday_short'),
        },
        {
            name: t('friday'),
            short: t('friday_short'),
        },
        {
            name: t('saturday'),
            short: t('saturday_short'),
            isWeekend: true,
        },
        ],
    
        // just play around with this number between 0 and 6
        weekStartingIndex: 0,
    
        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject) {
        return gregorainTodayObject;
        },
    
        // return a native JavaScript date here
        toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
        },
    
        // return a number for date's month length
        getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
        },
    
        // return a transformed digit to your locale
        transformDigit(digit) {
        return digit;
        },
    
        // texts in the date picker
        nextMonth: t('nextMonth'),
        previousMonth: t('previousMonth'),
        openMonthSelector: t('openMonthSelector'),
        openYearSelector: t('openYearSelector'),
        closeMonthSelector: t('closeMonthSelector'),
        closeYearSelector: t('closeYearSelector'),
        defaultPlaceholder: t('select'),
    
        // for input range value
        from: t('from'),
        to: t('to'),
    
    
        // used for input value when multi dates are selected
        digitSeparator: ',',
    
        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,
    
        // is your language rtl or ltr?
        isRtl: false,
    }





    const renderCustomInput = ({ ref }) => (
        <input
          readOnly
          ref={ref} // necessary
          placeholder={t('chooseDate')}
          value={val ? `${val.day}/${val.month}/${val.year}` : ''}
          className={ theme === 'light' ? styles.InputLight : styles.InputDark}
        />
      )

    return (
        <>
            <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>

            <div className={
                (errors && touched) ? (
                    theme === 'light' ? (
                        `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                    ):(
                        `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                    )     
                ): (
                    theme === 'light' ? (
                        styles.InputOuterLight
                    ):(
                        styles.InputOuterDark
                    )
                )}>
                <DatePicker
                    calendarPopperPosition='bottom'
                    name={name}
                    // locale={i18n.language ? CheckLanguage(i18n.language) : 'en'}
                    locale={Loc}
                    value={val} 
                    onChange={onChange} 
                    renderInput={renderCustomInput} // render a custom input
                    shouldHighlightWeekends
                    calendarClassName={ theme === 'light' ? styles.CalendarLight : styles.CalendarDark}
                    maximumDate={maxDate ? FormatDate(maxDate) : null}
                    minimumDate={minDate ? FormatDate(minDate) : null}
                />
                <div className={styles.CalendarIconContainer}><RiCalendar2Line className={theme === 'light' ? styles.CalendarIconLight : styles.CalendarIconDark} /></div>
            </div>
            {(errors && touched) &&
                <div style={{ marginTop:4}}>
                <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
                </div>
            }
        </>
    )
}
