import React, {useState, useEffect, useRef, useContext} from 'react'
import { Outlet, Link, Navigate, useLocation } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { RiSettings2Line, RiLifebuoyFill, RiLogoutBoxLine, RiMore2Fill } from "react-icons/ri";
import { AppearanceContext } from '../../../context/AppearanceContext';
import logoLight from '../../../assets/loofy_logo_full_Light.png'
import logoDark from '../../../assets/loofy_logo_full_Dark.png'
import verified_3d_image from '../../../assets/AddMobilePhone/verified_3d.png'
import mobile_verification_failed_image from '../../../assets/AddMobilePhone/mobile_verification_failed.png'
import mobile_verification_succeed_image from '../../../assets/AddMobilePhone/mobile_verification_succeed.png'

import { useAuth } from '../../../hooks/useAuth';

import SmallLink from '../../../components/Links/SmallLink';



import TIME from '../../../constants/TIME';
const MoveValue = 10

export default function MobilePhoneReminder() {
    
    const { user, logout } = useAuth();
    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();
    const location = useLocation();
    
    const [userDdOpened, setUserDdOpened] = useState(false)

    
    const refDd = useRef();
    useOnClickOutside(refDd, () => setUserDdOpened(false));


    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }



      const [heroImg, setHeroImg] = useState(verified_3d_image);


      useEffect(()=>{
        if(location.pathname === '/mobile-phone' || location.pathname === '/enter-otp'){
            setHeroImg(verified_3d_image)
        }else if(location.pathname === '/mobile-phone/verification-failed'){
            setHeroImg(mobile_verification_failed_image)
        }else if(location.pathname === '/mobile-phone/successful-verification'){
            setHeroImg(mobile_verification_succeed_image)
        }
      },[location.pathname])




      

    if (user.m) {
        return <Navigate to="/getstarted" />;
    }

    return (
        <div className={styles.MainContainer}>
            <motion.div
                key={heroImg}
                style={{position:'absolute',top:100, right:10}}
                initial={{ opacity: 0, translateY: MoveValue }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: MoveValue }}
                transition={{ duration: 0.25, delay: 7*TIME }}
            >
                <img src={heroImg} alt='Verification hero' />
            </motion.div>
            <div className={styles.Inner}>
                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25 }}
                >
                    <Link to='/'><img src={theme === 'light' ? logoLight : logoDark} className={styles.Logo} alt='Loofy Logo' /></Link>
                </motion.div>

                <Outlet />

                <motion.div
                    className={theme === 'light' ? styles.FooterInfoLight : styles.FooterInfoDark}
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 8*TIME }}
                >
                    <div className={theme === 'light' ? styles.FooterInfoLinksLight : styles.FooterInfoLinksDark}>© 2023 loofy · <Link to='/termsofuse' target='_blank'>{t('termsofuse')}</Link> · <Link to='/privacypolicy' target='_blank'>{t('privacypolicy')}</Link></div>
                </motion.div>
            </div>
            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: TIME }}
            >
                <div ref={refDd} className={styles.LoggedInUser} onClick={()=>setUserDdOpened(!userDdOpened)}>
                    {/* <img src={user.img} className={styles.LoggedInUserImg} alt='User avatar' /> */}
                    <div className={ theme === 'light' ? styles.CircleMenuLight : styles.CircleMenuDark}>
                        <RiMore2Fill className={ theme === 'light' ? styles.CircleMenuSvgLight : styles.CircleMenuSvgDark} size={24} />
                    </div>
                    <div className={ theme === 'light' ? (
                            userDdOpened ? styles.LoggedInUserDropdownOpenedLight : styles.LoggedInUserDropdownLight
                        ) : (
                            userDdOpened ? styles.LoggedInUserDropdownOpenedDark : styles.LoggedInUserDropdownDark
                        )
                        }>
                            <SmallLink destination='/mobile-phone/settings' theme={theme} text={t('settings')} leftIcon={<RiSettings2Line />} />
                            <SmallLink destination='/mobile-phone/helpcenter' theme={theme} text={t('helpcenter')} leftIcon={<RiLifebuoyFill />} />
                            <SmallLink onClick={()=>logout()} theme={theme} text={t('logout')} leftIcon={<RiLogoutBoxLine />} />
                    </div>
                </div>
            </motion.div>
           
        </div>
    )
}
