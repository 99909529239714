const Allergies = [
    {
        id:1,
        title:"Αλλεργία σε ψύλλους",
        en:"Allergy to fleas"
    }, 
    {
        id:2,
        title:"Αλλεργία σε γύρη",
        en:"Pollen allergy"
    },
    {
        id:3,
        title:"Αλλεργία σε μύκητες",
        en:"Allergy to fungi"
    },
    {
        id:4,
        title:"Αλλεργία σε τρίχες ζώων",
        en:"Allergy to animal hair"
    },
    {
        id:5,
        title:"Αλλεργία στη σκόνη του σπιτιού",
        en:"Allergy to house dust"
    }, {
        id:6,
        title:"Τροφική αλλεργία",
        en:"Food allergy"
    }, {
        id:7,
        title:"Αλλεργία σε φυτά",
        en:"Allergy to plants"
    }, {
        id:8,
        title:"Αλλεργία σε φαρμακευτικές ουσίες",
        en:"Allergy to medicinal substances"
    }
];

export default Allergies