import React from 'react'
import styles from './index.module.css'
import { AppearanceContext } from '../../context/AppearanceContext'
import logo from '../../assets/loofy_logo.png'
import { motion } from "framer-motion"

export default function LoaderScreen({fixed = false}) {

    const { theme } = React.useContext(AppearanceContext);

    return (
        fixed ? (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.125 }}
                className={theme === 'light' ? styles.FullScreenLoaderContainerLightFixed : styles.FullScreenLoaderContainerDarkFixed}
            >   <img src={logo} className={styles.LoaderLogo} alt='loader logo'/>
            </motion.div>
            // <motion.div
            //     initial={{ opacity: 0 }}
            //     animate={{ opacity: 1 }}
            //     exit={{ opacity: 0 }}
            //     transition={{ duration: 0.125 }}
            //     className={styles.LinearLoaderContainer}
            // >
            //     <div className={styles.LinearLoader}>
            //         <div className={styles.LinearLine}></div>
            //         <div className={styles.LinearSublineInc}></div>
            //         <div className={styles.LinearSublineDec}></div>
            //     </div>
            // </motion.div>
        ):(
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.125 }}
                className={theme === 'light' ? styles.FullScreenLoaderContainerLight : styles.FullScreenLoaderContainerDark}
            >   <img src={logo} className={styles.LoaderLogo} alt='loader logo'/>
            </motion.div>
        )
        
    )
}
