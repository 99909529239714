import React, {useEffect, useState} from 'react'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '220px',
  minHeight:220,
  borderRadius: 8
};


function GoogleMapComponent({setUserLoc, coords, storedLat = null, storedLng = null}) {
    const [center, setCenter] = useState(null)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAk0UBca0lwoeowNItVUdBkISaFLAtfw6E"
    })

  const [map, setMap] = React.useState(null)

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);
//     setMap(map)
//   }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    if(storedLat){
      setUserLoc({lat:storedLat, lng:storedLng})
      setCenter({lat:storedLat, lng:storedLng})
    }else{
      setUserLoc({lat:coords.latitude, lng:coords.longitude})
      setCenter({lat:coords.latitude, lng:coords.longitude})
    }
  }, [coords, storedLat, storedLng, setUserLoc]);


  return isLoaded && center ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onUnmount={onUnmount}
        map={map}
      >
        {center ? (
            <MarkerF 
              position={center}
              draggable={true}
              onDragEnd={(e)=>{setUserLoc({lat:e.latLng.lat(),lng:e.latLng.lng()})}}
            />
          ):null
        }
        {/* <></> */}
      </GoogleMap>
  ) : <></>
}

export default React.memo(GoogleMapComponent)