import React, { useState, useContext } from 'react'
import styles from './index.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../../context/AppearanceContext';

import LTitle from '../../../components/Titles/LTitle'
import SubTitle from '../../../components/Titles/SubTitle';
import ProfileImagePicker from '../../../components/Pickers/ImagePickers/ProfileImagePicker'
import PrimaryBtnLarge from '../../../components/Buttons/PrimaryBtnLarge';


import TIME from '../../../constants/TIME';

const MoveValue = 10



export default function RegisterStep2() {
    const location = useLocation();
    const navigate = useNavigate()

    const { theme } = useContext(AppearanceContext);
    const [t] = useTranslation();

    const [profileImg, setProfileImg] = useState(location.state ? location.state[3] ? location.state[3] : null : null);


    return (
        <>
        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: TIME }}
        >
            <LTitle title={t('profilephoto')} theme={theme} hasBackButton backButtonPath='/register' backButtonParams={location.state} />
        </motion.div>
        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <SubTitle text={t('uploadanimageasyourprofilephoto')} theme={theme} />
        </motion.div>
        <div className={styles.SeperatorS}></div>
        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 3*TIME }}
        >
            <ProfileImagePicker
                theme={theme}
                confirmedShownImage={
                    profileImg ? profileImg : location.state ? location.state[3] ? location.state[3] : null : null
                }
                setConfirmedShownImage={setProfileImg}
                width={160}
                height={160}
                borderRadius={160}
            />
        </motion.div>
        <div className={styles.SeperatorXXL}></div>
        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 4*TIME }}
            style={{flex:5, display:'flex', justifyContent:'flex-end', flexDirection:'column'}}
        >
            <PrimaryBtnLarge 
                title={t('next')}
                type='submit' 
                fullWidth
                onClick={()=>{
                    if(location.state.length > 3){
                        location.state.pop();
                    }
                    navigate('/register/location', { state: [...location.state, ...[profileImg]] })}
                }
            />
        </motion.div>
        </>
    )
}
