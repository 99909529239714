import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  defer
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import languages from './appearance/languages';

import UserLoginAndRegister from './routes/PublicRoutes/UserLoginAndRegister';
import Login from './routes/Login';
import Register from './routes/Register'
import RegisterStep2 from './routes/Register/Step2'
import RegisterStep3 from './routes/Register/Step3'
import Terms from './routes/PublicRoutes/Terms';


import PublicRoutes from './routes/PublicRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import MainRoutes from './routes/ProtectedRoutes/MainRoutes';
import OnBoardingRoutes from './routes/ProtectedRoutes/OnBoardingRoutes';

import { AuthLayout } from './layout/AuthLayout';
import AddPetLayout from "./routes/StartingPoint/GetStarted/AddPetLayout";


import Home from './routes/Home';
import GetStarted from "./routes/StartingPoint/GetStarted";
import Choices from "./routes/StartingPoint/GetStarted/Choices";
import SelectSpecies from "./routes/StartingPoint/GetStarted/AddYourPet/SelectSpecies";
import AddPetBasics from "./routes/StartingPoint/GetStarted/AddYourPet/AddPetBasics";
import AddPetHealth from "./routes/StartingPoint/GetStarted/AddYourPet/AddPetHealth";
import AddPetPersonality from "./routes/StartingPoint/GetStarted/AddYourPet/AddPetPersonality";
import MobilePhoneReminder from "./routes/StartingPoint/MobilePhoneReminder";
import AddMobilePhone from "./routes/StartingPoint/MobilePhoneReminder/AddMobilePhone";
import AddOTPForMobilePhone from "./routes/StartingPoint/MobilePhoneReminder/AddOTPForMobilePhone";
import AddMobilePhoneFailed from "./routes/StartingPoint/MobilePhoneReminder/AddMobilePhoneFailed";
import SuccessfullVerification from "./routes/StartingPoint/MobilePhoneReminder/SuccessfullVerification";
import Feed from './routes/Home/Feed';

// ideally this would be an API call to server to get logged in user data


const queryClient = new QueryClient()


function App() {



  i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: languages,
    lng: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : 'el',
    fallbackLng: ["en","el"],
    interpolation: {
      escapeValue: false,
    },
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    
  });



const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 0)
  );





  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        id="BaseRoute"
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >

        {/* if not user (from Auth Context) */}
        <Route element={<PublicRoutes />} errorElement={<Navigate to='/login'/>}>
          <Route element={<UserLoginAndRegister />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/profile-image" element={<RegisterStep2 />} />
            <Route path="/register/location" element={<RegisterStep3 />} />
            <Route path="/termsofuse" element={<Terms />} />
            <Route path="/*" element={<Navigate to='/login'/>} />
          </Route>
        </Route>

        {/* if user (from Auth Context) */}
        <Route element={<ProtectedRoutes />}>
          
            {/* if user has roles --- user.roles */}
            <Route element={<MainRoutes />} errorElement={<Navigate to='/'/>}>
              <Route path="/" element={<Home />}>
                <Route path="feed" element={<Feed />} />
              </Route>
            </Route>

            {/* if user hasn't roles --- !user.roles */}
            <Route element={<OnBoardingRoutes />} errorElement={<Navigate to='/getstarted'/>}>

              {/* if user mobile phone exists or it is escaped --- user.m */}
              <Route element={<GetStarted />}>
                <Route path="/getstarted" element={<Choices />} />
                <Route element={<AddPetLayout />}>
                  <Route path="/getstarted/add-your-pet" element={<SelectSpecies />} />
                  <Route path="/getstarted/pet-basics" element={<AddPetBasics />} />
                  <Route path="/getstarted/pet-health" element={<AddPetHealth />} />
                  <Route path="/getstarted/pet-personality" element={<AddPetPersonality />} />
                </Route>
              </Route>

              {/* if user mobile phone doesn't exist (after register) --- user.m === null */}
              <Route element={<MobilePhoneReminder />}>
                <Route path="/mobile-phone" element={<AddMobilePhone />} />
                <Route path="/enter-otp" element={<AddOTPForMobilePhone />} />
                <Route path="/mobile-phone/verification-failed" element={<AddMobilePhoneFailed />} />
                <Route path="/mobile-phone/successful-verification" element={<SuccessfullVerification />} />
              </Route>

            </Route>
            
        </Route>

      </Route>
    )
  );

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
