import React, {useState, useCallback, useEffect} from 'react'
import styles from './index.module.css'

import Chip from './Chip'

export default function RadioChipMd({label, value, data, formik, name, errors, touched, theme}) {

    const [selected, setSelected] = useState(value ? value : '');

    const { setFieldValue } = formik;


    const renderItem = useCallback(
        (item) => (
            selected === item.id ? (
                <Chip key={item.id} item={item} onClick={()=>setSelected(item.id)} theme={theme} errors={errors} touched={touched} selected={true} />
            ):(
                <Chip key={item.id} item={item} onClick={()=>setSelected(item.id)} theme={theme} errors={errors} touched={touched} />
            )
            
        
    ),
    [selected, theme, errors, touched, setSelected]);
    

    useEffect(() => {
        setFieldValue(name, selected);
    }, [selected, setFieldValue, name])

    // onChange={formik.handleChange}

    return (
        <>
            <label className={theme === 'light' ? styles.ChipLabelLight : styles.ChipLabelDark}>{label}</label>
            <div className={styles.RadioChipsContainer}>
                {data.map(renderItem)}
            </div>
            {(errors && touched) &&
                <div style={{ marginTop:4}}>
                <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
            </div>
        }
        </>
    )
}
