import React from "react";
import { useGeolocated } from "react-geolocated";
import GoogleMapComponent from "../GoogleMapComponent";
import Loader from '../Loaders/LoaderCircle'

const GetGeolocation = ({setUserLocation, storedLat = null, storedLng = null}) => {

    
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
            watchPosition: false,
            geolocationProvider: navigator.geolocation,
        });




    return !isGeolocationAvailable ? (
        <div>Your browser does not support Geolocation</div>
    ) : !isGeolocationEnabled ? (
        // <div>Geolocation is not enabled</div>
        <div>Οι υπηρεσίες τοποθεσίας δεν είναι ενεργοποιημένες</div>
    ) : storedLat && storedLng ? (
        <GoogleMapComponent setUserLoc={setUserLocation} storedLat={storedLat} storedLng={storedLng} />
    ) : coords ? (
        <GoogleMapComponent setUserLoc={setUserLocation} coords={coords} />
    ) : (
        // <div>Getting the location data&hellip; </div>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Loader />
        </div>
    );
};

export default GetGeolocation;