import React, {useState, useContext} from 'react'
import styles from './index.module.css'
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next";
import { AppearanceContext } from '../../../../context/AppearanceContext';

import el_flag from '../../../../assets/Flags/GreekFlag.svg'
import en_flag from '../../../../assets/Flags/EnglishFlag.svg'



import TIME from '../../../../constants/TIME';


const MoveValue = -10




export default function FloatingLanguagePicker({languages, activeLanguage, theme}) {

    
    const { setLanguageFunc } = useContext(AppearanceContext);
    const { t, i18n } = useTranslation()

    const [hovered, setHovered] = useState(false)



    const FlagAssigner = (item) => {
        if(item === 'el'){
            return el_flag
        }
        if(item === 'en'){
            return en_flag
        }
    }




    return (
        <div 
            className={theme === 'light' ? styles.FloatingLanguagePickerContainerLight : styles.FloatingLanguagePickerContainerDark}
            onMouseEnter={() => {
                setHovered(true);
            }}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            <div className={theme === 'light' ? styles.LanguageLight : styles.LanguageDark}>
                <img src={FlagAssigner(activeLanguage)} alt='country flag' />
                {t(activeLanguage)}
            </div>
            {
                hovered ? (
                    <div className={styles.LanguagesContainer}>
                        {
                            languages.map((item, index) => (
                                item !== activeLanguage ? (

                                    <motion.div
                                        key={item}
                                        initial={{ opacity: 0, translateY: MoveValue }}
                                        animate={{ opacity: 1, translateY: 0 }}
                                        exit={{ opacity: 0, translateY: MoveValue }}
                                        transition={{ duration: 0.25, delay: index*TIME }}
                                    >
                                        <div 
                                            className={theme === 'light' ? `${styles.LanguageLight} ${styles.LanguageMargin}` : `${styles.LanguageDark} ${styles.LanguageMargin}`}
                                            onClick={()=>{setLanguageFunc(item);i18n.changeLanguage(item);setHovered(false)}}
                                        >
                                        <img src={FlagAssigner(item)} alt='country flag' />
                                            {t(item)}
                                        </div>
                                    </motion.div>

                                ):null
                                
                            ))
                        }
                    </div>
                ):(
                    null
                )
            }
            
        </div>
    )
}
