import React, { useContext } from 'react'
import styles from './index.module.css'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { motion } from "framer-motion"
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../context/AppearanceContext';

import LTitle from '../../components/Titles/LTitle'
import SubTitle from '../../components/Titles/SubTitle';
import PrimaryBtnLarge from '../../components/Buttons/PrimaryBtnLarge';
import Input from '../../components/Inputs/Input';
import Password from '../../components/Inputs/Password';




import TIME from '../../constants/TIME';

const MoveValue = 10

export default function Register() {

  const { theme } = useContext(AppearanceContext);
  const [t] = useTranslation();

  const navigate = useNavigate()
  const location = useLocation()


  const registerValidationSchema_1 = yup.object().shape({
    name: yup
      .string()
      .required(t('nameRequiredValidation')),
    email: yup
      .string()
      .email(t('emailWrongValidation'))
      .required(t('emailRequiredValidation')),
    pass: yup
      .string()
      .required(t('passwordRequiredValidation'))
      .matches(/\w*[a-z]\w*/,  t('passwordLowercaseValidation'))
      .matches(/\w*[A-Z]\w*/,   t('passwordUppercaseValidation'))
      .matches(/\d/, t('passwordNumberValidation'))
      .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('passwordSpecialCharacterValidation'))
      .min(8, ({ min }) => t('password8limitValidation')),
    repass: yup
      .string()
      .oneOf([yup.ref('pass')], 'Οι κωδικοί δεν ταιριάζουν')
      .required('Η επαλύθευση κωδικού είναι υποχρεωτική'),
})


const formik = useFormik({
    validationSchema: registerValidationSchema_1,
    initialValues: {
      // name: '',
      // email: '',
      // pass: '',
      // repass: ''
      name: location.state ? location.state[0] : '',
      email: location.state ? location.state[1] : '',
      pass: location.state ? location.state[2] : '',
      repass: location.state ? location.state[2] : '',
    },
    onSubmit: values => {
      navigate('/register/profile-image', { state: [values.name, values.email, values.pass] })
    },
  });










  return (
    <>

      <motion.div
        initial={{ opacity: 0, translateX: MoveValue }}
        animate={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: MoveValue }}
        transition={{ duration: 0.25, delay: TIME }}
      >
        <LTitle title={t('register')} theme={theme} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, translateX: MoveValue }}
        animate={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: MoveValue }}
        transition={{ duration: 0.25, delay: 2*TIME }}
      >
        <SubTitle text={t('createyouraccount')} theme={theme} />
      </motion.div>

      <form onSubmit={formik.handleSubmit}>
          <div className={styles.SeperatorS}></div>

          <motion.div
              initial={{ opacity: 0, translateX: MoveValue }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: MoveValue }}
              transition={{ duration: 0.25, delay: 4*TIME }}
          >
              <Input
                  formik={formik} 
                  label={t("fullname")}
                  type='text'
                  placeholder={t('examplename')}
                  name="name" 
                  value={formik.values.name} 
                  errors={formik.errors.name}
                  touched={formik.touched.name}
                  theme={theme}
                  autoComplete={false}
              />
          </motion.div>
          <div className={styles.SeperatorM}></div>
          <motion.div
              initial={{ opacity: 0, translateX: MoveValue }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: MoveValue }}
              transition={{ duration: 0.25, delay: 4*TIME }}
          >
              <Input
                  formik={formik} 
                  label='Email'
                  type='email'
                  placeholder='someone@something.com'
                  name="email" 
                  value={formik.values.email} 
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                  theme={theme}
                  autoComplete={false}
              />
          </motion.div>
          <div className={styles.SeperatorM}></div>
          <motion.div
              initial={{ opacity: 0, translateX: MoveValue }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: MoveValue }}
              transition={{ duration: 0.25, delay: 5*TIME }}
          >
              <Password
                  formik={formik} 
                  label={t('password')}
                  placeholder='********'
                  name="pass" 
                  value={formik.values.pass} 
                  errors={formik.errors.pass}
                  touched={formik.touched.pass}
                  theme={theme}
              />
          </motion.div>
          <div className={styles.SeperatorM}></div>
          <motion.div
              initial={{ opacity: 0, translateX: MoveValue }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: MoveValue }}
              transition={{ duration: 0.25, delay: 6*TIME }}
          >
              <Password
                  formik={formik} 
                  label={t('confirmpassword')}
                  placeholder='********'
                  name="repass" 
                  value={formik.values.repass} 
                  errors={formik.errors.repass}
                  touched={formik.touched.repass}
                  theme={theme}
              />
          </motion.div>
          <div className={styles.SeperatorM}></div>
          <motion.div
              initial={{ opacity: 0, translateX: MoveValue }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: MoveValue }}
              transition={{ duration: 0.25, delay: 7*TIME }}
          >
              <PrimaryBtnLarge 
                  title={t('next')}
                  type='submit' 
                  fullWidth
                  // onClick={()=>formik.submitForm()}
              />
          </motion.div>
          <div className={styles.SeperatorM}></div>
          <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 8*TIME }}
          >
            <Link className={theme === 'light' ? styles.LoginPromptLight : styles.LoginPromptDark} to='/login'>{t('haveanaccount')} <span>{t('logintoloofy')}</span></Link>
          </motion.div>
      </form>

    </>
  )
}
