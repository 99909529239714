import { Suspense, useContext } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { AuthProvider } from "../hooks/useAuth";
import { AppearanceContext } from "../context/AppearanceContext";
import styles from './AuthLayout.module.css'

import LoaderScreen from "./LoaderScreen";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { userPromise } = useLoaderData();
  const {theme} = useContext(AppearanceContext)

  return (
    <div className={theme === 'light' ? styles.AuthLayoutLight : styles.AuthLayoutDark}>
      <Suspense fallback={<LoaderScreen />}>
      {/* <Suspense> */}
        <Await
          resolve={userPromise}
          errorElement={<div>Something went wrong!</div>}
          children={(user) => (
            <AuthProvider userData={user}>{outlet}</AuthProvider>
          )}
        />
      </Suspense>
    </div>
  );
};
