import React, {useState, useRef} from 'react';
import styles from './index.module.css'
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';
import { FiCheck, FiX } from "react-icons/fi";
import { RiUserLine } from "react-icons/ri";
import { BsFillCameraFill} from "react-icons/bs";
import imageCompression from 'browser-image-compression'



import Loader from '../../../Loaders/LoaderCircle'



import TIME from '../../../../constants/TIME';
const MoveValue = -10

export default function ProfileImagePicker({
  label = null,
  theme,
  confirmedShownImage,
  setConfirmedShownImage,
  width,
  height,
  borderRadius
}) {
  
  const [t] = useTranslation();

  const [selectedImg, setSelectedImage] = useState();
  const [zoomSelectedImg, setZoomSelectedImg] = useState(1);
  // const [rotateSelectedImg, setRotateSelectedImg] = useState(0);
  const [loadingState, setLoadingState] = useState(false);

  const inputFileRef = useRef();
  const editorRef = useRef(null);


  // COMPRESS IMAGE
  async function ImageCompression(file) {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
      alwaysKeepResolution: true,
    }
    try {
        const compressedFile = await imageCompression(file, options);
        const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile)
        setSelectedImage(compressedBase64); 
        setLoadingState(false);
    } catch (error) {
        console.log(error);
    }
  }


  const onFileChangeCapture = (e) => {
    if(e.target.files[0]){
        setLoadingState(true);
        ImageCompression(e.target.files[0])
    }
  };


  const handleSlider = (e) => {
      setZoomSelectedImg(e.target.value)
  }
  // const handleRotateLeft = () => {
  //     setRotateSelectedImg(rotateSelectedImg - 45)
  // }
  // const handleRotateRight = () => {
  //     setRotateSelectedImg(rotateSelectedImg + 45)
  // }



  const CancelImage = () => {
    setSelectedImage(null)
    inputFileRef.current.value = "";
  }
  const ConfirmImage = () => {
    const dataUrl = editorRef.current.getImage().toDataURL();
    setSelectedImage(null)
    setConfirmedShownImage(dataUrl);
    inputFileRef.current.value = "";
  }



  return (
    <>
      <input 
        accept='image/png, image/jpeg'
        type='file' 
        name='selectedImg'
        style={{display:'none'}} 
        ref={inputFileRef}
        onChangeCapture={onFileChangeCapture}
      />
      {label ? (
          <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
        ):(
          null
        )
      }
      {selectedImg ? (

        <div className={styles.ImageAndControls}>
            <div className={styles.AvatarEditorContainer}>
              <AvatarEditor
                  ref={editorRef}
                  image={selectedImg}
                  width={width}
                  height={height}
                  border={0}
                  borderRadius={borderRadius}
                  backgroundColor='#ffffff'
                  scale={parseFloat(zoomSelectedImg)}
                  // rotate={rotateSelectedImg}
              />
            </div> 
             
            <motion.div
                initial={{ opacity: 0, translateY: MoveValue }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: MoveValue }}
                transition={{ duration: 0.25, delay: TIME }}
            >
              <div className={theme === 'light' ? styles.ImageControlsContainerLight : styles.ImageControlsContainerDark}>
                <div className={theme === 'light' ? styles.AdjustTitleLight : styles.AdjustTitleDark}><RiUserLine /> {t('adjustyourphoto')}</div>
                <div className={styles.ZoomSliderContainer}>
                    <span className={theme === 'light' ? styles.ZoomSliderLight : styles.ZoomSliderDark}>{t('zoom')}</span>
                    <input 
                        type="range" 
                        min="1" 
                        max="10" 
                        step="0.1"
                        onChange={handleSlider}
                        value={zoomSelectedImg}
                    />
                    {/* <RangeSlider
                      className={theme === 'light' ? `${styles.RangeSliderLight} single-thumb RangeSliderZoomImg` : `${styles.RangeSliderDark} single-thumb RangeSliderZoomImg`}
                      defaultValue={[0, 0]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={true}
                      min="1" 
                      max="10" 
                      step="0.1"
                      onInput={(val)=> setZoomSelectedImg(val[1])}
                    /> */}
                </div>
                <div className={styles.ImageControlsCancelDone}>
                    <div className={theme === 'light' ? styles.ImageControlsCancelLight : styles.ImageControlsCancelDark} onClick={CancelImage}><FiX /></div>
                    <div className={theme === 'light' ? styles.ImageControlsDoneLight : styles.ImageControlsDoneDark} onClick={ConfirmImage}><FiCheck style={{marginRight:5}}/> {t('done_exclamation')}</div>
                </div>
              </div>
            </motion.div>
            

        </div> 

      ):(


        <div onClick={()=>inputFileRef.current.click()} style={{maxWidth:160}}>
          <div className={theme === 'light' ? styles.ProfileImageContainerLight : styles.ProfileImageContainerDark}>
          
            {loadingState ? (
                <div style={{position:'absolute',width:'100%',height:'100%', top:0,left:0, display:'flex', justifyContent:'center', alignItems:'center'}}>
                {/* <Loader type='internal' /> */}
                <Loader />
                </div>
            ):(
              confirmedShownImage ? <img src={confirmedShownImage} alt='User avatar' /> : <BsFillCameraFill className={theme === 'light' ? styles.ProfileImageIconLight : styles.ProfileImageIconDark} />
            )}
          </div>
        </div>
      )
      }
    </>
  )
}
