const Genders = [
    {
        id:1,
        title:"Θηλυκό",
        en: "Female",
        // img:require('../assets/female-symbol.png')
    }, {
        id:2,
        title:"Αρσενικό",
        en: "Male",
        // img:require('../assets/male-symbol.png')
    }
];

export default Genders
