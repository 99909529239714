import React, {useContext} from 'react'
import styles from './index.module.css'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next';

import XXLTitle from '../../components/Titles/XXLTitle';
import SubTitle from '../../components/Titles/SubTitle';
import PrimaryBtnLarge from '../../components/Buttons/PrimaryBtnLarge';
import Input from '../../components/Inputs/Input';
import Password from '../../components/Inputs/Password';

// import { AuthContext } from '../../context/AuthContext';
import { AppearanceContext } from '../../context/AppearanceContext';
import { useAuth } from '../../hooks/useAuth'

import mobilePrompt from '../../assets/Login/handTouchingSmartphone.png';
import googlePlay from '../../assets/Login/GooglePlay.png';
import appStore from '../../assets/Login/AppStore.png'

import TIME from '../../constants/TIME';


const MoveValue = 10


export default function Login() {


    // const { setAuthFunc } = useContext(AuthContext);
    const { theme } = useContext(AppearanceContext);
    const { login } = useAuth();
    const [t] = useTranslation();


    const loginValidationSchema = yup.object().shape({
        email: yup
          .string()
          .email(t('emailWrongValidation'))
          .required(t('emailRequiredValidation')),
        pass: yup
          .string()
          .required(t('passwordRequiredValidation'))
          .matches(/\w*[a-z]\w*/,  t('passwordLowercaseValidation'))
          .matches(/\w*[A-Z]\w*/,   t('passwordUppercaseValidation'))
          .matches(/\d/, t('passwordNumberValidation'))
          .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('passwordSpecialCharacterValidation'))
          .min(8, ({ min }) => t('password8limitValidation')),
    })


    const formik = useFormik({
        validationSchema: loginValidationSchema,
        initialValues: {
          email: '',
          pass: ''
        },
        onSubmit: values => {
        //   console.log(values.email, values.pass)
        //   loginUser(values.email, values.password)
        //   setAuthFunc(true)
          login({
            token: 'gseg83qyf980qr91gf9876w4e5986tg9q74d6htfr184376tr983475t6yr98f3984',
            roles: null,
            m:true,
            n: 'Γιώργος Γεωργίου',
            s: 'giwrgosgeorgiou',
            img: 'https://randomuser.me/api/portraits/men/22.jpg'
          })
        },
      });






    return (
        <>
            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: TIME }}
            >
                <XXLTitle title1={t('welcome')} title2={t('toloofyApp')} theme={theme} />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 2*TIME }}
            >
                <SubTitle text={t('loginwithyouraccount')} theme={theme} />
            </motion.div>

            <form onSubmit={formik.handleSubmit}>
                <div className={styles.SeperatorS}></div>

                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 3*TIME }}
                >
                    <Input
                        formik={formik} 
                        label='Email'
                        type='email'
                        placeholder='someone@something.com'
                        name="email" 
                        value={formik.values.email} 
                        errors={formik.errors.email}
                        touched={formik.touched.email}
                        theme={theme}
                    />
                </motion.div>
                <div className={styles.SeperatorM}></div>
                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 4*TIME }}
                >
                    <Password
                        formik={formik} 
                        label={t('password')}
                        placeholder='********'
                        name="pass" 
                        value={formik.values.pass} 
                        errors={formik.errors.pass}
                        touched={formik.touched.pass}
                        forgotPass
                        theme={theme}
                    />
                </motion.div>
                <div className={styles.SeperatorM}></div>
                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 5*TIME }}
                >
                    <PrimaryBtnLarge 
                        title={t('login')}
                        type='submit' 
                        fullWidth
                        // onClick={()=>formik.submitForm()}
                    />
                </motion.div>
            </form>
            <div className={styles.SeperatorM}></div>
            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 6*TIME }}
            >
                <Link className={theme === 'light' ? styles.RegisterPromptLight : styles.RegisterPromptDark} to='/register'>{t('noaccount')} <span>{t('registerhere')}</span></Link>
            </motion.div>

            <div className={styles.SeperatorXXL}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 7*TIME }}
            >
                <div className={styles.MobilePrompt}>
                    <img src={mobilePrompt} className={styles.MobilePromptImg} alt='A hand tapping a smartphone' />
                    <div>
                        <div className={theme === 'light' ? styles.MobilePromptTextLight : styles.MobilePromptTextDark}>{t('downloadloofyprompt')}</div>
                        <div className={styles.StoresContainer}>
                            <Link to='/'><img src={googlePlay} alt='Google play' /></Link>
                            <Link to='/'><img src={appStore} alt='App store' /></Link>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    )
}
