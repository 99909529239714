import React,{ useState, useEffect } from 'react'
// import RangeSlider from 'react-range-slider-input';
// import "react-range-slider-input/dist/style.css";
import styles from './index.module.css'
import ReactSlider from 'react-slider'
import './index.css'

export default function RangeSlider({type, theme, formik, label, name, value, errors, touched}) {

    const [val, setVal] = useState(value);

    const { setFieldValue } = formik;

    useEffect(() => {
        setFieldValue(name, val ? val : 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [val, setFieldValue, name])

    return (
        <div style={{height:60}}>
            {!label ? (
                null
            ):(
                <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
            )}
            <ReactSlider
                thumbClassName={ theme === 'light' ? 'thumbLight' : `${type} thumbDark`}
                trackClassName={ theme === 'light' ? `trackLight ${type}` : `trackDark ${type}`}
                renderThumb={(props, state) => <div {...props}><div className='percentageNum'>{state.valueNow}</div></div>}
                thumbActiveClassName='dragging'
                onChange={(v)=>setVal(v)}
                value={val}
                name={name}
            />
            {(errors && touched) &&
                <div style={{ marginTop:4}}>
                <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
                </div>
            }
        </div>
    );
}