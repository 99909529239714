const el = {
    "ext": "ext.",
    "country": "Τη χώρα τηλεφώνου",
    "phone": "Tηλέφωνο",
    "AB": "Αμπχαζία",
    "AC": "Νησί της αναλήψεως",
    "AD": "Ανδόρα",
    "AE": "Ηνωμένα Αραβικά Εμιράτα",
    "AF": "Αφγανιστάν",
    "AG": "Αντίγκουα και Μπαρμπούντα",
    "AI": "Ανγκουίλα",
    "AL": "Αλβανία",
    "AM": "Αρμενία",
    "AO": "Αγκόλα",
    "AQ": "Ανταρκτική",
    "AR": "Αργεντινή",
    "AS": "Αμερικανική Σαμόα",
    "AT": "Αυστρία",
    "AU": "Αυστραλία",
    "AW": "Αρούμπα",
    "AX": "Νήσοι Åland",
    "AZ": "Αζερμπαϊτζάν",
    "BA": "Βοσνία και Ερζεγοβίνη",
    "BB": "Μπαρμπάντος",
    "BD": "Μπαγκλαντές",
    "BE": "Βέλγιο",
    "BF": "Μπουρκίνα Φάσο",
    "BG": "Βουλγαρία",
    "BH": "Μπαχρέιν",
    "BI": "Μπουρούντι",
    "BJ": "Μπενίν",
    "BL": "Άγιος Βαρθολομαίος",
    "BM": "Βερμούδες",
    "BN": "Μπρουνέι",
    "BO": "Βολιβία",
    "BQ": "Καραϊβική Κάτω Χώρες",
    "BR": "Βραζιλία",
    "BS": "Μπαχάμες",
    "BT": "Μπουτάν",
    "BV": "Νήσος Μπούβε",
    "BW": "Μποτσουάνα",
    "BY": "Λευκορωσία",
    "BZ": "Μπελίζε",
    "CA": "Καναδάς",
    "CC": "Νήσοι Κόκος",
    "CD": "Κονγκό (ΛΔΚ)",
    "CF": "Δημοκρατία Κεντρικής Αφρικής",
    "CG": "Κονγκό (Δημοκρατία)",
    "CH": "Ελβετία",
    "CI": "Ακτή του Ελεφαντοστού",
    "CK": "Νήσοι Κουκ",
    "CL": "Χιλή",
    "CM": "Καμερούν",
    "CN": "Κίνα",
    "CO": "Κολομβία",
    "CR": "Κόστα Ρίκα",
    "CU": "Κούβα",
    "CV": "Πράσινο Ακρωτήριο",
    "CW": "Κουρασάο",
    "CX": "Το νησί των Χριστουγέννων",
    "CY": "Κύπρος",
    "CZ": "Δημοκρατία της Τσεχίας",
    "DE": "Γερμανία",
    "DJ": "Τζιμπουτί",
    "DK": "Δανία",
    "DM": "Δομίνικα",
    "DO": "Δομινικανή Δημοκρατία",
    "DZ": "Αλγερία",
    "EC": "Ισημερινός",
    "EE": "Εσθονία",
    "EG": "Αίγυπτος",
    "EH": "Δυτική Σαχάρα",
    "ER": "Ερυθραία",
    "ES": "Ισπανία",
    "ET": "Αιθιοπία",
    "FI": "Φινλανδία",
    "FJ": "Φίτζι",
    "FK": "Νήσοι Φώκλαντ",
    "FM": "Μικρονησία",
    "FO": "Νήσοι Φερόε",
    "FR": "Γαλλία",
    "GA": "Γκαμπόν",
    "GB": "Ηνωμένο Βασίλειο",
    "GD": "Γρενάδα",
    "GE": "Γεωργία",
    "GF": "Γαλλική Γουιάνα",
    "GG": "Γκέρνζυ",
    "GH": "Γκάνα",
    "GI": "Γιβραλτάρ",
    "GL": "Γροιλανδία",
    "GM": "Γκάμπια",
    "GN": "Γουινέα",
    "GP": "Γουαδελούπη",
    "GQ": "Ισημερινή Γουινέα",
    "GR": "Ελλάδα",
    "GS": "Νότια Γεωργία και Νότιες Νήσοι Σάντουιτς",
    "GT": "Γουατεμάλα",
    "GU": "Γκουάμ",
    "GW": "Γουινέα-Μπισάου",
    "GY": "Γουιάνα",
    "HK": "Χονγκ Κονγκ",
    "HM": "Το νησί Heard και τα νησιά McDonald",
    "HN": "Ονδούρα",
    "HR": "Κροατία",
    "HT": "Αϊτή",
    "HU": "Ουγγαρία",
    "ID": "Ινδονησία",
    "IE": "Ιρλανδία",
    "IL": "Ισραήλ",
    "IM": "Νήσος του Μαν",
    "IN": "Ινδία",
    "IO": "Βρετανικό έδαφος του Ινδικού Ωκεανού",
    "IQ": "Ιράκ",
    "IR": "Ιράν",
    "IS": "Ισλανδία",
    "IT": "Ιταλία",
    "JE": "Τζέρσεϋ",
    "JM": "Τζαμάικα",
    "JO": "Ιορδανία",
    "JP": "Ιαπωνία",
    "KE": "Κένυα",
    "KG": "Κιργιζιστάν",
    "KH": "Καμπότζη",
    "KI": "Κιριμπάτι",
    "KM": "Κομόρες",
    "KN": "Άγιος Χριστόφορος και Νέβις",
    "KP": "Βόρεια Κορέα",
    "KR": "Νότια Κορέα",
    "KW": "Κουβέιτ",
    "KY": "Νήσοι Κέιμαν",
    "KZ": "Καζακστάν",
    "LA": "Λάος",
    "LB": "Λίβανος",
    "LC": "Αγία Λουκία",
    "LI": "Λιχτενστάιν",
    "LK": "Σρι Λάνκα",
    "LR": "Λιβερία",
    "LS": "Λεσόθο",
    "LT": "Λιθουανία",
    "LU": "Λουξεμβούργο",
    "LV": "Λετονία",
    "LY": "Λιβύη",
    "MA": "Μαρόκο",
    "MC": "Μονακό",
    "MD": "Μολδαβία",
    "ME": "Μαυροβούνιο",
    "MF": "Άγιος Μαρτίνος",
    "MG": "Μαδαγασκάρη (Μαδαγασκάρα)",
    "MH": "Νήσοι Μάρσαλ",
    "MK": "Μακεδονία (ΠΓΔΜ)",
    "ML": "Μάλι",
    "MM": "Μυανμάρ",
    "MN": "Μογγολία",
    "MO": "Μακάο",
    "MP": "Νήσοι Β. Μαριάνων",
    "MQ": "Μαρτινίκα",
    "MR": "Μαυριτανία",
    "MS": "Μοντσερράτ",
    "MT": "Μάλτα",
    "MU": "Μαυρίκιος",
    "MV": "Μαλδίβες",
    "MW": "Μαλάουι",
    "MX": "Μεξικό",
    "MY": "Μαλαισία",
    "MZ": "Μοζαμβίκη",
    "NA": "Ναμίμπια",
    "NC": "Νέα Καληδονία",
    "NE": "Νίγηρας",
    "NF": "Νήσος Νόρφολκ",
    "NG": "Νιγηρία",
    "NI": "Νικαράγουα",
    "NL": "Κάτω Χώρες",
    "NO": "Νορβηγία",
    "NP": "Νεπάλ",
    "NR": "Ναούρου",
    "NU": "Νιούε",
    "NZ": "Νέα Ζηλανδία",
    "OM": "Ομάν",
    "OS": "Νότια Οσετία",
    "PA": "Παναμάς",
    "PE": "Περού",
    "PF": "Γαλλική Πολυνησία",
    "PG": "Παπούα Νέα Γουινέα",
    "PH": "Φιλιππίνες",
    "PK": "Πακιστάν",
    "PL": "Πολωνία",
    "PM": "Άγιος Πέτρος και Μικελόν",
    "PN": "Νήσος Πίτκαιρν",
    "PR": "Πουέρτο Ρίκο",
    "PS": "Παλαιστίνη",
    "PT": "Πορτογαλία",
    "PW": "Παλάου",
    "PY": "Παραγουάη",
    "QA": "Κατάρ",
    "RE": "Ρεϋνιόν",
    "RO": "Ρουμανία",
    "RS": "Σερβία",
    "RU": "Ρωσία",
    "RW": "Ρουάντα",
    "SA": "Σαουδική Αραβία",
    "SB": "Νήσοι Σολομώντος",
    "SC": "Σεϋχέλλες",
    "SD": "Σουδάν",
    "SE": "Σουηδία",
    "SG": "Σιγκαπούρη",
    "SH": "Αγία Ελένη",
    "SI": "Σλοβενία ​​(Slovenija)",
    "SJ": "Σβάλμπαρντ και Γιαν Μάγιεν",
    "SK": "Σλοβακία",
    "SL": "Σιέρα Λεόνε",
    "SM": "Άγιος Μαρίνος",
    "SN": "Σενεγάλη",
    "SO": "Σομαλία",
    "SR": "Σουρινάμ",
    "SS": "Νότιο Σουδάν",
    "ST": "Σάο Τομέ και Πρίνσιπε",
    "SV": "Ελ Σαλβαδόρ",
    "SX": "Άγιος Μάρτιν",
    "SY": "Συρία",
    "SZ": "Σουαζιλάνδη",
    "TA": "Τριστάν ντα Κούνια",
    "TC": "Νήσοι Τερκς και Κάικος",
    "TD": "Τσαντ",
    "TF": "Γαλλικά νότια εδάφη",
    "TG": "Τόγκο",
    "TH": "Ταϊλάνδη",
    "TJ": "Τατζικιστάν",
    "TK": "Τοκελάου",
    "TL": "Τιμόρ-Λέστε",
    "TM": "Τουρκμενιστάν",
    "TN": "Τυνησία",
    "TO": "Τόνγκα",
    "TR": "Τουρκία",
    "TT": "Τρινιντάντ και Τομπάγκο",
    "TV": "Τουβαλού",
    "TW": "Ταϊβάν",
    "TZ": "Τανζανία",
    "UA": "Ουκρανία",
    "UG": "Ουγκάντα",
    "UM": "Μικρά απομακρυσμένα νησιά των Ηνωμένων Πολιτειών",
    "US": "Ηνωμένες Πολιτείες",
    "UY": "Ουρουγουάη",
    "UZ": "Ουζμπεκιστάν",
    "VA": "Πόλη του Βατικανού",
    "VC": "Άγιος Βικέντιος και Γρεναδίνες",
    "VE": "Βενεζουέλα",
    "VG": "Βρετανικές Παρθένοι Νήσοι",
    "VI": "Παρθένοι Νήσοι των ΗΠΑ",
    "VN": "Βιετνάμ",
    "VU": "Βανουάτου",
    "WF": "Νήσοι Ουώλις και Φουτούνα",
    "WS": "Σαμόα",
    "XK": "Κοσόβου",
    "YE": "Υεμένη",
    "YT": "Μαγιότ",
    "ZA": "Νότια Αφρική",
    "ZM": "Ζάμπια",
    "ZW": "Ζιμπάμπουε",
    "ZZ": "Διεθνές"
}

export default el