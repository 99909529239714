import React, {useState, useEffect} from 'react'
import OTPInput from "otp-input-react";
import styles from './index.module.css'

export default function OTP({
    theme, 
    digits,
    setOtpNum,
    hasError,
    setHasError,
    hasErrorOutside, 
    setHasErrorOutside, 
    errorMsg
}) {
    const [value, setValue] = useState("");

    const isValid = (v) =>{
        if(v){
            if(v.length === 5){
                return true
            }else{
                return false
            }
        }
        
    }

    useEffect(()=>{
        if (value){
            setHasErrorOutside(false)
            if(!isValid(value)){
                setHasError(true)
            }else{
                setHasError(null)
                setOtpNum(value)
            }
        }else{
            setHasError(true)
        }
    },[value, setHasErrorOutside, setHasError, setOtpNum])


    return (
        <>
            <OTPInput 
                value={value} 
                onChange={setValue} 
                autoFocus 
                OTPLength={digits} 
                otpType="number" 
                disabled={false} 
                inputClassName={ 
                (hasErrorOutside) ? (
                    theme === 'light' ? (
                        `${styles.OTPInputLight} ${styles.OTPInputErrorLight}`
                    ):(
                        `${styles.OTPInputDark} ${styles.OTPInputErrorDark}`
                    )     
                ): (
                    theme === 'light' ? (
                        styles.OTPInputLight
                    ):(
                        styles.OTPInputDark
                    )
                )}
            />
            {(hasErrorOutside) &&
                <div style={{ marginTop:4}}>
                <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errorMsg}</span>
                </div>
            }
        </>
    )
}
