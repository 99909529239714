const languages = {
    en:{
        translation:{
            el: "Greek",
            en: "English",
            welcome: "Welcome",
            toloofyApp: "to loofy app!",
            loginwithyouraccount: "Sign in with your account",
            email: "Email",
            password: "Password",
            confirmpassword: "Confirm password",
            forgotpassword: "Forgot password?",
            noaccount: "Don't have an account?",
            haveanaccount: "Already have an account?",
            logintoloofy: "Login to loofy",
            registerhere: "Register here",
            downloadloofyprompt: "Download loofy to you mobile device",
            termsofuse: "Terms of use",
            privacypolicy: "Privacy policy",
            login: "Login",
            emailWrongValidation: "Please enter a valid email address",
            emailRequiredValidation: "Email address is required",
            passwordRequiredValidation: "Password is required",
            passwordLowercaseValidation: "Password must have at least one lowercase letter",
            passwordUppercaseValidation: "Password must have at least one uppercase letter",
            passwordNumberValidation: "Password must have at least one number",
            passwordSpecialCharacterValidation: "Password must have at least one special character",
            password8limitValidation: "Password must consist of at least 8 characters",
            nameRequiredValidation: "Full name is required",
            register:"Register",
            fullname: "Full name",
            examplename: "John Richardson",
            createyouraccount: "Create your account",
            next: "Next",
            profilephoto: "Profile photo",
            uploadanimageasyourprofilephoto: "Upload an image as your profile photo",
            zoom: "Zoom",
            done_exclamation: "I'm done!",
            adjustyourphoto: "Adjust your photo",
            location: "Location",
            helpusprovidenotifications1:"Help us provide you with important notifications based on your location",
            foleskakopoiisieksafanisi: "(animal poisoning, abuse, disappearance etc.)",
            enableLocationServices: "Turn on location services",
            locationservices: "Location services",
            enterzipcodeofwhereyoulive: "or simply enter the zip code of the area where you live",
            registerAcceptedRules1: "By registering you accept our ",
            registerAcceptedRulesTerms: "terms of use",
            registerAcceptedRulesAnd: " and ",
            registerAcceptedRulesPrivacy: "privacy policy",
            youlivein: "You live in ",
            andyourzipcodeis: " and your zip code is ",
            ifyourlocationiswrongmovethepin: "If your location is incorrect, drag the marker to the right position",
            completeregistration: "Let's go!",
            breed: "Breed",
            chooseBreed: "Select breed",
            breedsCombined: "Is it crossbred?",
            breedsCombinedChooseAll: "Select the combined breeds",
            chooseColor: "Select color",
            colorsCombined: "Is it cross-colored?",
            colorsCombinedChooseAll: "Select the combined colors",
            mobileNumber: "Mobile number",
            settings: "Settings",
            helpcenter: "Help enter",
            logout: "Logout",
            importantSecurityNotice: "Important security notice",
            weRecommendMobileVerif: "For the highest security of loofy registered members, we strongly recommend mobile verification",
            verifiedMemberDistingWithSymbol: "The verified member is distinguished by the symbol",
            send5DigitOTP: "Get the 5-digit one-time code",
            enterMobNumAndPress: "Enter your mobile number and press",
            thenEnter5DigitOTPToVerifyAccount: "Then enter the 5-digit code to verify your account",
            phoneNumIsInvalid: "Phone number is invalid",
            phoneNumIsRequired: "Phone number is required",
            dontWantToVerifyAccount: "Don't want to verify your account?",
            skipThisStep: "Skip this step",
            enter5DigitCode: "Enter the 5-digit code",
            enterReceived5DigitOTPToVerifyAccount: "Enter the 5-digit code you received to verify your account",
            didntGetTheCode: "Didn't get the code?",
            tryAgain: "Try again",
            verifyAccount: "Verfiy account",
            addAll5DigitsOfCode: "Enter all 5 digits of the code",
            yourAccountNotVerified: "Your account has not been verified :(",
            thereWasProblemVerifyAccount: "There was a problem verifying your number.",
            tryAgainOrSkipStep: "Try again or temporarily skip this step",
            accountSuccessfulVerified: "Your account has been successfully verified!",
            continue: "Continue",
            letsStart: "Let's start",
            chooseHowYouWantToStart: "Choose how you want to start, you can add more along the way",
            addYourPet: "Add your pet",
            addYourPetDesc: "Register your pets and create their personal profile",
            becomeCoOwner: "Become a co-owner",
            becomeCoOwnerDesc: "Are your pets already registered by your own person? Make a co-ownership request",
            searchPetsForAdopt: "Search pets for adoption",
            searchPetsForAdoptDesc: "Find animals from shelters or other registered users",
            createProfessionalProfile: "Create a professional profile",
            createProfessionalProfileDesc: "Are you a veterinarian, petshop owner, groomer, trainer or other? Take advantage of loofy!",
            filoZwikesKatafigia: "Animal welfare groups/shelters",
            filoZwikesKatafigiaDesc: "Are you an active animal welfare member or want to add an animal welfare group or shelter?",
            selectPetSpecies: "Select your pet's species",
            dog: "Dog",
            cat: "Cat",
            petProfileCompleted1: "Profile is ",
            petProfileCompleted2: " complete",
            basicInformation: "Basic information",
            photo: "Photo",
            name: "Name",
            breedRequiredValidation: "Select at least one breed",
            gender: "Gender",
            color: "Color",
            genderRequiredValidation: "Gender is required",
            colorRequiredValidation: "Select at least one color",
            dateOfBirth: "Date of birth",
            dateNotAfterTodayValidation: "Date cannot be later than today",
            dateRequiredValidation: "Date of birth is required, if you don't know it you can choose an approximate one",
            writeSomeWords: "Write a few words",
            optional: "optional",
            health: "Health",
            sterilization: "Sterilization",
            sterilizationRequiredValidation: "Sterilization field is required",
            dateOfSterilization: "Date of sterilization",
            weight: "Weight (kg)",
            enterWeightInKg: "Enter the weight in kilograms (e.g 0,9, 12 or 25)",
            weightRequiredValidation: "Weight is required",
            dateOfweight: "Date of weight measurement",
            bloodType: "Blood type",
            chooseBloodType: "Select the pet's blood type",
            allergies: "Allergies",
            chooseAllergies: "Select allergies",
            allergiesCombined: "Does the pet have many allergies?",
            allergiesCombinedChooseAll: "Choose the ones you know",
            additionalInfo: "Additional Information",
            idNumber: "Identification number",
            microchipCode: "Microchip code",
            specialCharacteristics: "Special Characteristics",
            personalityBehavior: "Personality and behavior",
            chooseQuantityThatIsMoreCorrect: "Choose the percentage you think is most correct for each category",
            autopepithisi: "Self confidence",
            aneksartisia: "Independence",
            ntropalotita: "Shyness",
            deiliafovos: "Cowardice/Fear",
            epithetikotita: "Aggressiveness",
            kiriarxia: "Dominance",
            koinwnikotita: "Sociability",
            prosarmostikotita: "Adaptability",
            xalarotita: "Looseness",
            athlitikotita: "Athletic ability",
            completeProfile: "Complete profile",
            chooseDate: "Select date",
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
            sunday: "Sunday",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday_short: "S",
            monday_short: "M",
            tuesday_short: "T",
            wednesday_short: "W",
            thursday_short: "T",
            friday_short: "F",
            saturday_short: "S",
            nextMonth: "Next Month",
            previousMonth: "Previous Month",
            openMonthSelector: 'Open Month Selector',
            openYearSelector: 'Open Year Selector',
            closeMonthSelector: 'Close Month Selector',
            closeYearSelector: 'Close Year Selector',
            select: 'Select',
            from: 'from',
            to: 'to',
            thereAreNoResults: "There are no results"
        }
    },
    el:{
        translation:{
            el: "Ελληνικά",
            en: "Αγγλικά",
            welcome: "Καλωσήρθατε",
            toloofyApp: "στο loofy app!",
            loginwithyouraccount: "Συνδεθείτε με τον λογαριασμό σας",
            email: "Email",
            password: "Κωδικός",
            confirmpassword: "Επαλύθευση κωδικού",
            forgotpassword: "Ξέχασες τον κωδικό σου;",
            noaccount: "Δεν έχετε λογαριασμό;",
            haveanaccount: "Έχετε λογαριασμό;",
            logintoloofy: "Συνδεθείτε στο loofy",
            registerhere: "Εγγραφείτε εύκολα",
            downloadloofyprompt: "Κατεβάστε το loofy στο κινητό σας",
            termsofuse: "Όροι χρήσης",
            privacypolicy: "Πολιτική προστασίας",
            login: "Σύνδεση",
            emailWrongValidation: "Παρακαλώ εισάγετε έγκυρη διεύθυνση email",
            emailRequiredValidation: "Η διεύθυνση email είναι υποχρεωτική",
            passwordRequiredValidation: "Ο κωδικός είναι υποχρεωτικός",
            passwordLowercaseValidation: "Ο κωδικός πρέπει να έχει τουλάχιστον ένα πεζό γράμμα",
            passwordUppercaseValidation: "Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα",
            passwordNumberValidation: "Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμό",
            passwordSpecialCharacterValidation: "Ο κωδικός πρέπει να έχει τουλάχιστον έναν ειδικό χαρακτήρα",
            password8limitValidation: "Ο κωδικός πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες",
            nameRequiredValidation: "Το ονοματεπώνυμο είναι υποχρεωτικό",
            register:"Εγγραφή",
            fullname: "Ονοματεπώνυμο",
            examplename: "Γιάννης Παπαδόπουλος",
            createyouraccount: "Δημιουργήστε τον λογαριασμό σας",
            next: "Επόμενο",
            profilephoto: "Εικόνα προφίλ",
            uploadanimageasyourprofilephoto: "Ανεβάστε μια εικόνα ως φωτογραφία προφίλ σας",
            zoom: "Ζουμ",
            done_exclamation: "Έγινε!",
            adjustyourphoto: "Προσαρμόστε την φωτογραφία σας",
            location: "Τοποθεσία",
            helpusprovidenotifications1:"Βοηθήστε μας να σας παρέχουμε σημαντικές ειδοποιήσεις βάσει της τοποθεσίας σας",
            foleskakopoiisieksafanisi: "(φόλες, κακοποίηση ή εξαφάνιση ζώου κλπ)",
            enableLocationServices: "Ενεργοποιήστε τις υπηρεσίες τοποθεσίας",
            locationservices: "Υπηρεσίες τοποθεσίας",
            enterzipcodeofwhereyoulive: "ή απλά εισάγετε τον ταχυδρομικό κώδικα της περιοχής σας",
            registerAcceptedRules1: "Με την ολοκλήρωση εγγραφής αποδέχεστε τους ",
            registerAcceptedRulesTerms: "όρους χρήσης",
            registerAcceptedRulesAnd: " και την ",
            registerAcceptedRulesPrivacy: "πολιτική προστασίας προσωπικών δεδομένων",
            youlivein: "Η περιοχή διαμονής σας είναι ",
            andyourzipcodeis: " και ο ταχυδρομικός σας κώδικας ",
            ifyourlocationiswrongmovethepin: "Αν η τοποθεσία σας δεν εμφανίζεται σωστά, σύρετε την πινέζα στην σωστή θέση",
            completeregistration: "Ολοκλήρωση εγγραφής",
            breed: "Φυλή",
            chooseBreed: "Επιλέξτε φυλή",
            breedsCombined: "Υπάρχει διασταύρωση φυλών;",
            breedsCombinedChooseAll: "Επιλέξετε όλες τις συνδυαζόμενες φυλές",
            chooseColor: "Επιλέξτε χρώμα",
            colorsCombined: "Υπάρχει διασταύρωση χρωμάτων;",
            colorsCombinedChooseAll: "Επιλέξετε όλα τις συνδυαζόμενα χρώματα",
            mobileNumber: "Αριθμός κινητού τηλεφώνου",
            settings: "Ρυθμίσεις",
            helpcenter: "Κέντρο βοήθειας",
            logout: "Αποσύνδεση",
            importantSecurityNotice: "Σημαντική ειδοποίηση ασφαλείας",
            weRecommendMobileVerif: "Για τη μεγαλύτερη ασφάλεια των μελών της κοινότητας του loofy, προτείνουμε την επαλήθευση μέσω κινητού",
            verifiedMemberDistingWithSymbol: "Το επαληθευμένο μέλος διακρίνεται με το σύμβολο",
            send5DigitOTP: "Αποστολή 5-ψήφιου κωδικού μια χρήσης",
            enterMobNumAndPress: "Εισάγετε τον αριθμό του κινητού σας και πατήστε",
            thenEnter5DigitOTPToVerifyAccount: "Έπειτα, εισάγετε τον 5-ψήφιο κωδικό για να επαληθεύσετε τον λογαριασμό σας",
            phoneNumIsInvalid: "Ο αριθμός τηλεφώνου δεν είναι έγκυρος",
            phoneNumIsRequired: "Απαιτείται αριθμός τηλεφώνου",
            dontWantToVerifyAccount: "Δεν επιθυμείτε να επαληθεύσετε τον λογαριασμό σας;",
            skipThisStep: "Παραλείψτε αυτό το βήμα",
            enter5DigitCode: "Εισάγετε τον 5-ψήφιο κωδικό",
            enterReceived5DigitOTPToVerifyAccount: "Εισάγετε τον 5-ψήφιο κωδικό που λάβατε για να επαληθεύσετε τον λογαριασμό σας",
            didntGetTheCode: "Δεν λάβατε τον κωδικό;",
            tryAgain: "Δοκιμάστε ξανά",
            verifyAccount: "Επαληθεύστε τον λογαριασμό σας",
            addAll5DigitsOfCode: "Συμπληρώστε και τα 5 ψηφία του κωδικού",
            yourAccountNotVerified: "Ο λογαριασμός σας δεν επαληθεύτηκε :(",
            thereWasProblemVerifyAccount: "Υπήρξε κάποιο πρόβλημα με την επαλήθευση του αριθμού σας.",
            tryAgainOrSkipStep: "Δοκιμάστε ξανά ή παραλείψτε προσωρινά αυτό το βήμα",
            accountSuccessfulVerified: "Ο λογαριασμός σας επαληθεύτηκε με επιτυχία!",
            continue: "Συνέχεια",
            letsStart: "Ας ξεκινήσουμε",
            chooseHowYouWantToStart: "Επιλέξτε πως θέλετε να ξεκινήσετε, στην πορεία μπορείτε να προσθέσετε περισσότερα",
            addYourPet: "Προσθέστε το κατοικίδιο σας",
            addYourPetDesc: "Καταχωρήστε τα ζωάκια σας και δημιουργήστε το προσωπικό τους προφίλ",
            becomeCoOwner: "Γίνετε συνιδιοκτήτης",
            becomeCoOwnerDesc: "Τα ζωάκια σας είναι ήδη καταχωρημένα από δικό σας άτομο; Κάντε αίτημα συνιδιοκτησίας",
            searchPetsForAdopt: "Αναζητήστε κατοικίδια προς υιοθεσία",
            searchPetsForAdoptDesc: "Βρείτε ζωάκια από φιλοζωικές εταιρίες, καταφύγια ή άλλους εγγεγραμμένους χρήστες",
            createProfessionalProfile: "Δημιουργήστε επαγγελματικό προφίλ",
            createProfessionalProfileDesc: "Είστε κτηνίατρος, ιδιοκτήτης petshop, groomer, εκπαιδευτής ή άλλο; Αξιοποιήστε το loofy!",
            filoZwikesKatafigia: "Φιλοζωικές και καταφύγια ζώων",
            filoZwikesKatafigiaDesc: "Είστε ενεργό μέλος φιλοζωικής ή θέλετε να προσθέσετε φιλοζωική/καταφύγιο ζώων;",
            selectPetSpecies: "Επιλέξτε το είδος του κατοικίδιου σας",
            dog: "Σκύλος",
            cat: "Γάτα",
            petProfileCompleted1: "Το προφίλ έχει ολοκληρωθεί κατά",
            petProfileCompleted2: "",
            basicInformation: "Βασικά στοιχεία",
            photo: "Φωτογραφία",
            name: "Όνομα",
            breedRequiredValidation: "Επιλέξτε τουλάχιστον μια φυλή",
            gender: "Φύλο",
            color: "Χρώμα",
            genderRequiredValidation: "Η επιλογή φύλου είναι υποχρεωτική",
            colorRequiredValidation: "Επιλέξτε τουλάχιστον ένα χρώμα",
            dateOfBirth: "Ημερομηνία γέννησης",
            dateNotAfterTodayValidation: "Η ημερομηνία δεν μπορεί να είναι μελλοντική",
            dateRequiredValidation: "Η ημερομηνία γέννησης είναι υποχρεωτική, αν δεν την γνωρίζετε μπορείτε να επιλέξετε κατά προσέγγιση",
            writeSomeWords: "Γράψτε λίγα λόγια",
            optional: "προαιρετικό",
            health: "Υγεία",
            sterilization: "Στείρωση",
            sterilizationRequiredValidation: "Η επιλογή στείρωσης είναι υποχρεωτική",
            dateOfSterilization: "Ημερομηνία στείρωσης",
            weight: "Βάρος (κιλά)",
            enterWeightInKg: "Εισάγετε το βάρος σε κιλά (πχ 0,9, 12 ή 25)",
            weightRequiredValidation: "Το βάρος είναι υποχρεωτικό",
            dateOfweight: "Ημερομηνία μέτρησης βάρους",
            bloodType: "Ομάδα αίματος",
            chooseBloodType: "Επιλέξτε την ομάδα αίματος του κατοικίδιου",
            allergies: "Αλλεργίες",
            chooseAllergies: "Επιλέξτε τις αλλεργίες",
            allergiesCombined: "Το κατοικίδιο έχει πολλές αλλεργίες;",
            allergiesCombinedChooseAll: "Επιλέξτε όσες γνωρίζετε",
            additionalInfo: "Πρόσθετες πληροφορίες",
            idNumber: "Αριθμός ταυτότητας",
            microchipCode: "Κωδικός microchip",
            specialCharacteristics: "Ιδιαίτερα χαρακτηριστικά",
            personalityBehavior: "Προσωπικότητα & συμπεριφορά",
            chooseQuantityThatIsMoreCorrect: "Επιλέξτε το ποσοστό που θεωρείτε σωστότερο για κάθε κατηγορία",
            autopepithisi: "Αυτοπεποίθηση",
            aneksartisia: "Ανεξαρτησία",
            ntropalotita: "Ντροπαλότητα",
            deiliafovos: "Δειλία/Φόβος",
            epithetikotita: "Επιθετικότητα",
            kiriarxia: "Κυριαρχία",
            koinwnikotita: "Κοινωνικότητα",
            prosarmostikotita: "Προσαρμοστικότητα",
            xalarotita: "Χαλαρότητα",
            athlitikotita: "Αθλητικότητα",
            completeProfile: "Ολοκλήρωση προφίλ",
            chooseDate: "Επιλέξτε ημερομηνία",
            january: "Ιανουάριος",
            february: "Φεβρουάριος",
            march: "Μάρτιος",
            april: "Απρίλιος",
            may: "Μάιος",
            june: "Ιούνιος",
            july: "Ιούλιος",
            august: "Αύγουστος",
            september: "Σεπτέμβριος",
            october: "Οκτώβριος",
            november: "Νοέμβριος",
            december: "Δεκέμβριος",
            sunday: "Κυριακή",
            monday: "Δευτέρα",
            tuesday: "Τρίτη",
            wednesday: "Τετάρτη",
            thursday: "Πέμπτη",
            friday: "Παρασκευή",
            saturday: "Σάββατο",
            sunday_short: "Κ",
            monday_short: "Δ",
            tuesday_short: "Τ",
            wednesday_short: "Τ",
            thursday_short: "Π",
            friday_short: "Π",
            saturday_short: "Σ",
            nextMonth: "Επόμενος Μήνας",
            previousMonth: "Προηγούμενος Μήνας",
            openMonthSelector: "Άνοιγμα επιλογής μήνα",
            openYearSelector: "Άνοιγμα επιλογής έτους",
            closeMonthSelector: "Κλείσιμο επιλογής μήνα",
            closeYearSelector: "Κλείσιμο επιλογής έτους",
            select: "Επιλογή",
            from: 'από',
            to: 'έως',
            thereAreNoResults: "Δεν υπάρχουν αποτελέσματα"
        }
    }
}
export default languages