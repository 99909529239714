import React from 'react'

export default function VerifiedUser({size = 24}) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" width={size} height={size}>
        <path style={{fill:'#68B6FD'}} d="M427.8,54.1L312.5,17.7C294.3,11.9,275.2,9,256,9s-38.3,2.9-56.5,8.7L84.2,54.1C60.1,61.7,43.7,84,43.7,109.3
            v200.1c0,36.8,17.5,71.4,47.1,93.2l112.9,83.2C218.9,497,237.2,503,256,503s37.1-6,52.2-17.2l112.9-83.2
            c29.6-21.8,47.1-56.4,47.1-93.2V109.3C468.3,84.1,451.9,61.7,427.8,54.1z"/>
        <path  style={{fill:'#FFFFFF'}} d="M344.7,228.7l-95.5,95.5c-11.3,11.3-29.6,11.3-40.9,0l-40.9-40.9c-11.3-11.3-11.3-29.6,0-40.9s29.6-11.3,40.9,0
            l20.5,20.5l75-75c11.3-11.3,29.6-11.3,40.9,0C356,199.1,356,217.4,344.7,228.7z"/>
    </svg>
  )
}
