import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

export default function MainRoutes() {
  const { user } = useAuth();

  if (!user.roles) {
    return <Navigate to="/getstarted" replace />;
  }

  return (
    <Outlet/>
  );
};