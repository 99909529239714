import React, {useContext, useState, useEffect} from 'react'
import { useOutletContext, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik'
import * as yup from 'yup'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import { AppearanceContext } from '../../../../../context/AppearanceContext';
import DogSelectedBg from '../../../../../assets/getStarted/AddPet/DogSelectedBg.png'
import CatSelectedBg from '../../../../../assets/getStarted/AddPet/CatSelectedBg.png'


import LTitle from '../../../../../components/Titles/LTitle';
import PrimaryBtnLarge from '../../../../../components/Buttons/PrimaryBtnLarge';
import ProfileImagePicker from '../../../../../components/Pickers/ImagePickers/ProfileImagePicker';
import Input from '../../../../../components/Inputs/Input';
import MultiSelect from '../../../../../components/Select/MultiSelect';
import RadioChipMd from '../../../../../components/Inputs/RadioChips/RadioChipMd';
import DatePick from '../../../../../components/Inputs/DatePicker';
import TextArea from '../../../../../components/Inputs/TextArea';

import DogBreeds from '../../../../../DUMMY/DogBreeds'
import CatBreeds from '../../../../../DUMMY/CatBreeds'
import Genders from '../../../../../DUMMY/Genders'
import PetColors from '../../../../../DUMMY/PetColors';





import TIME from '../../../../../constants/TIME';
const MoveValue = 10




export default function AddPetBasics() {
    
    const { width } = useWindowDimensions();
    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()


    const [setProgressBarVisible, setNextProgress, setPrevProgress] = useOutletContext();

    const [profileImg, setProfileImg] = useState(location.state ? location.state[1] ? location.state[1].petImg ? location.state[1].petImg : null : null : null);

    useEffect(() => {
        console.log(location.state)
        setProgressBarVisible(true)
        setPrevProgress(0)
        setNextProgress(8)
    },[location, setProgressBarVisible, setPrevProgress, setNextProgress])
    



    const [stateImg, setStateImg] = useState(DogSelectedBg);
    const [breedsList, setBreedsList] = useState(null);
    useEffect(() => {
        if(location.state){
            if(location.state[0].speciesSelected === 0){
                setStateImg(DogSelectedBg);
                setBreedsList(DogBreeds);
            }else{
                setStateImg(CatSelectedBg);
                setBreedsList(CatBreeds);
            }
        }
    }, [location.state])


    const registerPetValidationSchema_1 = yup.object().shape({
        name: yup
            .string()
            .required(t('nameRequiredValidation')),
        breeds: yup
            .array()
            .min(1, t('breedRequiredValidation')),
        gender: yup
            .string()
            .required(t('genderRequiredValidation')),
        colors: yup
            .array()
            .min(1, t('colorRequiredValidation')),
        birth: yup
            .date()
            .max(new Date(), t('dateNotAfterTodayValidation'))
            .required(t('dateRequiredValidation')),
        somewords: yup
            .string()
    })

    const formik = useFormik({
        validationSchema: registerPetValidationSchema_1,
        initialValues: {
          name: location.state ? location.state[1] ? location.state[1].petname ? location.state[1].petname : '' : '' : '',
          breeds: location.state ? location.state[1] ? location.state[1].petbreed ? location.state[1].petbreed : [] : [] : [],
          gender: location.state ? location.state[1] ? location.state[1].petgender ? location.state[1].petgender : '' : '' : '',
          colors: location.state ? location.state[1] ? location.state[1].petcolor ? location.state[1].petcolor : [] : [] : [],
          birth: location.state ? location.state[1] ? location.state[1].petbirth ? location.state[1].petbirth : '' : '' : '',
          somewords: location.state ? location.state[1] ? location.state[1].petfewwords ? location.state[1].petfewwords : '' : '' : ''
        },
        onSubmit: values => {
            
            if(location.state.length > 1){
                location.state.pop();
            }
            // console.log(values.name, values.breeds, values.gender, values.colors, values.birth)
            navigate('/getstarted/pet-health', { state: [...location.state, ...[{petImg: profileImg ? profileImg : null, petname: values.name, petbreed: values.breeds, petgender: values.gender, petcolor: values.colors, petbirth: values.birth, petfewwords: values.somewords}]] })
        },
    });

   

    if (location.state === null) {
        return <Navigate to="/getstarted" />;
    }else if(location.state[0] === null){
        return <Navigate to="/getstarted" />;
    }


    return (
        <>

        <motion.div
            className={styles.TopRightImg}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 10*TIME }}
        >
            <img src={stateImg} alt='Pet animal hero' />
        </motion.div>

        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('basicInformation')} 
                backButtonPath='/getstarted/add-your-pet'
                hasBackButton
                backButtonParams={location.state}
            />
        </motion.div>

        <div className={styles.SeperatorS}></div>

        <form onSubmit={formik.handleSubmit}>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 3*TIME }}
            >
                <ProfileImagePicker
                    label={t('photo')} 
                    theme={theme}
                    confirmedShownImage={
                        profileImg ? profileImg : null
                    }
                    setConfirmedShownImage={setProfileImg}
                    width={160}
                    height={160}
                    borderRadius={160}
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 4*TIME }}
                style={{maxWidth:500}}
            >
                <Input
                    formik={formik} 
                    label={t('name')} 
                    type='text'
                    placeholder='Jack'
                    name="name" 
                    value={formik.values.name} 
                    errors={formik.errors.name}
                    touched={formik.touched.name}
                    theme={theme}
                    autoComplete={false}
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 5*TIME }}
                style={{maxWidth:500, position:'relative', zIndex:3}}
            >
                {breedsList ? (
                    <MultiSelect 
                        formik={formik}
                        name='breeds'
                        ListItemType='breeds'
                        value={formik.values.breeds} 
                        errors={formik.errors.breeds}
                        touched={formik.touched.breeds}
                        theme={theme} 
                        type='text'
                        label={t('breed')} 
                        data={breedsList} 
                        placeholder={t('chooseBreed')} 
                        id='breedMultiSelect'
                        hasToolTip
                        toolTipContent={<span>{t('breedsCombined')}<br/>{t('breedsCombinedChooseAll')}</span>}
                        toolTipPlacement={width > 768 ? 'right' : 'top'}
                        isToolTipInfo
                    />
                ):null}
                
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 6*TIME }}
                style={{maxWidth:500}}
            >
                <RadioChipMd 
                    label={t('gender')}
                    data={Genders} 
                    formik={formik}
                    name='gender'
                    value={formik.values.gender} 
                    errors={formik.errors.gender}
                    touched={formik.touched.gender}
                    theme={theme} 
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 7*TIME }}
                style={{maxWidth:500, position:'relative', zIndex:2}}
            >
                <MultiSelect 
                    formik={formik}
                    name='colors'
                    ListItemType='colors'
                    value={formik.values.colors} 
                    errors={formik.errors.colors}
                    touched={formik.touched.colors}
                    theme={theme} 
                    type='text'
                    label={t('color')} 
                    data={PetColors} 
                    placeholder={t('chooseColor')} 
                    id='colorMultiSelect'
                    hasToolTip
                    toolTipContent={<span>{t('colorsCombined')}<br/>{t('colorsCombinedChooseAll')}</span>}
                    toolTipPlacement={width > 768 ? 'right' : 'top'}
                    isToolTipInfo
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 8*TIME }}
                style={{maxWidth:500, position:'relative', zIndex:1}}
            >
                <DatePick 
                    formik={formik}
                    label={t('dateOfBirth')} 
                    name='birth'
                    value={formik.values.birth} 
                    errors={formik.errors.birth}
                    touched={formik.touched.birth}
                    theme={theme} 
                    maxDate={new Date()}
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 9*TIME }}
                style={{maxWidth:500, position:'relative'}}
            >
                <TextArea
                    formik={formik}
                    label={t('writeSomeWords') + ' ('+t('optional') + ')'} 
                    name='somewords'
                    value={formik.values.somewords} 
                    errors={formik.errors.somewords}
                    touched={formik.touched.somewords}
                    theme={theme} 
                />
            </motion.div>

            <motion.div
                style={{maxWidth:280, marginTop:80, marginBottom:150}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 10*TIME }}
            >
                <PrimaryBtnLarge type='submit' fullWidth title={t('next')} />
            </motion.div>

        </form>


      


           
        </>
    )
}
