import React, {useState, useContext, useRef, useEffect, useCallback} from 'react'
import { RiArrowDropDownLine, RiSearchLine } from "react-icons/ri";
import Fuse from 'fuse.js'
import styles from './index.module.css'
import { motion } from "framer-motion"
import CountryItem from './CountryItem';
import { AppearanceContext } from '../../../context/AppearanceContext';

const CustomSelect = (props, ref) => {

    const { theme } = useContext(AppearanceContext)

    const openDropDown = () => {
        setOpenedDropDown(true)
    }
    const [searchResults, setSearchResults] = useState(props.options);

    const refDd = useRef();
    const flagRef = useRef();
    const refSearchInput = useRef()
    const [openedDropDown, setOpenedDropDown] = useState(false);
    const [selected, setSelected] = useState(props.value);
    useOnClickOutside(refDd, () => setOpenedDropDown(false));

    function useOnClickOutside(reff, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              if (!reff.current || reff.current.contains(event.target) || flagRef.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [reff, handler]
        );
    }


    const options = {
        shouldSort: true,
        minMatchCharLength: 1,
        keys: ['label'],
        threshold:0.5,
        // threshold:1.0,
        includeMatches: true,
        // findAllMatches: true,
        // useExtendedSearch: true,
        ignoreLocation: true
    }
      
    const fuse = new Fuse(props.options, options)
      

    const liveSearch = (e) =>{
        const length = e.target.value.length;
        if(length >= 1){
            const searchedObjects = []
            const result = fuse.search(e.target.value);
            result.forEach((item, index) => {
                searchedObjects.push(item.item)
                return;
            });
            setSearchResults(searchedObjects);
        }else{
            setSearchResults(props.options);
        }
    }

    



    const renderItem = useCallback(
        (item) => (
            <CountryItem key={item.value} theme={theme} item={item} onClick={()=>{
                setSelected(item.value); 
                props.onChange(item.value);
                setOpenedDropDown(false);
                refSearchInput.current.value = '';
                setSearchResults(props.options)
            }} />
        ),
        [theme, props]
    );







    return (
        <>
            <div ref={flagRef} onClick={()=>openDropDown()} style={{display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
                {selected ? (
                <motion.div 
                    key={selected}
                    initial={{ opacity: 0, translateY:10, scale: 0.7 }}
                    animate={{ opacity: 1, translateY:0, scale: 1 }}
                    exit={{ opacity: 0, translateY:10, scale: 0.7 }}
                    transition={{ duration: 0.25 }}
                >   
                    <img style={{display:'block'}} alt='selected country' src={require(`../../../assets/Countries/${selected}.svg`)} width={30} height={20} />
                    {/* <img style={{display:'block'}} alt='selected country' src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selected}.svg`} width={30} height={20} /> */}
                </motion.div>
                ):null}
                <RiArrowDropDownLine className={theme==='light' ? styles.DropDownChevronLight : styles.DropDownChevronDark} size={26} />
            </div>
            <div style={{position:'absolute',top:'100%', left:0}}>
            {
                openedDropDown ? (
                    <motion.div 
                        className={theme === 'light' ? `${styles.DropDownContainerLight}` : `${styles.DropDownContainerDark}`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.25 }}
                        ref={refDd}
                    >   
                        <div className={ theme === 'light' ? styles.DropDownInputContainerLight : styles.DropDownInputContainerDark}>
                            <div  className={ theme === 'light' ? styles.DropDownInputOuterLight : styles.DropDownInputOuterDark}>
                                <div className={styles.DropDownSearchIconContainer}>
                                    <RiSearchLine className={styles.DropDownSearchIcon} size={18} />
                                </div>
                                <input ref={refSearchInput} autoFocus type='text' onChange={liveSearch} className={ theme === 'light' ? styles.DropDownInputLight : styles.DropDownInputDark} />
                            </div>
                        </div>
                        <div className={styles.CountriesResults}>
                            {
                                
                                // props.options.map(renderItem)
                                searchResults.map(renderItem)
                            }
                        </div>
                    </motion.div>
                ):null
            }
            </div>
        </>
    )
}

export default React.forwardRef(CustomSelect)
