import React from 'react'
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'

export default function LTitle({title, theme, hasBackButton, backButtonPath, backButtonParams = null, color = false}) {

  const navigate = useNavigate()

  return (  
    !color ? (
      hasBackButton ? (
        <div className={styles.BackButtonAndTitleContainer}>
          {
            backButtonParams ? (
              <RiArrowLeftLine className={theme === 'light' ? styles.BackButtonLight : styles.BackButtonDark} onClick={()=>navigate(backButtonPath, { state: backButtonParams })}/>
            ):(
              <RiArrowLeftLine className={theme === 'light' ? styles.BackButtonLight : styles.BackButtonDark} onClick={()=>navigate(backButtonPath)}/>
            )
          }
          <h1 className={theme === 'light' ? styles.MainTitleH1Light : styles.MainTitleH1Dark}>{title}</h1>
        </div>
      ):(
        <h1 className={theme === 'light' ? styles.MainTitleLight : styles.MainTitleDark}>{title}</h1>
      )
    ) : (
      hasBackButton ? (
        <div className={styles.BackButtonAndTitleContainer}>
          {
            backButtonParams ? (
              <RiArrowLeftLine className={theme === 'light' ? styles.BackButtonColoredLight : styles.BackButtonColoredDark} onClick={()=>navigate(backButtonPath, { state: backButtonParams })}/>
            ):(
              <RiArrowLeftLine className={theme === 'light' ? styles.BackButtonColoredLight : styles.BackButtonColoredDark} onClick={()=>navigate(backButtonPath)}/>
            )
          }
          <h1 className={theme === 'light' ? styles.MainTitleH1ColoredLight : styles.MainTitleH1ColoredDark}>{title}</h1>
        </div>
      ):(
        <h1 className={theme === 'light' ? styles.MainTitleColoredLight : styles.MainTitleColoredDark}>{title}</h1>
      )
    )
  )
}
