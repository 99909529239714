import React from 'react'
import styles from './index.module.css'

export default function SubTitle({text1, text2, theme, iconInText}) {
  return (
    iconInText ? (
      <h2 className={theme === 'light' ? styles.SubTitleLight : styles.SubTitleDark}>{text1}<br/><span className={styles.SubTitleText2WithIcon}>{text2}{iconInText}</span></h2>
    ):(
      <h2 className={theme === 'light' ? styles.SubTitleLight : styles.SubTitleDark}>{text1}<br/><span className={styles.SubTitleText2}>{text2}</span></h2>
    )
  )
}
