import React from 'react'
import styles from './index.module.css'

export default function Chip({item, theme, errors, touched, selected, onClick}) {
  return (
        <div 
            key={item.id} 
            className={
                (errors && touched) ? (
                    theme === 'light' ? (
                        `${styles.ChipOuterLight} ${styles.ChipOuterErrorLight}`
                    ):(
                        `${styles.ChipOuterDark} ${styles.ChipOuterErrorDark}`
                    )     
                ): (
                    theme === 'light' ? (
                        selected ? (styles.ChipOuterSelectedLight):(styles.ChipOuterLight)
                    ):(
                        selected ? (styles.ChipOuterSelectedDark):(styles.ChipOuterDark)
                    )
                )}
            onClick={onClick}
            >
            {item.title}
        </div>
  )
}
