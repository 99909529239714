import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { AppearanceContext } from "../context/AppearanceContext";

import LoaderScreen from "../layout/LoaderScreen";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const {setLoadingFunc, loading} = useContext(AppearanceContext)

  // call this function when you want to authenticate the auth
  const login = async (data) => {
    setLoadingFunc(true)
    setTimeout(() => {
      setUser(data);
      if(!data.roles){
        navigate("/getstarted");
        setLoadingFunc(false)
      }else{
        navigate("/");
        setLoadingFunc(false)
      }
    }, 5000)
  };

  // call this function to sign out logged in auth
  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  // const value = useMemo( 
  //   () => ({
  //     auth,
  //     login,
  //     logout
  //   }),
  //   [auth, login, logout]
  // );

  const value = {
    user,
    login,
    logout
  };
  return (
    <AuthContext.Provider value={value}>
      {children}
      {
      loading ? (
        <LoaderScreen fixed />
      ) : null
      }
    </AuthContext.Provider>
    )
};

export const useAuth = () => {
  return useContext(AuthContext);
};