import React, {useContext} from 'react'
import { Link, Outlet } from "react-router-dom";
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import styles from './index.module.css'
import logoLight from '../../../assets/loofy_logo_full_Light.png'
import logoDark from '../../../assets/loofy_logo_full_Dark.png'
import poster from '../../../assets/posterTransparent.png'
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import { AppearanceContext } from '../../../context/AppearanceContext'

import TIME from '../../../constants/TIME';
import FloatingLanguagePicker from '../../../components/Pickers/LanguagePickers/FloatingLanguagePicker';



const MoveValue = 10

export default function UserLoginAndRegister() {


    const { theme, setThemeFunc } = useContext(AppearanceContext);
    const { t, i18n } = useTranslation()

    const { width } = useWindowDimensions()


    const toggleDarkMode = (checked) => {
        setThemeFunc(checked === true ? 'dark' : 'light');
    };


  return (
    <div className={styles.MainContainer}>
        <div className={styles.LangAndThemeContainer}>
            <FloatingLanguagePicker languages={i18n.languages} activeLanguage={i18n.language} theme={theme}/>
            <div className={styles.ThemeSwitcher}>
                <DarkModeSwitch
                    style={{ marginBottom: '2rem' }}
                    className={theme === 'light' ? styles.ThemeSwitchLight : styles.ThemeSwitchDark}
                    checked={theme === 'light' ? false : true}
                    onChange={toggleDarkMode}
                    size={25}
                    moonColor='#fff'
                    sunColor='#FFBD1F'
                />
            </div>
        </div>
        <div className={styles.MainContainerInner}>
            <div className={styles.Inner} id='MainAppIntro'>
                <motion.div
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25 }}
                >
                    <Link to='/'><img src={theme === 'light' ? width < 768 ? logoDark : logoLight : logoDark} className={styles.Logo} alt='Loofy Logo' /></Link>
                </motion.div>
                <Outlet />
                <motion.div
                    className={theme === 'light' ? styles.FooterInfoLight : styles.FooterInfoDark}
                    initial={{ opacity: 0, translateX: MoveValue }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: MoveValue }}
                    transition={{ duration: 0.25, delay: 8*TIME }}
                >
                    <div className={theme === 'light' ? styles.FooterInfoLinksLight : styles.FooterInfoLinksDark}>© 2023 loofy · <Link to='/termsofuse' target='_blank'>{t('termsofuse')}</Link> · <Link to='/privacypolicy' target='_blank'>{t('privacypolicy')}</Link></div>
                </motion.div>
            </div>
        </div>
        <div className={styles.MainPosterContainer}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25, delay: TIME }} 
                className={theme === 'light' ? styles.MainPosterBgLight : styles.MainPosterBgDark}
            ></motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25, delay: 2*TIME }}
            >
                <img src={poster} className={styles.MainPoster} alt='poster' />
            </motion.div>
        </div>
    </div>
  )
}
