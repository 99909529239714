import React, {useLayoutEffect} from 'react'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

export default function OnBoardingRoutes() {
  const { user } = useAuth();
  const location = useLocation();

  // SCROLL TO TOP ON EVERY URL CHANGE
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  // SCROLL TO TOP ON EVERY URL CHANGE


  if (user.roles) {
    return <Navigate to="/" />;
  }


  return (
        <Outlet />
  );
};