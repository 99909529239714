const DogBloodTypes = [
    {title:'Δεν γνωρίζω', id:1, en:'I don\'t know'},
    {title:'DEA 1 Αρνητικό', id:2, en:'DEA 1 Negative'},
    {title:'DEA 1 Θετικό', id:3, en:'DEA 1 Positive'},
    {title:'DEA 1.1', id:4, en:'DEA 1.1'},
    {title:'DEA 1.2', id:5, en:'DEA 1.2'},
    {title:'DEA 1.3', id:6, en:'DEA 1.3'},
    {title:'DEA 3', id:7, en:'DEA 3'},
    {title:'DEA 7', id:8, en:'DEA 7'},
];

export default DogBloodTypes;