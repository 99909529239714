import React, {useState, useContext} from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import BottomProgressBar from '../../../../components/ProgressBars/BottomProgressBar'

import { AppearanceContext } from '../../../../context/AppearanceContext'

export default function AddPetLayout() {
    
    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();

    const [progressBarVisible, setProgressBarVisible] = useState(false)
    const [nextProgress, setNextProgress] = useState(0)
    const [prevProgress, setPrevProgress] = useState(0)
    

    return (
        <>  
            <Outlet context={[setProgressBarVisible, setNextProgress, setPrevProgress]} />
            <BottomProgressBar 
                theme={theme}
                progressBarVisible={progressBarVisible}
                from={prevProgress}
                to={nextProgress} 
                text1={t('petProfileCompleted1')}
                text2={t('petProfileCompleted2')}
            />
        </>
    )
}
