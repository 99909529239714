// import React from 'react'
// import { Outlet } from 'react-router-dom'

// export default function ProtectedRoutes() {
//   return (
//     <Outlet/>
//   )
// }

import React from 'react'
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import styles from './index.module.css'
import { AppearanceContext } from "../../context/AppearanceContext";

export default function ProtectedRoutes() {
  const { user } = useAuth();
  const {theme} = React.useContext(AppearanceContext)

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
      <div className={theme === 'light' ? styles.MainContainerLight : styles.MainContainerDark}>
        <Outlet />
      </div>
  );
};

