import React, { useContext, useState }  from 'react'
// import { Outlet, Link, Navigate } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../../../context/AppearanceContext';

// import { useAuth } from '../../../../hooks/useAuth';

import LTitle from '../../../../components/Titles/LTitle';
import SubTitle from '../../../../components/Titles/SubTitleDual';
import ToolTip from '../../../../components/ToolTip';
import Phone from '../../../../components/Inputs/Phone'
import PrimaryBtnLarge from '../../../../components/Buttons/PrimaryBtnLarge';
// import stateImage from '../../../../assets/AddMobilePhone/verified_3d.png'




import TIME from '../../../../constants/TIME';
const MoveValue = 10

export default function AddMobilePhoneFailed() {
    const { theme } = useContext(AppearanceContext);
    const [t, i18n ] = useTranslation();
    const navigate = useNavigate()

    const [num, setNum] = useState(null)
    const [hasError, setHasError] = useState(null)
    const [hasErrorOutside, setHasErrorOutside] = useState(false)

    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(hasError){
            setHasErrorOutside(true)
        }else{
            console.log(num)
            navigate("/enter-otp")
        }
    }

  return (
    <>
        {/* <motion.div
            style={{position:'absolute',top:100, right:10}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 7*TIME }}
        >
            <img src={stateImage} alt='Verification hero' />
        </motion.div> */}

        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('yourAccountNotVerified')} 
            />
        </motion.div>
        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 3*TIME }}
        >
            <SubTitle 
                theme={theme} 
                text1={t('thereWasProblemVerifyAccount')}
                text2={t('tryAgainOrSkipStep')}
            />
        </motion.div>

        <form onSubmit={handleSubmit}>
            <motion.div
                style={{maxWidth:400, minHeight:232, marginTop:150, position:'relative', zIndex:2}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 4*TIME }}
            >
                <ToolTip 
                    id='phone'
                    theme={theme}
                    isOpen={true}
                    hasToolTip
                    toolTipContent={<span style={{maxWidth:350, lineHeight:'26px'}}>{t('enterMobNumAndPress')} "<strong>{t('send5DigitOTP')}</strong>". {t('thenEnter5DigitOTPToVerifyAccount')}</span>}
                    toolTipPlacement='top'
                    isToolTipInfo
                />
                <div id='phone' style={{marginBottom:10}}></div>
                <Phone
                    setNum={setNum}
                    language={i18n}
                    label={t('mobileNumber')}
                    type='email'
                    placeholder='123 456 7890'
                    name="email" 
                    theme={theme}
                    hasError={hasError}
                    setHasError={setHasError}
                    hasErrorOutside={hasErrorOutside}
                    setHasErrorOutside={setHasErrorOutside}
                    invalidText={t('phoneNumIsInvalid')}
                    requiredText={t('phoneNumIsRequired')}
                /> 
            </motion.div>


            <motion.div
                style={{maxWidth:420}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 5*TIME }}
            >
                <PrimaryBtnLarge type='submit' fullWidth title={t('send5DigitOTP')} />
            </motion.div>
        </form>

        <div className={styles.SeperatorXL}></div>

        <motion.div
            style={{maxWidth:420,}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 6*TIME }}
        >
            <div className={theme === 'light' ? styles.IgnorePromptLight : styles.IgnorePromptDark}>
                {t('dontWantToVerifyAccount')}
            </div>
            <Link className={theme === 'light' ? styles.IgnorePromptLight : styles.IgnorePromptDark}>
                <span>{t('skipThisStep')}</span>
            </Link>
        </motion.div>

    </>
  )
}
