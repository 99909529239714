import React, {useContext, useState, useEffect} from 'react'
import { useOutletContext, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik'
import * as yup from 'yup'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import { AppearanceContext } from '../../../../../context/AppearanceContext';
import stateImg from '../../../../../assets/getStarted/AddPet/HeartBeatBg.png'


import LTitle from '../../../../../components/Titles/LTitle';
import PrimaryBtnLarge from '../../../../../components/Buttons/PrimaryBtnLarge';


import Input from '../../../../../components/Inputs/Input';
import NumberInput from '../../../../../components/Inputs/NumberInput';
import SingleSelect from '../../../../../components/Select/SingleSelect';
import MultiSelect from '../../../../../components/Select/MultiSelect';
import RadioChipMd from '../../../../../components/Inputs/RadioChips/RadioChipMd';
import DatePick from '../../../../../components/Inputs/DatePicker';

import Sterilized from '../../../../../DUMMY/Sterilized'
import DogBloodTypes from '../../../../../DUMMY/DogBloodTypes'
import CatBloodTypes from '../../../../../DUMMY/CatBloodTypes'
import Allergies from '../../../../../DUMMY/Allergies';

import TIME from '../../../../../constants/TIME';
import TextArea from '../../../../../components/Inputs/TextArea';
const MoveValue = 10




export default function AddPetHealth() {
    

    const { width } = useWindowDimensions();
    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()


    const [setProgressBarVisible, setNextProgress, setPrevProgress] = useOutletContext();


    useEffect(() => {
        setProgressBarVisible(true)
        setPrevProgress(8)
        setNextProgress(54)
    },[location, setProgressBarVisible, setPrevProgress, setNextProgress])
    

    const [bloodTypesList, setBloodTypesList] = useState(null);
    useEffect(() => {
        if(location.state){
            if(location.state[0].speciesSelected === 0){
                setBloodTypesList(DogBloodTypes);
            }else{
                setBloodTypesList(CatBloodTypes);
            }
        }
    }, [location.state])




    const registerPetValidationSchema_1 = yup.object().shape({
        sterilization: yup
            .string()
            .required(t('sterilizationRequiredValidation')),
        dateOfSterilization: yup
            .date()
            .min(location.state[1].petbirth)
            .max(new Date(), t('dateNotAfterTodayValidation'))
            .nullable()
            .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
        weight: yup
            .string()
            .required(t('weightRequiredValidation')),
        dateOfweight: yup
            .date()
            .min(location.state[1].petbirth)
            .max(new Date(), t('dateNotAfterTodayValidation'))
            .nullable()
            .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
        bloodType: yup
            .string()
            .required(t('sterilizationRequiredValidation')),
        allergies: yup
            .array(),
        idNumber: yup
            .string(),
        microchipCode: yup
            .string(),
        specialCharacteristics: yup
            .string()
        
    })

    const formik = useFormik({
        validationSchema: registerPetValidationSchema_1,
        initialValues: {
            sterilization: location.state ? location.state[2] ? location.state[2].sterilization ? location.state[2].sterilization : '' : '' : '',
            dateOfSterilization: location.state ? location.state[2] ? location.state[2].dateOfSterilization ? location.state[2].dateOfSterilization : '' : '' : '',
            weight: location.state ? location.state[2] ? location.state[2].weight ? location.state[2].weight : '' : '' : '',
            dateOfweight: location.state ? location.state[2] ? location.state[2].dateOfweight ? location.state[2].dateOfweight : '' : '' : '',
            bloodType: location.state ? location.state[2] ? location.state[2].bloodType ? location.state[2].bloodType : '' : '' : '',
            allergies: location.state ? location.state[2] ? location.state[2].allergies ? location.state[2].allergies : [] : [] : [],
            idNumber: location.state ? location.state[2] ? location.state[2].idNumber ? location.state[2].idNumber : '' : '' : '',
            microchipCode: location.state ? location.state[2] ? location.state[2].microchipCode ? location.state[2].microchipCode : '' : '' : '',
            specialCharacteristics: location.state ? location.state[2] ? location.state[2].specialCharacteristics ? location.state[2].specialCharacteristics : '' : '' : ''
        },
        onSubmit: values => {
            if(location.state.length > 2){
                location.state.pop();
            }
          navigate('/getstarted/pet-personality', { state: [...location.state, ...[{
                sterilization: values.sterilization, 
                dateOfSterilization: values.dateOfSterilization, 
                weight: values.weight, 
                dateOfweight: values.dateOfweight, 
                bloodType: values.bloodType,
                allergies: values.allergies, 
                idNumber: values.idNumber, 
                microchipCode: values.microchipCode, 
                specialCharacteristics: values.specialCharacteristics
            }]] })
        },
    });



    
    if (location.state === null) {
        return <Navigate to="/getstarted" />;
    }else if(location.state[1] === null){
        return <Navigate to="/getstarted" />;
    }




    return (
        <>

        <motion.div
            className={styles.TopRightImg}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 10*TIME }}
        >
            <img src={stateImg} alt='Pet heart hero' />
        </motion.div>

        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('health')} 
                backButtonPath='/getstarted/pet-basics'
                hasBackButton
                backButtonParams={location.state}
            />
        </motion.div>

        <div className={styles.SeperatorS}></div>

        <form onSubmit={formik.handleSubmit}>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 3*TIME }}
            >
                <RadioChipMd 
                    label={t('sterilization')}
                    data={Sterilized} 
                    formik={formik}
                    name='sterilization'
                    value={formik.values.sterilization} 
                    errors={formik.errors.sterilization}
                    touched={formik.touched.sterilization}
                    theme={theme} 
                />
            </motion.div>

            {formik.values.sterilization ? (  
                formik.values.sterilization === 2 ? (
                    <>
                        <div className={styles.SeperatorM}></div>

                        <motion.div
                            initial={{ opacity: 0, translateY: -10 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            exit={{ opacity: 0, translateY: -10 }}
                            transition={{ duration: 0.25, delay: TIME }}
                            style={{maxWidth:500, position:'relative', zIndex:4}}
                        >
                            <DatePick 
                                formik={formik}
                                label={t('dateOfSterilization') + ' (' + t('optional') +')'} 
                                name='dateOfSterilization'
                                value={formik.values.dateOfSterilization}
                                errors={formik.errors.dateOfSterilization}
                                touched={formik.touched.dateOfSterilization}
                                theme={theme} 
                                maxDate={new Date()}
                                minDate={location.state[1].petbirth}
                            />
                        </motion.div>
                    </>
                ):null
                ):null
            }
            

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 4*TIME }}
                style={{maxWidth:500}}
            >
                <NumberInput
                    formik={formik} 
                    label={t('weight')} 
                    type='text'
                    notLessThanZero
                    placeholder={t('enterWeightInKg')} 
                    name="weight" 
                    value={formik.values.weight} 
                    errors={formik.errors.weight}
                    touched={formik.touched.weight}
                    theme={theme}
                    autoComplete={false}
                />
            </motion.div>

            {formik.values.weight ? (  
                <>
                    <div className={styles.SeperatorM}></div>

                    <motion.div
                        initial={{ opacity: 0, translateY: -10 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        exit={{ opacity: 0, translateY: -10 }}
                        transition={{ duration: 0.25, delay: TIME }}
                        style={{maxWidth:500, position:'relative', zIndex:3}}
                    >
                        <DatePick 
                            formik={formik}
                            label={t('dateOfweight') + ' (' + t('optional') +')'} 
                            name='dateOfweight'
                            value={formik.values.dateOfweight}
                            errors={formik.errors.dateOfweight}
                            touched={formik.touched.dateOfweight}
                            theme={theme} 
                            maxDate={new Date()}
                            minDate={location.state[1].petbirth}
                        />
                    </motion.div>
                </>
            ):null
            }

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 5*TIME }}
                style={{maxWidth:500, position:'relative', zIndex:2}}
            >
            {bloodTypesList ? (
                <SingleSelect 
                    formik={formik}
                    name='bloodType'
                    ListItemType='bloodType'
                    value={formik.values.bloodType}
                    errors={formik.errors.bloodType}
                    touched={formik.touched.bloodType}
                    theme={theme} 
                    type='text'
                    label={t('bloodType')} 
                    data={bloodTypesList} 
                    placeholder={t('chooseBloodType')} 
                />
                ):null
            }
                
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 6*TIME }}
                style={{maxWidth:500, position:'relative', zIndex:1}}
            >

                <MultiSelect 
                    formik={formik}
                    name='allergies'
                    ListItemType='allergies'
                    value={formik.values.allergies} 
                    errors={formik.errors.allergies}
                    touched={formik.touched.allergies}
                    theme={theme} 
                    type='text'
                    label={t('allergies') + ' (' + t('optional') +')'} 
                    data={Allergies} 
                    placeholder={t('chooseAllergies')} 
                    id='allergiesMultiSelect'
                    hasToolTip
                    toolTipContent={<span>{t('allergiesCombined')}<br/>{t('allergiesCombinedChooseAll')}</span>}
                    toolTipPlacement={width > 768 ? 'right' : 'top'}
                    isToolTipInfo
                />
                
            </motion.div>

            <div className={styles.SeperatorXXXXL}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 7*TIME }}
                style={{maxWidth:500, position:'relative'}}
            >
                <div className={theme === 'light' ? styles.ContentTitleLight : styles.ContentTitleDark}>{t('additionalInfo')}</div>
            </motion.div>

            <div className={styles.SeperatorL}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 8*TIME }}
                style={{maxWidth:500, position:'relative'}}
            >
                <Input
                    formik={formik} 
                    label={t('idNumber') + ' (' + t('optional') +')'} 
                    type='text'
                    placeholder='' 
                    name="idNumber" 
                    value={formik.values.idNumber} 
                    errors={formik.errors.idNumber}
                    touched={formik.touched.idNumber}
                    theme={theme}
                    autoComplete={false}
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 9*TIME }}
                style={{maxWidth:500, position:'relative'}}
            >
                <Input
                    formik={formik} 
                    label={t('microchipCode') + ' (' + t('optional') +')'} 
                    type='text'
                    placeholder='' 
                    name="microchipCode" 
                    value={formik.values.microchipCode} 
                    errors={formik.errors.microchipCode}
                    touched={formik.touched.microchipCode}
                    theme={theme}
                    autoComplete={false}
                />
            </motion.div>

            <div className={styles.SeperatorM}></div>

            <motion.div
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 10*TIME }}
                style={{maxWidth:500, position:'relative'}}
            >
                <TextArea
                    formik={formik}
                    label={t('specialCharacteristics') + ' ('+t('optional') + ')'} 
                    name='specialCharacteristics'
                    value={formik.values.specialCharacteristics} 
                    errors={formik.errors.specialCharacteristics}
                    touched={formik.touched.specialCharacteristics}
                    theme={theme} 
                />
            </motion.div>
            

            <motion.div
                style={{maxWidth:280, marginTop:80, marginBottom:150}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 11*TIME }}
            >
                <PrimaryBtnLarge type='submit' fullWidth title={t('next')} />
            </motion.div>

        </form>


      


           
        </>
    )
}
