import React, { useEffect, createContext, useState } from 'react';

export const AuthContext = createContext({});

export const AuthContextProvider = props => {

  // Initial values are obtained from the props
  const {
    auth: InitialAuthType,
    children
  } = props;

  // Use State to store the values
  const [auth, setAuth] = useState(InitialAuthType);

  useEffect(() => {
    if(localStorage.getItem("auth")){
        setAuth(localStorage.getItem("auth"))
    }
  }, [] )


  const setAuthFunc = (type) => {
    if(type === true){
        setAuth(type)
        localStorage.setItem("auth", type);
    } else{
        setAuth(type)
        localStorage.removeItem("auth");
    }
  }








  // Make the context object:
  const authContext = {
    auth,
    setAuthFunc
  };

  // pass the value in provider and return
  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};