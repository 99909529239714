import React from 'react'
import styles from './index.module.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import flags from 'react-phone-number-input/flags'
import el from '../../../DUMMY/Countries/el'
import en from '../../../DUMMY/Countries/en'
import CustomInput from './CustomInput'
import CustomSelect from './CustomSelect'

export default function Phone({
    label, 
    setNum,
    hasError,
    setHasError, 
    language, 
    placeholder, 
    errors, 
    touched, 
    hasErrorOutside, 
    setHasErrorOutside, 
    noLabel = false, 
    theme,
    invalidText,
    requiredText
}) {

    const [value, setValue] = React.useState('');
    const [country, setCountry] = React.useState('GR')

    const LanguageChecker = (l) => {
        switch (l) {
            case 'el':
                return el
            case 'en':
                return en
            default:
                return en
          }
    }

    React.useEffect(()=>{
        if (value){
            setHasErrorOutside(false)
            if(!isValidPhoneNumber(value)){
                setHasError('invalid')
            }else{
                setHasError(null)
                setNum(value)
            }
        }else{
            setHasError('required')
        }
    },[value, setHasErrorOutside, setHasError, setNum])
    
  return (
    <>
        {noLabel ? (
            null
        ):(
            <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
        )}
        <div className={
            ((errors && touched) || hasErrorOutside) ? (
                theme === 'light' ? (
                    `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                ):(
                    `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                )     
            ): (
                theme === 'light' ? (
                    styles.InputOuterLight
                ):(
                    styles.InputOuterDark
                )
            )}>
            <PhoneInput
                className={theme === 'light' ? styles.InputLight : styles.InputDark}
                international
                addInternationalOption={false}
                defaultCountry="GR"
                onCountryChange={(c)=>setCountry(c)}
                flags={flags}
                labels={LanguageChecker(language.language)}
                additional={{theme:theme, country:country}}
                placeholder={placeholder}
                value={value}
                onChange={setValue}
                limitMaxLength
                countryCallingCodeEditable={false}
                // error={value ? (isValidPhoneNumber(value) ? undefined : 'Phone number is invalid') : 'Phone number required'}
                inputComponent={CustomInput}
                countrySelectComponent={CustomSelect}
            />
        </div>
        {(hasErrorOutside) &&
            <div style={{ marginTop:4}}>
            <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{
            hasError === 'invalid' ? invalidText : requiredText
            }</span>
            </div>
        }
    </>
  )
}
