const PetColors = [
    {title:'Μαύρο', id:1, en:'Black', alt:'μαυρο', rgb:'#000000'},
    {title:'Λευκό', id:2, en:'White', alt:'λευκό, ασπρο', rgb:'#ffffff'},
    {title:'Καφέ', id:3, en:'Brown', alt:'καφε', rgb:'#A52A2A'},
    {title:'Ανοιχτό καφέ', id:4, en:'Light Brown', alt:'ανοιχτο καφε', rgb:'#b5651d'},
    {title:'Σκούρο καφέ', id:5, en:'Dark Brown', alt:'σκουρο καφε', rgb:'#654321'},
    {title:'Τζίντζερ', id:6, en:'Ginger', alt:'τζιντζερ', rgb:'#96665a'},
    {title:'Πορτοκαλί', id:7, en:'Orange', alt:'πορτοκαλι', rgb:'#FFA500'},
    {title:'Χρυσό', id:8, en:'Gold', alt:'χρυσο', rgb:'#FFD700'},
    {title:'Ασημί', id:9, en:'Silver', alt:'ασημι', rgb:'#C0C0C0'},
    {title:'Γκρι', id:10, en:'Gray', alt:'γκρι', rgb:'#808080'},
    {title:'Ανοιχτό γκρι', id:11, en:'Light Gray', alt:'ανοιχτο γκρι', rgb:'#D3D3D3'},
    {title:'Σκούρο γκρι', id:12, en:'Dark Gray', alt:'σκουρο γκρι', rgb:'#696969'},
    {title:'Μπεζ', id:13, en:'Beige', alt:'μπεζ', rgb:'#E8D3B9'},
    {title:'Κρεμ', id:14, en:'Cream', alt:'κρεμ', rgb:'#FFFDD0'},
    {title:'Ροζ', id:15, en:'Pink', alt:'ροζ', rgb:'#FFC0CB'},
    {title:'Κόκκινο', id:16, en:'Red', alt:'κοκκινο', rgb:'#FF0000'},
    {title:'Μπλε', id:17, en:'Blue', alt:'μπλε', rgb:'#0000FF'},
    {title:'Κίτρινο', id:18, en:'Yellow', alt:'κιτρινο', rgb:'#FFFF00'},
    {title:'Πράσινο', id:19, en:'Green', alt:'πρασινο', rgb:'#00FF00'},
    {title:'Μωβ', id:20, en:'Purple', alt:'μωβ', rgb:'#E6E6FA'},
    {title:'Κανελί', id:21, en:'Cinnamon', alt:'κανελι, κανελα', rgb:'#C58C66'},
];

export default PetColors
