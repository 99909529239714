import React, { useState } from 'react';
import styles from './index.module.css'
import { motion } from "framer-motion";

function Toggle({text, onChange, checked, theme}) {

  const [toggleDirection, setToggleDirection] = useState(0)
    const toggleOn = () => {
        setToggleDirection(toggleDirection === 0 ? 20 : 0)
        onChange(toggleDirection === 0 ? true : false)
    }
  return (
    <>
      <label className={
        checked ? (
          theme === 'light' ? styles.ToggleSwitchContainerActiveLight : styles.ToggleSwitchContainerActiveDark
        ):(
          theme === 'light' ? styles.ToggleSwitchContainerLight : styles.ToggleSwitchContainerDark
        )
      }>
        <span className={theme === 'light' ? styles.ToggleSwitchLabelLight : styles.ToggleSwitchLabelDark}>{text}</span>
        <motion.div className={styles.ToggleContainer}>
            <motion.div 
                className={
                  checked ? (
                    theme === 'light' ? styles.ToggleBackgroundActiveLight : styles.ToggleBackgroundActiveDark
                  ) : (
                    theme === 'light' ? styles.ToggleBackgroundLight : styles.ToggleBackgroundDark
                  )
                }
                onTap={toggleOn}
            >
                <motion.div 
                    className={styles.ToggleCircle}
                    animate={{
                        x: toggleDirection
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 700,
                        damping: 30
                    }}
                />
            </motion.div>
        </motion.div>
      </label>
    </>
  );
}

export default Toggle;
