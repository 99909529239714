import React, {useContext} from 'react'
import { Link, Navigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
// import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../../../context/AppearanceContext';
import stateImg from '../../../../assets/getStarted/petBalloon.png'

import addPet from '../../../../assets/getStarted/light/AddPet.png'
import CoOwner from '../../../../assets/getStarted/light/CoOwner.png'
import SearchPet from '../../../../assets/getStarted/light/SearchPet.png'
import ProUser from '../../../../assets/getStarted/light/ProUser.png'
import FiloZwiki from '../../../../assets/getStarted/light/FiloZwiki.png'

import addPetDark from '../../../../assets/getStarted/dark/AddPet.png'
import CoOwnerDark from '../../../../assets/getStarted/dark/CoOwner.png'
import SearchPetDark from '../../../../assets/getStarted/dark/SearchPet.png'
import ProUserDark from '../../../../assets/getStarted/dark/ProUser.png'
import FiloZwikiDark from '../../../../assets/getStarted/dark/FiloZwiki.png'


import { useAuth } from '../../../../hooks/useAuth';

import LTitle from '../../../../components/Titles/LTitle';
import SubTitle from '../../../../components/Titles/SubTitle';



import TIME from '../../../../constants/TIME';
const MoveValue = 10

// import MultiSelect from '../../../components/Select/MultiSelect';

// import DogBreeds from '../../../DUMMY/DogBreeds';

export default function Choices() {
    

    const { user } = useAuth();
    const { theme } = useContext(AppearanceContext)
    const [t ] = useTranslation();
   


    if (user.m === null) {
        return <Navigate to="/mobile-phone" />;
    }


    return (
        <>

        <motion.div
            className={styles.TopRightImg}
            initial={{ opacity: 0, translateY: MoveValue }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: MoveValue }}
            transition={{ duration: 0.25, delay: 10*TIME }}
        >
            <img src={stateImg} alt='Get started hero' />
        </motion.div>

        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('letsStart')} 
            />
        </motion.div>


        <motion.div
            style={{maxWidth:420}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 3*TIME }}
        >
            <SubTitle 
                theme={theme}  
                text={t('chooseHowYouWantToStart')} 
            />
        </motion.div>

        <div className={styles.ChoicesContainer}>
            <motion.div
                className={styles.Choice}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 4*TIME }}
            >
                <Link to='add-your-pet' className={ theme === 'light' ? styles.ChoiceItemLight : styles.ChoiceItemDark }>
                    <img src={ theme === 'light' ? addPet : addPetDark} className={styles.ChoiceImg} alt='' />
                    <div className={styles.ChoiceItemText}>
                        <div className={ theme === 'light' ? styles.ChoiceItemTitleLight : styles.ChoiceItemTitleDark}>{t('addYourPet')}</div>
                        <div className={ theme === 'light' ? styles.ChoiceItemDescLight : styles.ChoiceItemDescDark}>{t('addYourPetDesc')}</div>
                    </div>
                </Link>
            </motion.div>
            <motion.div
                className={styles.Choice}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 5*TIME }}
            >
                <Link to='become-coowner' className={ theme === 'light' ? styles.ChoiceItemLight : styles.ChoiceItemDark }>
                    <img src={ theme === 'light' ? CoOwner : CoOwnerDark} className={styles.ChoiceImg} alt='' />
                    <div className={styles.ChoiceItemText}>
                        <div className={ theme === 'light' ? styles.ChoiceItemTitleLight : styles.ChoiceItemTitleDark}>{t('becomeCoOwner')}</div>
                        <div className={ theme === 'light' ? styles.ChoiceItemDescLight : styles.ChoiceItemDescDark}>{t('becomeCoOwnerDesc')}</div>
                    </div>
                </Link>
            </motion.div>
            <motion.div
                className={styles.Choice}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 6*TIME }}
            >
                <Link to='search-pets-for-adopt' className={ theme === 'light' ? styles.ChoiceItemLight : styles.ChoiceItemDark }>
                    <img src={ theme === 'light' ? SearchPet : SearchPetDark} className={styles.ChoiceImg} alt='' />
                    <div className={styles.ChoiceItemText}>
                        <div className={ theme === 'light' ? styles.ChoiceItemTitleLight : styles.ChoiceItemTitleDark}>{t('searchPetsForAdopt')}</div>
                        <div className={ theme === 'light' ? styles.ChoiceItemDescLight : styles.ChoiceItemDescDark}>{t('searchPetsForAdoptDesc')}</div>
                    </div>
                </Link>
            </motion.div>
            <motion.div
                className={styles.Choice}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 7*TIME }}
            >
                <Link to='professional-profile' className={ theme === 'light' ? styles.ChoiceItemLight : styles.ChoiceItemDark }>
                    <img src={ theme === 'light' ? ProUser : ProUserDark} className={styles.ChoiceImg} alt='' />
                    <div className={styles.ChoiceItemText}>
                        <div className={ theme === 'light' ? styles.ChoiceItemTitleLight : styles.ChoiceItemTitleDark}>{t('createProfessionalProfile')}</div>
                        <div className={ theme === 'light' ? styles.ChoiceItemDescLight : styles.ChoiceItemDescDark}>{t('createProfessionalProfileDesc')}</div>
                    </div>
                </Link>
            </motion.div>
            <motion.div
                className={styles.Choice}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 8*TIME }}
            >
                <Link to='animal-welfare-groups' className={ theme === 'light' ? styles.ChoiceItemLight : styles.ChoiceItemDark }>
                    <img src={ theme === 'light' ? FiloZwiki : FiloZwikiDark} className={styles.ChoiceImg} alt='' />
                    <div className={styles.ChoiceItemText}>
                        <div className={ theme === 'light' ? styles.ChoiceItemTitleLight : styles.ChoiceItemTitleDark}>{t('filoZwikesKatafigia')}</div>
                        <div className={ theme === 'light' ? styles.ChoiceItemDescLight : styles.ChoiceItemDescDark}>{t('filoZwikesKatafigiaDesc')}</div>
                    </div>
                </Link>
            </motion.div>
        </div>


           
        </>
        // <div style={{maxWidth:700}}>
        //     <div>GetStarted</div>
        //     <Link to='/getstarted/feed'>Go to Feed</Link>
        //     <button onClick={()=>logout()}>Signout</button>
        //     {/* <MultiSelect 
        //         theme={theme} 
        //         label={t('breed')} 
        //         data={DogBreeds} 
        //         placeholder={t('chooseBreed')} 
        //         id='breedMultiSelect'
        //         hasToolTip
        //         toolTipContent={<span>{t('breedsCombined')}<br/>{t('breedsCombinedChooseAll')}</span>}
        //         toolTipPlacement='right'
        //         isToolTipInfo
        //     /> */}
        //     {/* <Outlet /> */}
        // </div>
    )
}
