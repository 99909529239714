import React from 'react'
import styles from './index.module.css'

export default function CountryItem({theme, item, onClick}) {
  return (
    <div 
        key={item.value} 
        onClick={onClick}
        className={ theme==='light' ? styles.CountryItemLight : styles.CountryItemDark }
    >
        <img style={{display:'block'}} src={require(`../../../../assets/Countries/${item.value}.svg`)} alt={item.label} width={30} height={20} />
        {item.label}
    </div>
  )
}
