import React from 'react'
import styles from './index.module.css'

export default function TextArea({label, height = 120, placeholder, formik, name, value, errors, touched, hasErrorOutside = null, noLabel = false, theme}) {
  return (
    <>
        {noLabel ? (
            null
        ):(
            <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
        )}
        <div className={
            ((errors && touched) || hasErrorOutside) ? (
                theme === 'light' ? (
                    `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                ):(
                    `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                )     
            ): (
                theme === 'light' ? (
                    styles.InputOuterLight
                ):(
                    styles.InputOuterDark
                )
            )}
            style={{height:height}}
            >
            <textarea
                className={theme === 'light' ? styles.InputLight : styles.InputDark}
                style={{height:height}}
                name={name}
                placeholder={placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={value}
            >

            </textarea>
        </div>
        {(errors && touched) &&
            <div style={{ marginTop:4}}>
            <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
            </div>
        }
    </>
  )
}
