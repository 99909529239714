import React from 'react'
import styles from './index.module.css'

export default function NumberInput({label, type, placeholder, formik, name, value, errors, touched, hasErrorOutside = null, noLabel = false, theme}) {

    const handleChange = (e)=>{
        const re = /^[0-9]*[,]?[0-9]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            formik.setFieldValue(name, e.target.value)
         }
    }

  return (
    <>
        {noLabel ? (
            null
        ):(
            <label className={theme === 'light' ? styles.InputLabelLight : styles.InputLabelDark}>{label}</label>
        )}
        <div className={
            ((errors && touched) || hasErrorOutside) ? (
                theme === 'light' ? (
                    `${styles.InputOuterLight} ${styles.InputOuterErrorLight}`
                ):(
                    `${styles.InputOuterDark} ${styles.InputOuterErrorDark}`
                )     
            ): (
                theme === 'light' ? (
                    styles.InputOuterLight
                ):(
                    styles.InputOuterDark
                )
            )}>
            <input 
                className={theme === 'light' ? styles.InputLight : styles.InputDark}
                name={name}
                type={type} 
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={value}
            />
        </div>
        {(errors && touched) &&
            <div style={{ marginTop:4}}>
            <span className={theme === 'light' ? styles.ErrorTextLight : styles.ErrorTextDark}>{errors}</span>
            </div>
        }
    </>
  )
}
