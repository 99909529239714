import React from 'react'
import { RiCheckboxCircleFill } from "react-icons/ri";
import { motion } from "framer-motion"
import styles from './index.module.css'

export default function BloodTypeItem({theme, item, onClick, checked}) {
  return (
    <div className={theme === 'light' ? styles.DropDownItemLight : styles.DropDownItemDark} key={item.id} onClick={onClick}>
        <div className={styles.ColorAndTextContainer}>
            <div>
                <div className={theme === 'light' ? styles.DropDownItemTitleLight : styles.DropDownItemTitleDark}>{item.title}</div>
            </div>
        </div>
        <div>
            {checked ? (
                <motion.div 
                    initial={{ opacity: 0, scale:0.8 }}
                    animate={{ opacity: 1, scale:1 }}
                    exit={{ opacity: 0, scale:0.8 }}
                    transition={{ duration: 0.125 }}
                >
                    <RiCheckboxCircleFill size={20} className={styles.Checked} />
                </motion.div>
            ):null}
        </div>
    </div>
  )
}
