import React, {useEffect, useRef} from 'react'
import { motion, animate } from "framer-motion"
import styles from './index.module.css'

export default function BottomProgressBar({theme, progressBarVisible, from, to, text1, text2}) {

    const variants = {
        progressBarShown: { translateY: 0, opacity:1, transition: { duration: 0.5 } },
        progressBarHidden: { translateY: 80, opacity:0, transition: { duration: 0.5 } },
    };

    const nodeRef = useRef();

    useEffect(() => {
        const node = nodeRef.current;

        const controls = animate(from, to, {
            duration: 1,
            onUpdate(value) {
                node.textContent = value.toFixed(0);
            }
        });

        return () => controls.stop();
    }, [from, to]);




    return (
        <motion.div 
                variants={variants}
                animate={progressBarVisible ? 'progressBarShown' : 'progressBarHidden' }
                className={theme === 'light' ? styles.ProgressBarContainerLight : styles.ProgressBarContainerDark}
            >
                <div className={styles.ProgressBarContainer}>
                    <div className={theme === 'light' ? styles.ProgressBarTextLight : styles.ProgressBarTextDark}>{text1} <span className={theme === 'light' ? styles.PercentageLight : styles.PercentageDark} ref={nodeRef} /><span className={theme === 'light' ? styles.PercentageLight : styles.PercentageDark}>%</span> {text2}</div>
                    <div className={theme === 'light' ? styles.ProgressBarTrackLight : styles.ProgressBarTrackDark}>
                        <div 
                            className={theme === 'light' ? styles.ProgressBarLineLight : styles.ProgressBarLineDark}
                            style={{transform:`translateX(-${100-to}%)`}}
                        >
                        </div>
                    </div>
                </div>
        </motion.div>
    )
}
