import React, { useContext, useState }  from 'react'
// import { Outlet, Link, Navigate } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './index.module.css'
import { useTranslation } from 'react-i18next';
import { AppearanceContext } from '../../../../context/AppearanceContext';

// import { useAuth } from '../../../../hooks/useAuth';

import LTitle from '../../../../components/Titles/LTitle';
import SubTitle from '../../../../components/Titles/SubTitleDual';
import ToolTip from '../../../../components/ToolTip';
import PrimaryBtnLarge from '../../../../components/Buttons/PrimaryBtnLarge';
// import stateImage from '../../../../assets/AddMobilePhone/verified_3d.png'




import TIME from '../../../../constants/TIME';
import OTP from '../../../../components/Inputs/OTP';
const MoveValue = 10

export default function AddOTPForMobilePhone() {
    const { theme } = useContext(AppearanceContext);
    const [t] = useTranslation();
    const navigate = useNavigate()
    
    const [otpNum, setOtpNum] = useState(null)
    const [hasError, setHasError] = useState(null)
    const [hasErrorOutside, setHasErrorOutside] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        if(hasError){
            setHasErrorOutside(true)
        }else{
            navigate("/mobile-phone/successful-verification")
            console.log(otpNum)
        }
    }

  return (
    <>
        {/* <motion.div
            style={{position:'absolute',top:100, right:10}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 7*TIME }}
        >
            <img src={stateImage} alt='Verification hero' />
        </motion.div> */}

        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 2*TIME }}
        >
            <LTitle 
                theme={theme} 
                title={t('enter5DigitCode')}
                hasBackButton
                backButtonPath='/mobile-phone'
            />
        </motion.div>
        <motion.div
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 3*TIME }}
        >
            <SubTitle 
                theme={theme} 
                text1={t('enterReceived5DigitOTPToVerifyAccount')}
            />
        </motion.div>

        <form onSubmit={handleSubmit}>
            <motion.div
                style={{maxWidth:400, minHeight:332, marginTop:50, position:'relative', zIndex:2}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 4*TIME }}
            >
                <ToolTip 
                    id='otp'
                    theme={theme}
                    isOpen={true}
                    hasToolTip
                    toolTipContent={<span style={{maxWidth:350, lineHeight:'26px'}}>{t('didntGetTheCode')} <Link to='/mobile-phone' className={theme === 'light' ? styles.IgnorePromptLight : styles.IgnorePromptDark} style={{display:'inline-block'}}><span>{t('tryAgain')}</span></Link></span>}
                    toolTipPlacement='bottom'
                    isToolTipInfo
                />
                <div style={{maxWidth:420, marginBottom:10}}>
                    <OTP 
                        digits={5}
                        setOtpNum={setOtpNum}
                        theme={theme}
                        hasError={hasError}
                        setHasError={setHasError}
                        hasErrorOutside={hasErrorOutside}
                        setHasErrorOutside={setHasErrorOutside}
                        errorMsg={t('addAll5DigitsOfCode')}
                    />
                </div>
                <div id='otp'></div>
            </motion.div>


            <motion.div
                style={{maxWidth:420}}
                initial={{ opacity: 0, translateX: MoveValue }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: MoveValue }}
                transition={{ duration: 0.25, delay: 5*TIME }}
            >
                <PrimaryBtnLarge type='submit' fullWidth title={t('verifyAccount')} />
            </motion.div>
        </form>

        <div className={styles.SeperatorXL}></div>

        <motion.div
            style={{maxWidth:420,}}
            initial={{ opacity: 0, translateX: MoveValue }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: MoveValue }}
            transition={{ duration: 0.25, delay: 6*TIME }}
        >
            <div className={theme === 'light' ? styles.IgnorePromptLight : styles.IgnorePromptDark}>
                {t('dontWantToVerifyAccount')}
            </div>
            <Link className={theme === 'light' ? styles.IgnorePromptLight : styles.IgnorePromptDark}>
                <span>{t('skipThisStep')}</span>
            </Link>
        </motion.div>

    </>
  )
}
